import React from 'react'
import { useFormContext } from 'react-hook-form'

import { TextField, Card } from '@nike/eds'

import { Label } from '../../../components/FormComponents'

function Connection({ host, port }) {
  const { register } = useFormContext()
  return (
    <div className='eds-spacing--mb-24 eds-grid eds-grid--m-cols-12'>
      <Card className='eds-grid--m-col-3 no-padding'>
        <Label label='Connection Details' />
      </Card>
      <Card className='eds-grid--m-col-4 no-padding'>
        <Label label='Host' required />
        <TextField {...register(host, { required: true })} />
      </Card>
      {port && (
        <Card className='eds-grid--m-col-2 no-padding'>
          <Label label='Port' />
          <TextField {...register(port, { valueAsNumber: true })} />
        </Card>
      )}
    </div>
  )
}

export default Connection
