import clsx from 'clsx'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Button, Icon, TextField } from '@nike/eds'

import CheckboxForwardToDLQ from '../../components/CheckboxForwardToDLQ'
import { Label, Select } from '../../components/FormComponents'
import styles from './Http.module.styl'

function httpSettings(data) {
  let auth
  if (data.settings.auth.type === 'oauth')
    auth = {
      type: 'oauth',
      oauth: {
        ...(data.settings.auth.oauth.issuer.type !== '' && {
          issuer: data.settings.auth.oauth.issuer,
        }),
        scopes: data.settings.auth.oauth.scopes,
      },
    }
  if (data.settings.auth.type === 'shoestring')
    auth = {
      type: 'shoestring',
      shoestring: {
        audience: data.settings.auth.shoestring.audience,
        environment: data.settings.auth.shoestring.environment,
      },
    }
  const response = {
    forwardErrorsToDLQ: data.settings.response.forwardErrorsToDLQ,
    handlers: data.settings.response.handlers.map((h) => ({
      code: h.code,
      handler: {
        type: h.handler.type,
        ...(h.handler.type === 'failure' && { failure: h.handler.failure }),
        ...(h.handler.type === 'retry' && { retry: h.handler.retry }),
        ...(h.handler.type === 'success' && { success: h.handler.success }),
      },
    })),
    maxRetryCount: data.settings.response.maxRetryCount,
  }

  const settings = {
    type: 'kafka.connect.http.push',
    'kafka.connect.http.push': {
      ...(auth && { auth }),
      format: data.settings.format,
      headers: data.settings.headers,
      method: data.settings.method,
      response,
      url: data.settings.url,
    },
  }

  return settings
}

function Http() {
  const { register, setValue, watch } = useFormContext()
  const settings = watch('settings') || {
    method: 'PUT',
    forwardErrorsToDLQ: false,
  }
  if (!settings.auth) settings.auth = {}
  if (!settings.auth.type) settings.auth.type = ''
  if (!settings.auth.oauth) settings.auth.oauth = { issuer: {}, scopes: [] }
  if (!settings.auth.shoestring) settings.auth.shoestring = { audience: [] }
  if (!settings.format) settings.format = { type: 'json' }
  if (!settings.response)
    settings.response = { handlers: [], maxRetryCount: 3, forwardErrorsToDLQ: false }
  if (!settings.response.handlers) settings.response.handlers = []
  if (!settings.headers) settings.headers = []

  return (
    <div>
      <TextField
        {...register('settings.url', { required: true })}
        label='Destination URL'
        subtitle='The URL to send data to'
        className='eds-spacing--mb-16'
      />
      <div className='eds-grid eds-grid--m-cols-4'>
        <div>
          <Label label='Auth' />
          <Select
            value={settings.auth.type}
            defaultValue='None'
            inputProps={register('settings.auth.type')}
            options={['None', 'oauth', 'shoestring']}
          />
        </div>
        {settings.auth.type === 'oauth' && (
          <div>
            <Label label='Issuer' />
            <Select
              value={settings.auth.oauth?.issuer?.type}
              options={['custom', 'nikeOkta']}
              optional
              inputProps={register('settings.auth.oauth.issuer.type')}
            />
          </div>
        )}
        {settings.auth.type === 'shoestring' && (
          <div>
            <Label label='Environment' required />
            <Select
              value={settings.auth.shoestring?.environment}
              options={['production', 'test']}
              inputProps={register('settings.auth.shoestring.environment')}
            />
          </div>
        )}
      </div>

      {settings.auth.type === 'shoestring' && (
        <div className={styles.httpAuthForm}>
          <Label label='Audience' />
          {settings.auth.shoestring.audience.map((audience, i) => (
            <div key={i} className='eds-spacing--mb-16 eds-grid eds-grid--m-cols-4'>
              <div>
                <TextField
                  key={i}
                  value={audience}
                  onChange={(e) => {
                    settings.auth.shoestring.audience[i] = e.target.value
                    setValue('settings.auth.shoestring.audience', settings.auth.shoestring.audience)
                  }}
                />
              </div>
              <div>
                <Button
                  variant='secondary'
                  size='s'
                  onClick={() => {
                    settings.auth.shoestring.audience.splice(i, 1)
                    setValue('settings.auth.shoestring.audience', settings.auth.shoestring.audience)
                  }}
                  className='eds-spacing--mt-24'
                >
                  <Icon name='Close' size='s' />
                </Button>
              </div>
            </div>
          ))}
          <Button
            variant='secondary'
            onClick={() =>
              setValue('settings.auth.shoestring.audience', [
                ...settings.auth.shoestring.audience,
                '',
              ])
            }
            className='eds-spacing--mb-16'
          >
            Add Audience
          </Button>
        </div>
      )}

      {settings.auth.type === 'oauth' && settings.auth.oauth?.issuer.type === 'custom' && (
        <div className={clsx('eds-grid eds-grid--m-cols-4', styles.httpAuthForm)}>
          <div>
            <Label label='OAuth Custom Issuer' />
          </div>
          <div>
            <Label label='Client ID' required />
            <TextField
              {...register('settings.auth.oauth.issuer.custom.clientID', { required: true })}
            />
          </div>
          <div>
            <Label label='Client Secret' required />
            <TextField
              {...register('settings.auth.oauth.issuer.custom.clientSecret', { required: true })}
            />
          </div>
          <div>
            <Label label='Token URL' required />
            <TextField
              {...register('settings.auth.oauth.issuer.custom.tokenURL', { required: true })}
            />
          </div>
        </div>
      )}

      {settings.auth.type === 'oauth' && (
        <div className={styles.httpAuthForm}>
          <Label label='OAuth Scopes' />
          {settings.auth.oauth.scopes?.map((scope, i) => (
            <div key={i} className='eds-spacing--mb-16 eds-grid eds-grid--m-cols-4'>
              <div>
                <TextField
                  key={i}
                  value={scope}
                  onChange={(e) => {
                    settings.auth.oauth.scopes[i] = e.target.value
                    setValue('settings.auth.oauth.scopes', settings.auth.oauth.scopes)
                  }}
                />
              </div>
              <div>
                <Button
                  variant='secondary'
                  size='s'
                  onClick={() => {
                    settings.auth.oauth.scopes.splice(i, 1)
                    setValue('settings.auth.oauth.scopes', settings.auth.oauth.scopes)
                  }}
                  className='eds-spacing--mt-24 no-padding'
                >
                  <Icon name='Close' size='s' />
                </Button>
              </div>
            </div>
          ))}
          <Button
            variant='secondary'
            onClick={() =>
              setValue('settings.auth.oauth.scopes', [...settings.auth.oauth.scopes, ''])
            }
            className='eds-spacing--mb-16'
          >
            Add Scope
          </Button>
        </div>
      )}

      <div className='eds-spacing--mb-24' />
      <Label label='Format' />
      <Select
        value={settings.format.type}
        options={['json']}
        inputProps={register('settings.format.type')}
      />
      <div className='eds-spacing--my-16 eds-grid eds-grid--m-cols-4'>
        <div>
          <Label label='Batch' />
        </div>
        <div>
          <Label label='Count' />
          <TextField {...register('settings.format.json.batch.count', { valueAsNumber: true })} />
        </div>
        <div>
          <Label label='Field Name' />
          <TextField {...register('settings.format.json.batch.fieldName')} defaultValue='records' />
        </div>
        <div>
          <Label label='Frequency' />
          <TextField
            {...register('settings.format.json.batch.frequency', { valueAsNumber: true })}
          />
        </div>
      </div>

      <Label label='Method' required />
      <Select
        value={settings.method}
        options={['PATCH', 'POST', 'PUT']}
        inputProps={register('settings.method', { required: true })}
        className='eds-spacing--mb-16'
      />

      <Label label='Request Headers' />
      {settings.headers.map((header, i) => (
        <div key={i} className='eds-spacing--mb-16 eds-grid eds-grid--m-cols-3'>
          <div>
            <TextField
              key={i}
              value={header}
              onChange={(e) => {
                settings.headers[i] = e.target.value
                setValue('settings.headers', settings.headers)
              }}
            />
          </div>
          <div>
            <Button
              variant='secondary'
              size='s'
              onClick={() => {
                settings.headers.splice(i, 1)
                setValue('settings.headers', settings.headers)
              }}
              className='eds-spacing--mt-24'
            >
              <Icon name='Close' size='s' />
            </Button>
          </div>
        </div>
      ))}
      <Button
        variant='secondary'
        onClick={() => setValue('settings.headers', [...settings.headers, ''])}
        className='eds-spacing--mb-16'
      >
        Add Header
      </Button>

      <Label label='Response Handlers' />
      {settings.response.handlers.map((handler, i) => (
        <div key={i}>
          <div className='eds-spacing--mb-16 eds-grid eds-grid--m-cols-8'>
            <div>
              <TextField
                {...register(`settings.response.handlers[${i}].code`, { valueAsNumber: true })}
              />
            </div>
            <div>
              <Select
                value={handler.handler.type}
                defaultValue='None'
                options={['None', 'failure', 'retry', 'success']}
                inputProps={register(`settings.response.handlers[${i}].handler.type`)}
                className='eds-spacing--mt-16'
              />
            </div>
            <div>
              <Button
                variant='secondary'
                size='s'
                onClick={() => {
                  settings.response.handlers.splice(i, 1)
                  setValue('settings.response.handlers', settings.response.handlers)
                }}
                className='eds-spacing--mt-24'
              >
                <Icon name='Close' size='s' />
              </Button>
            </div>
          </div>
          {handler.handler.type === 'failure' && (
            <div className='eds-spacing--my-16'>
              <CheckboxForwardToDLQ
                checked={settings.response.handlers[i].handler.failure.forwardErrorsToDLQ}
                onChange={() =>
                  setValue(
                    `settings.response.handlers[${i}].handler.failure.forwardErrorsToDLQ`,
                    !settings.response.handlers[i].handler.failure.forwardErrorsToDLQ
                  )
                }
                settingType='failure'
              />
            </div>
          )}
          {handler.handler.type === 'retry' && (
            <div>
              <div className='eds-grid eds-grid--m-cols-10'>
                <div>
                  <Label label='Growth Rate' />
                  <TextField
                    {...register(`settings.response.handlers[${i}].handler.retry.growthRate`, {
                      valueAsNumber: true,
                    })}
                  />
                </div>
                <div>
                  <Label label='Initial Wait' />
                  <TextField
                    {...register(`settings.response.handlers[${i}].handler.retry.initialWait`, {
                      valueAsNumber: true,
                    })}
                  />
                </div>
                <div>
                  <Label label='Max Retry Count' />
                  <TextField
                    {...register(`settings.response.handlers[${i}].handler.retry.maxRetryCount`, {
                      valueAsNumber: true,
                    })}
                    defaultValue='3'
                  />
                </div>
              </div>
              <div className='eds-spacing--my-16'>
                <CheckboxForwardToDLQ
                  checked={settings.response.handlers[i].handler.retry.forwardErrorsToDLQ}
                  onChange={() =>
                    setValue(
                      `settings.response.handlers[${i}].handler.retry.forwardErrorsToDLQ`,
                      !settings.response.handlers[i].handler.retry.forwardErrorsToDLQ
                    )
                  }
                  settingType='retry'
                />
              </div>
            </div>
          )}
        </div>
      ))}
      <Button
        variant='secondary'
        onClick={() => {
          setValue('settings.response.handlers', [
            ...settings.response.handlers,
            { code: 200, handler: { type: 'None', failure: {}, retry: {}, success: {} } },
          ])
        }}
        className='eds-spacing--mb-16'
      >
        Add Handler
      </Button>
      <div>
        <Label label='Max Retry Count' />
        <TextField
          {...register(`settings.response.maxRetryCount`, {
            valueAsNumber: true,
          })}
          defaultValue='3'
          className='eds-spacing--mb-16'
        />
      </div>
      <div>
        <CheckboxForwardToDLQ
          checked={settings.response.forwardErrorsToDLQ}
          onChange={() =>
            setValue(`settings.response.forwardErrorsToDLQ`, !settings.response.forwardErrorsToDLQ)
          }
          settingType='global'
        />
      </div>
    </div>
  )
}

export { Http, httpSettings }
