import React from 'react'

import StreamSettings from '../../../components/StreamSettings'
import AuthForm from './AuthForm/AuthForm'
import SchemaDetails from './SchemaDetails'

function HttpForm() {
  return (
    <>
      <AuthForm />
      <StreamSettings sourceType='http' />
      <SchemaDetails />
    </>
  )
}

export default HttpForm
