import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from '@nike/eds'

import Sinks from '../Sinks'
import Usage from '../Usage'
import EditStream from './EditStream'
import SignalFx from './SignalFx'
import styles from './Stream.module.styl'
import StreamSchema from './StreamSchema'

function getUsageForStream(streamName, resourceUsage) {
  const connections = resourceUsage?.connections?.filter(
    (connection) => connection.stream === streamName
  )
  const routes = resourceUsage?.routes?.filter((connection) => connection.stream === streamName)
  return {
    connections,
    routes,
  }
}

function Stream({ stream, index, resource, resourceType, isAdmin }) {
  const history = useHistory()
  const usage = getUsageForStream(stream.name, resource.usage)

  return (
    <div key={index} className={styles.stream}>
      <h4 className='eds-type--title-4'>{stream.name}</h4>

      <span className={styles.controls}>
        {isAdmin && (
          <EditStream stream={stream} resourceName={resource.name} resourceType={resourceType} />
        )}
        <StreamSchema index={index} stream={stream} />
        <SignalFx type={resourceType} resourceName={resource.name} streamName={stream.name} />
      </span>

      <h5 className='eds-type--title-5 eds-spacing--m-16'>Usage</h5>
      <section>
        <Usage usage={usage} />
      </section>
      <h5 className='eds-type--title-5 eds-spacing--m-16'>Sinks</h5>
      <section>
        <Sinks source={resource} stream={stream} />

        {resource.allowedActions.includes('source:Sink') && (
          <Button
            size='small'
            onClick={() =>
              history.push(`/sinks/register?source=${resource.name}&stream=${stream.name}`)
            }
          >
            Register Sink
          </Button>
        )}
      </section>
    </div>
  )
}

export default Stream
