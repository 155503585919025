import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Checkbox, Card, TextField } from '@nike/eds'

import { Label, Select } from '../../components/FormComponents'

function fusionSettings(data) {
  const settings = {
    type: 'kafka.connect.jms.fusion',
    'kafka.connect.jms.fusion': {
      ...(data.settings.auth.type === 'basic' && {
        auth: { type: 'basic', basic: data.settings.auth.basic },
      }),
      characterEncoding: data.settings.characterEncoding,
      connectionFactory: data.settings.connectionFactory,
      destination: data.settings.destination,
      format: data.settings.format,
      forwardHeaders: data.settings.forwardHeaders,
      jmsModule: data.settings.jmsModule,
      url: data.settings.url,
    },
  }

  return settings
}

function Fusion() {
  const { register, setValue, watch } = useFormContext()
  const settings = watch('settings') || { destination: { type: 'queue' } }
  if (!settings.destination) settings.destination = { type: 'queue' }
  if (!settings.format) settings.format = { type: 'json' }

  return (
    <div>
      <Label label='Url' />
      <TextField {...register('settings.url')} />

      <div className='eds-spacing--my-24 eds-grid eds-grid--m-cols-12'>
        <Card className='eds-grid--m-col-3 no-padding'>
          <Label label='Auth' />
          <Select
            value={settings.auth?.type}
            defaultValue='None'
            inputProps={register('settings.auth.type')}
            options={['None', 'basic']}
          />
        </Card>
        {settings.auth?.type === 'basic' && (
          <Card className='eds-grid--m-col-3 no-padding'>
            <Label label='User' />
            <TextField {...register('settings.auth.basic.user')} />
          </Card>
        )}
        {settings.auth?.type === 'basic' && (
          <Card className='eds-grid--m-col-3 no-padding'>
            <Label label='Password' />
            <TextField {...register('settings.auth.basic.password')} />
          </Card>
        )}
      </div>

      <Label label='JMS Module' />
      <TextField {...register('settings.jmsModule')} className='eds-spacing--mb-24' />

      <Label label='Destination' />
      <Select
        value={settings.destination?.type}
        defaultValue=''
        inputProps={register('settings.destination.type')}
        options={['queue', 'topic']}
        className='eds-spacing--mb-24'
      />

      <Label label={settings.destination.type} />
      <TextField
        {...register(`settings.destination.${settings.destination.type}`)}
        className='eds-spacing--mb-24'
      />

      <Label label='Connection Factory' />
      <TextField {...register('settings.connectionFactory')} className='eds-spacing--mb-24' />

      <div className='eds-spacing--mb-24 eds-grid eds-grid--m-cols-10'>
        <Card className='eds-grid--m-col-2 no-padding'>
          <Label label='Character Encoding' />
        </Card>
        <Card className='eds-grid--m-col-2 no-padding'>
          <Select
            value={settings.characterEncoding}
            defaultValue='UTF-8'
            inputProps={register('settings.characterEncoding')}
            options={['ISO-8859-1', 'US-ASCII', 'UTF-16', 'UTF-16BE', 'UTF-16LE', 'UTF-8']}
          />
        </Card>
      </div>
      <div className='eds-spacing--mb-24 eds-grid eds-grid--m-cols-10'>
        <Card className='eds-grid--m-col-2 no-padding'>
          <Label label='Format' />
        </Card>
        <Card className='eds-grid--m-col-2 no-padding'>
          <Select
            value={settings.format?.type}
            defaultValue='json'
            inputProps={register('settings.format.type')}
            options={['json', 'string']}
          />
        </Card>
      </div>

      <Checkbox
        checked={settings.forwardHeaders}
        onChange={() => setValue('settings.forwardHeaders', !settings.forwardHeaders)}
        label='Forward Headers - Whether or not forward headers to the JMS messages as string properties.'
      />
    </div>
  )
}

export { Fusion, fusionSettings }
