import React from 'react'
import { useFormContext } from 'react-hook-form'

import { TextField, Card } from '@nike/eds'

import { Label } from '../../../components/FormComponents'

function DatabaseTable({ database, table }) {
  const { register } = useFormContext()

  return (
    <>
      <div className='eds-spacing--mb-24 eds-grid eds-grid--m-cols-12'>
        <Card className='eds-grid--m-col-3 no-padding'>
          <Label label='Database' required />
        </Card>
        <Card className='eds-grid--m-col-4 no-padding'>
          <TextField {...register(database, { required: true })} className='no-margin' />
        </Card>
      </div>
      <div className='eds-spacing--mb-24 eds-grid eds-grid--m-cols-12'>
        <Card className='eds-grid--m-col-3 no-padding'>
          <Label label='Table' required />
        </Card>
        <Card className='eds-grid--m-col-4 no-padding'>
          <TextField {...register(table, { required: true })} className='no-margin' />
        </Card>
      </div>
    </>
  )
}

export default DatabaseTable
