import Autocomplete from '@mui/material/Autocomplete'
import MuiTextField from '@mui/material/TextField'
import qs from 'qs'
import React, { useState, useEffect } from 'react'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { SelectCloudRedGuids } from '@nike/cx-x-product'
import {
  Button,
  Card,
  Icon,
  Skeleton,
  Snack,
  StatusIndicator,
  TextArea,
  TextField,
  TextGroup,
} from '@nike/eds'

import { getResource, postFilter } from '../api'
import AlarmForm from '../components/AlarmForm'
import { Title, Label, Select } from '../components/FormComponents'
import TagForm from '../components/TagForm'
import config from '../config'
import { useNarkOnPageLoad } from '../util/nark'
import { createAlarms, createPostResource } from '../util/utils'
import { getFilter } from './RegisterSink/FilterForm'
import { SinkType, getSettings } from './RegisterSink/SinkType'
import { getTransformers } from './RegisterSink/TransformersForm'

export async function fetchSourcesInBatches() {
  let from = 0
  const size = 100
  const sourceAccess = { access: { equal: 'source:Sink' } }
  const data = await postFilter('source', {
    from,
    size,
    sourceAccess,
  })
  if (!data || data.totalCount === 0) return []

  const promises = []
  for (from += size; from < data.totalCount; from += size)
    promises.push(postFilter('source', { from, size, sourceAccess }))
  const results = await Promise.all(promises)

  const sources = results.reduce((sources, result) => {
    if (result) sources.push(...result.sources)
    return sources
  }, data.sources)

  return sources
}

function RegisterSink({ location }) {
  const okta = JSON.parse(window.localStorage.getItem('okta-token-storage'))
  const selectCloudRedGuidsOptions = {
    accessToken: okta?.accessToken?.accessToken,
    userEmail: okta?.idToken?.claims?.email,
    env: config.env === 'prod' ? 'prod' : 'qa',
    id: 'cloudRedGUID',
    placeholder: 'llsv2-supply-store_stock_events',
    label: 'Cloud Red GUID',
    isMulti: false,
  }

  useNarkOnPageLoad('register-sink-page')
  const params = qs.parse(location.search?.substring(1))
  const defaultValues = {
    'azure.objectDelimiter': '+',
    'es.batch.count': 100,
    'es.retry.backoff': 5,
    'es.retry.maxCount': 3,
    transformers: [],
    source: params.source || '',
    stream: params.stream || '',
  }

  const methods = useForm({ defaultValues })
  const {
    formState: { errors },
    clearErrors,
    handleSubmit,
    register,
    setError,
    setValue,
    watch,
  } = methods

  const history = useHistory()
  const source = watch('source') || null
  const stream = watch('stream') || ''
  const [tags, setTags] = useState([])
  const [sources, setSources] = useState([])
  const [streams, setStreams] = useState([])
  const [registration, setRegistration] = useState()
  const [isLoadingSources, setLoadingSources] = useState(false)

  useEffect(() => {
    setLoadingSources(true)
    fetchSourcesInBatches().then((sources) => {
      setLoadingSources(false)
      setSources(sources)
    })
    return () => setSources([])
  }, [])

  useEffect(() => {
    if (!sources.length) return
    const currentSource = sources.find((s) => s.name === source)
    if (!currentSource) return
    setStreams(currentSource.streams)
    setValue('stream', currentSource.streams[0].name)
  }, [source, sources, setValue])

  function getBody(data) {
    const body = {
      name: data.name,
      description: data.description,
      source: { name: data.source, stream: data.stream },
      cloudRedGUID: data.cloudRedGUID,
      tags,
      //resetOffsets: {},
      transformers: getTransformers(data.transformers),
      settings: getSettings(data),
    }
    const sinkFilter = getFilter(data.filter)
    if (sinkFilter) body.filter = sinkFilter
    return body
  }

  async function onSubmit(data) {
    setRegistration({ status: 'info', message: 'Submitting registration' })
    if (!(await createPostResource('sinks', getBody(data), setRegistration))) {
      return null
    }
    if (data.alarms.length > 0) {
      setRegistration({ status: 'info', message: 'Saving alarms...' })
      if (!(await createAlarms('sinks', data.name, data.alarms, setRegistration))) return null
    }
    history.push(`/sinks/details?resource=${data.name}`)
  }

  async function validateName(name) {
    const nameRe = /^[a-zA-Z0-9_-]{4,}$/

    if (!nameRe.exec(name))
      return setError(
        'name',
        {
          type: 'focus',
          message: `Name Restrictions: The name must be at least 4 characters long,
            CANNOT include spaces, and only underscore ( _ ) or
            dash ( - ) special characters are allowed.`,
        },
        { shouldFocus: true }
      )
    const resource = await getResource('sinks', name)
    if (resource?.sink)
      return setError('name', { type: 'manual', message: 'This sink name already exists!' })
    clearErrors('name')
  }

  return (
    <FormProvider {...methods}>
      <form aria-label='form' style={{ padding: 44 }} data-testid='sink-form'>
        <Title title='Register a Sink' />

        <Card padding={24} className='eds-spacing--mb-24'>
          <TextGroup>
            <TextField
              {...register('name', { required: true })}
              errorMessage={errors.name?.message}
              style={{ width: 400 }}
              placeholder='llsv2-suppy-store-stock_events'
              hasErrors={!!errors.name}
              onBlur={(e) => validateName(e.target.value)}
              data-testid='sink-name'
              label='Name Your Sink'
            />
            <Controller
              control={methods.control}
              name='description'
              render={({ field: { onChange, onBlur, value } }) => (
                <TextArea
                  id='description'
                  placeholder="LLSv2 subscription to 'supply' domain with event type 'supply/store_stock_events' (id '8f8929239-d542-39383dtd'"
                  label='Add Description'
                  minRows={2}
                  resize='vertical'
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />
            <div style={{ display: 'grid', gridTemplateColumns: '400px 1fr', columnGap: 24 }}>
              <div>
                <Label label='Source' required />
                <Autocomplete
                  {...register('source', { required: true })}
                  value={source}
                  onChange={(e, source) => setValue('source', source)}
                  loading={isLoadingSources}
                  options={sources.map((source) => source.name)}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      error={!!errors.source}
                      helperText={errors.source?.message}
                    />
                  )}
                  popupIcon={<Icon name='CaretDown' />}
                  sx={{ '& .MuiOutlinedInput-root': { padding: '5px' } }}
                  data-testid='sink-source'
                />
              </div>
              <div>
                <Label label='Stream' required />
                <Select
                  value={stream}
                  options={streams.map((stream) => stream.name)}
                  inputProps={register('stream')}
                  data-testid='sink-stream'
                />
              </div>
            </div>
            <div style={{ width: 300 }}>
              <Controller
                control={methods.control}
                name='cloudRedGUID'
                render={({ field }) => (
                  <SelectCloudRedGuids
                    {...selectCloudRedGuidsOptions}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                  />
                )}
              />
            </div>
          </TextGroup>
        </Card>
        <Card size={24} className='eds-spacing--mb-24'>
          <TagForm tags={tags} setTags={setTags} />
        </Card>
        <SinkType />
        <AlarmForm />

        <div
          style={{ display: 'grid', gridTemplateColumns: '200px 1fr', columnGap: 24 }}
          className='eds-spacing--mb-16'
        >
          <div>
            <Button
              className='eds-spacing--mt-16'
              onClick={handleSubmit(onSubmit)}
              disabled={registration?.status === 'info'}
            >
              Create &amp; Finish
            </Button>
          </div>
          <div>
            {registration && (
              <Snack status={registration.status} onDismiss={() => setRegistration(null)}>
                {registration.status === 'info' && <Skeleton height={8} />}
                <p>{registration.message}</p>
                {registration.errors &&
                  registration.errors.map((error, i) => (
                    <StatusIndicator key={i} status='inactive' label={error.description} />
                  ))}
              </Snack>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

export default RegisterSink
