import React from 'react'
import { Link } from 'react-router-dom'

import { Text } from '@nike/eds'

import Hr from '../../components/Hr'

function DeadLetterQueue({ queue }) {
  if (!queue) {
    return null
  }
  const { name, streams } = queue
  return (
    <>
      <div className='eds-spacing--mt-16'>
        <Text font='title-4' className='eds-spacing--mb-16'>
          Dead Letter Queue
        </Text>
      </div>
      <div className='eds-spacing--my-16 eds-grid eds-grid--m-cols-2'>
        {name && (
          <ul>
            <li>
              DLQ Name:{' '}
              <Link
                to={{
                  pathname: `/sources/details`,
                  search: `?resource=${name}&type=source`,
                }}
              >
                {name}
              </Link>
            </li>
            <li>DLQ Stream: {streams}</li>
          </ul>
        )}
      </div>
      <Hr className='no-margin' />
    </>
  )
}

export default DeadLetterQueue
