import { LoginCallback } from '@okta/okta-react'
import React from 'react'

import { Card, Text, TextGroup } from '@nike/eds'

const OktaError = ({ error }) => {
  // this is a copy/paste from this library's ./OktaError.js
  if (error.name && error.message) {
    // Custom error message when users are not assigned Nike.Technology.NSP-UI.Global
    if (error.name === 'OAuthError' && error.errorCode === 'access_denied')
      return (
        <Card>
          <TextGroup>
            <Text font={'title-3'} as={'h3'}>
              Nike Streaming Platform
            </Text>
            <p>
              <b>{error.name}:</b> {error.message}
            </p>
            <p>
              AD Internal is no longer allowed for clients in the Non-Production environments. To
              gain access to the Non-Production environment please <b>request access</b> to be a
              member of
              <b> Nike.Technology.NSP-UI.Global</b> AdGroup via <b>IDLocker</b>.
            </p>
          </TextGroup>
        </Card>
      )
    return (
      <Card>
        <b>{error.name}:</b> {error.message}
      </Card>
    )
  }
  return (
    <Card>
      <b>Error:</b> {error.toString()}
    </Card>
  )
}

const ImplicitCallback = () => <LoginCallback errorComponent={OktaError} />

export default ImplicitCallback
