import { useOktaAuth } from '@okta/okta-react'
import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'

import IntegratedPlatform from '@nike/console-integrated-platform'

import config from '../config'

function NavBar(props) {
  const { authState, oktaAuth } = useOktaAuth()
  const [accessToken, setAccessToken] = useState('none')
  const isLoggedIn = accessToken !== 'none'

  useEffect(() => {
    if (!authState) return
    if (authState.isAuthenticated) setAccessToken(authState.accessToken.accessToken)
  }, [authState, oktaAuth])

  const navBarLinks = [
    { text: 'Sources', path: '/sources' },
    { text: 'Sinks', path: '/sinks' },
    { text: 'KStreams', path: '/kstreams' },
    {
      text: 'Support',
      path: 'https://console.platforms.nike.com/support?platform=NSP',
      external: true,
    },
    { text: 'Docs', path: 'https://docs.platforms.nike.com/nike-streaming', external: true },
  ]

  const navBarConfig = {
    name: 'Nike Streaming Platform',
    rootPath: '/',
    routes: navBarLinks,
    RouterLink: ({ href, ...props }) => <Link to={href} {...props} />,
  }

  const platformConsoleConfig = {
    /** Application Name */
    name: 'nsp',
    /** Okta Access token; NOT client_id */
    accessToken,
    /** Slack URL, linked on Error screen if this component's error boundary is reached */
    slackURL: 'slack://channel?team=T0G3T5X2B&id=CDEUCSQC8',
    /** Boolean of whether or not user is logged in */
    isLoggedIn,
    /** Boolean of whether or not auth check has finished */
    hasAuthCheckFinished: isLoggedIn,
    /** Source and Show Console Navigation whether on console domain or not, defaults to false */
    showConsoleNav: true,
    /** Specify whether you want to let Platform console render "logged out" state (inludes login button). Defaults to true */
    useConsoleLoginScreen: false,
    navBarConfig,
    children: props.children,
    techSolutionId: config.platformId,
    env: config.env,
  }

  if (!isLoggedIn) return props.children
  return <IntegratedPlatform {...platformConsoleConfig} />
}

export default withRouter(NavBar)
