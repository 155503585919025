import React from 'react'

import { Icon } from '@nike/eds'

const statusMap = {
  degraded: { name: 'LaceDown', color: 'var(--eds-color-red-40)' },
  error: { name: 'AlertCircleFilled', color: 'red' },
  initializing: { name: 'WorkoutsFilled', color: 'var(--eds-color-blue-40)' },
  missingschema: { name: 'InfoCircleFilled', color: 'var(--eds-color-orange-20)' },
  paused: { name: 'PauseFilled', color: 'var(--eds-color-blue-10)' },
  revoked: { name: 'LightsOff', color: 'var(--eds-color-grey-1)' },
  running: { name: 'CheckCircleFilled', color: 'var(--eds-color-background-success)' },
  default: { name: 'AdaptLight', color: 'var(--eds-color-grey-2)' },
}

function StatusIcon({ state, className, size }) {
  state = state.toLowerCase()
  const selectedStatus = statusMap[state] ?? statusMap['default']
  return (
    <Icon
      className={className}
      name={selectedStatus.name}
      size={size}
      color={selectedStatus.color}
    />
  )
}

export default StatusIcon
