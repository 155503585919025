import React, { useEffect, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { Card, Checkbox, TextArea, Select } from '@nike/eds'

import { CardTitle, Label } from '../../../components/FormComponents'

function SchemaDetails() {
  const { watch, setValue, control } = useFormContext()
  const name = 'settings.http'
  const hasSchema = watch(`${name}.hasSchema`, false)
  const schema = watch(`schema`)
  const [displaySchema, setDisplaySchema] = useState('')
  const [schemaError, setSchemaError] = useState('')
  const options = [
    { label: 'avro', value: 'avro' },
    { label: 'json', value: 'json' },
  ]

  useEffect(() => {
    if (schema && Object.keys(schema).length) {
      try {
        setDisplaySchema(JSON.stringify(schema))
      } catch {
        setDisplaySchema(schema)
      }
    }
  }, [schema, setDisplaySchema])

  const validateSchema = (value) => {
    try {
      setSchemaError('')
      setValue(`schema`, JSON.parse(value))
    } catch (error) {
      setSchemaError(error.message)
    }
    setDisplaySchema(value)
  }
  const resetSchema = (hasSchema) => {
    setValue(`${name}.hasSchema`, !hasSchema)
    setValue(`${name}.schemaType`, {})
    setValue(`schema`, {})
  }
  return (
    <Card padding={24}>
      <CardTitle title='Schema Details' />
      <Label label='Has Schema' />
      <Checkbox
        id={`${name}.hasSchema.id`}
        name={`${name}.hasSchema`}
        className='eds-spacing--mb-24'
        checked={hasSchema}
        onChange={() => resetSchema(hasSchema)}
        label={
          <span>
            If your data has a schema associated with it, your source will not be
            <br />
            created until a schema has been registered with a stream on this
            <br />
            source. This value cannot be changed after the source has been created.
          </span>
        }
      />
      {hasSchema && (
        <>
          <Label
            label='Schema Type'
            tooltip='Sets schema type and settings for schemas. This is only required if the stream has a schema. If the stream has a schema and this is not set it will default to "avro".'
            required
          />
          <Controller
            control={control}
            // Defaults to first value in options array
            defaultValue={options.find((option) => option.value === 'avro')?.value}
            name={`${name}.schemaType.type`}
            render={({ field }) => (
              <Select
                value={options.filter((option) => field.value === option.value)}
                onChange={(e) => setValue(`${name}.schemaType.type`, e.value)}
                options={options}
                className={'eds-spacing--mb-16'}
              />
            )}
          />
          <TextArea
            id='http-source-schema'
            onChange={(e) => validateSchema(e.target.value)}
            style={{ marginBottom: '16px' }} // TODO: soon to be a patch for this in EDS libary (issue is with the `className` prop)
            placeholder={'{"type": "record"}'}
            errorMessage={schemaError}
            hasErrors={schemaError}
            required
            value={displaySchema}
            minRows={4}
            resize={'vertical'}
          />
        </>
      )}
    </Card>
  )
}

export default SchemaDetails
