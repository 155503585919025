import React from 'react'

import RecordDetails from '../../../components/RecordDetails'
import ConnectionDetails from './ConnectionDetails'
import StreamSettings from './StreamSettings'

function OracleForm() {
  return (
    <>
      <ConnectionDetails />
      <RecordDetails name='settings.oracledb' />
      <StreamSettings />
    </>
  )
}

export default OracleForm
