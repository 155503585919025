import clsx from 'clsx'
import React from 'react'

import { Icon } from '@nike/eds'

import styles from './SinkStatus.module.styl'

function stateToIcon(state) {
  switch (state.toLowerCase()) {
    case 'degraded':
      return { name: 'TrendingDown', category: 'danger' }
    case 'down':
      return { name: 'AdaptLight' }
    case 'error':
      return { name: 'ArrowDown', category: 'danger' }
    case 'initializing':
      return { name: 'AdaptLight', category: 'success' }
    case 'missingschema':
      return { name: 'AlertCircleFilled', category: 'warning' }
    case 'paused':
      return { name: 'PauseFilled' }
    case 'revoked':
      return { name: 'AlertCircleFilled' }
    case 'running':
      return { name: 'CheckCircleFilled', category: 'success' }
    case 'warning':
      return { name: 'CheckCircleFilled', category: 'warning' }
    default:
      return { name: state }
  }
}
function SinkStatus({ state }) {
  const { name, category } = stateToIcon(state)
  return (
    <span className={styles[category]}>
      {state} <Icon name={name} size='s' className={clsx(styles.statusIcon, styles[category])} />
    </span>
  )
}

export default SinkStatus
