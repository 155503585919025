import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Card, Select } from '@nike/eds'

import { Label } from '../../components/FormComponents'
import { SourceFormMap } from '../../types'

const DropdownTypes = [
  { label: 'IBM MQ', value: 'kafka.connect.ibmmq' },
  { label: 'MySQL', value: 'kafka.connect.jdbc.mysql' },
  { label: 'Oracle', value: 'kafka.connect.jdbc.oracledb' },
  { label: 'Postgres', value: 'kafka.connect.jdbc.postgres' },
  { label: 'SQL Server', value: 'kafka.connect.jdbc.sqlserver' },
  { label: 'Fusion (Beta)', value: 'kafka.connect.jms.fusion' },
  { label: 'HTTP', value: 'kafka.http.push' },
  { label: 'Producer', value: 'kafka.producer' },
]

function SourceType() {
  const { watch, setValue, unregister } = useFormContext()
  const name = 'settings.type'
  const value = watch(name, '')
  const SelectedType = SourceFormMap[value]
  const handleChange = (e) => {
    unregister('settings')
    setValue(name, e.value)
  }
  return (
    <Card padding={24} className='eds-spacing--mb-24'>
      <Label label='Source Type' required />
      <Select
        id='source-type-select'
        name={name}
        options={DropdownTypes}
        class={'eds-spacing--mb-24'}
        required
        onChange={(e) => handleChange(e)}
      />
      {value && <SelectedType />}
    </Card>
  )
}

export default SourceType
