import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Card, TextField, Checkbox, Link } from '@nike/eds'

import { CardTitle, Label } from '../../../components/FormComponents'

function StreamSettings() {
  const { register, setValue, watch } = useFormContext()
  const name = 'settings.ibmmq.defaultStreamSettings'
  const isFips = watch('settings.ibmmq.encryption.fips', false)
  return (
    <Card padding={24}>
      <CardTitle title='Default Stream Settings' />
      <input
        type='hidden'
        {...register(`${name}.maxPayloadSize`, { required: true, valueAsNumber: true })}
        value={1}
      />
      <TextField
        {...register(`${name}.partitionCount`, { required: true, valueAsNumber: true })}
        className='eds-spacing--mb-16'
        placeholder='1'
        defaultValue={1}
        label={<Label label='Partition Count' />}
      />

      <CardTitle title='Retention' />
      <TextField
        {...register(`${name}.retention.size`, { required: true, valueAsNumber: true })}
        className='eds-spacing--mb-16'
        placeholder='20000'
        defaultValue={20000}
        label={<Label label='Size' />}
      />
      <TextField
        {...register(`${name}.retention.time`, { required: true, valueAsNumber: true })}
        className='eds-spacing--mb-16'
        placeholder='345600000'
        defaultValue={345600000}
        label={<Label label='Time' />}
      />
      <TextField
        {...register(`settings.ibmmq.encryption.certificateDN`, { required: true })}
        className='eds-spacing--mb-16'
        placeholder=''
        label={
          <Label
            label='Certificate DN'
            tooltip='The distinguished name of the certificate on the server. This can be used to ensure that the certificate on the server matches the expected DN when making a secured connection.'
          />
        }
      />
      <TextField
        {...register(`settings.ibmmq.encryption.cipherSpec`)}
        className='eds-spacing--mb-16'
        placeholder=''
        label={
          <Label
            label='Cipher Spec'
            tooltip='The name of the cipher suite to use in making the connection. The connection will not be encrypted unless this value is set. The full list of supported suites is described at https://www.ibm.com/docs/en/ibm-mq/9.1?topic=jms-tls-cipherspecs-ciphersuites-in-mq-classes'
          />
        }
      />
      <Label label='Fips' />
      <Checkbox
        name={`settings.ibmmq.encryption.fips`}
        className='eds-spacing--mb-24'
        value={'fips'}
        checked={!!isFips}
        onChange={(e) => setValue(`settings.ibmmq.encryption.fips`, e.target.checked)}
        label={
          <span>
            Whether FIPS is required for the cipher suite that was selected. Refer to
            <Link href='https://www.ibm.com/support/knowledgecenter/en/SSFKSJ_9.1.0/com.ibm.mq.dev.doc/q113220_.htm'>
              &nbsp;IBM Support&nbsp;
            </Link>
            to determine whether to use FIPS for the selected cipher suite.
          </span>
        }
      />
    </Card>
  )
}

export default StreamSettings
