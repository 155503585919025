import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { useHistory, Link } from 'react-router-dom'

import {
  Button,
  Card,
  Skeleton,
  StatusIndicator,
  Snack,
  Text,
  TextArea,
  TextField,
  TextGroup,
} from '@nike/eds'

import { getResource } from '../api'
import AlarmForm from '../components/AlarmForm'
import TagForm from '../components/TagForm'
import { SourceFormMap, SourceTypeMap, SinkTypes } from '../types'
import { useNarkOnPageLoad } from '../util/nark'
import { createAlarms, createResource, createSchema } from '../util/utils'
import { azureSettings } from './RegisterSink/Azure'
import { consumerSettings } from './RegisterSink/Consumer'
import { esSettings } from './RegisterSink/ElasticSearch'
import { FilterForm, getFilter } from './RegisterSink/FilterForm'
import { fusionSettings } from './RegisterSink/Fusion'
import { httpSettings } from './RegisterSink/Http'
import { ibmSettings } from './RegisterSink/IbmMq'
import { mySqlSettings } from './RegisterSink/MySql'
import { s3Settings } from './RegisterSink/S3'
import { snowflakeSettings } from './RegisterSink/Snowflake'
import { splunkSettings } from './RegisterSink/Splunk'
import { sqlServerSettings } from './RegisterSink/SqlServer'
import { TransformersForm, getTransformers } from './RegisterSink/TransformersForm'

function EditPage(props) {
  useNarkOnPageLoad('edit-page')
  const history = useHistory()
  const pathnames = props.location.pathname.split('/')
  const resourceType = pathnames[1] === 'nsp' ? pathnames[2] : pathnames[1]
  const params = qs.parse(props.location.search?.substring(1))
  const name = params['resource']
  const [resource, setResource] = useState()
  const [update, setUpdate] = useState()
  const [tags, setTags] = useState([])
  const methods = useForm()

  useEffect(() => {
    async function fetchResource() {
      try {
        const r = await getResource(resourceType, name)
        const alarms = await getResource(resourceType, `${name}/alarms`)
        if (r.source) {
          setResource(r.source)
          setTags(r.source.tags)

          const updateObj = {
            settings: {
              [SourceTypeMap[r.source.settings.type]]: r.source.settings[r.source.settings.type],
            },
            cloudRedGUID: r.source.cloudRedGUID,
            description: r.source.description,
            tags: r.source.tags,
            alarms: alarms.alarms,
          }
          if (r.source.streams.length) updateObj.schema = r.source.streams[0]?.schema
          methods.reset(updateObj)
        } else if (r.sink) {
          setResource(r.sink)
          setTags(r.sink.tags)
          const updateObj = {
            source: { name: r.sink.source.name, stream: r.sink.source.stream },
            settings: r.sink.settings ? r.sink.settings[r.sink.settings.type] : null,
            cloudRedGUID: r.sink.cloudRedGUID,
            description: r.sink.description,
            tags: r.sink.tags,
            alarms: alarms.alarms,
            filter: r.sink.filter,
            transformers: r.sink.transformers,
          }
          methods.reset(updateObj)
        } else setResource({})
      } catch (err) {
        console.error(err)
      }
    }

    fetchResource()
    return () => setResource(null)
  }, [name, resourceType, methods])

  async function onSubmit(data) {
    setUpdate({ status: 'info', message: 'Updating' })
    let body
    if (resourceType === 'sinks') {
      let settings
      if (settingsType === 'kafka.connect.azure.blob') settings = azureSettings(data)
      if (settingsType === 'kafka.connect.elasticsearch') settings = esSettings(data)
      if (settingsType === 'kafka.connect.http.push') settings = httpSettings(data)
      if (settingsType === 'kafka.connect.ibmmq') settings = ibmSettings(data)
      if (settingsType === 'kafka.connect.jdbc.mysql') settings = mySqlSettings(data)
      if (settingsType === 'kafka.connect.jdbc.snowflake') settings = snowflakeSettings(data)
      if (settingsType === 'kafka.connect.jdbc.sqlserver') settings = sqlServerSettings(data)
      if (settingsType === 'kafka.connect.jms.fusion') settings = fusionSettings(data)
      if (settingsType === 'kafka.connect.s3') settings = s3Settings(data)
      if (settingsType === 'kafka.connect.splunk') settings = splunkSettings(data)
      if (settingsType === 'kafka.consumer') settings = consumerSettings(data)
      body = {
        source: data.source,
        settings,
        tags,
        description: data.description,
        cloudRedGUID: data.cloudRedGUID,
        filter: getFilter(data.filter),
        transformers: getTransformers(data.transformers),
      }
    }

    if (resourceType === 'sources') {
      body = {
        settings: {
          [settingsType]: data.settings[SourceTypeMap[settingsType]],
        },
        tags: tags,
        description: data.description,
        cloudRedGUID: data.cloudRedGUID,
      }
    }
    if (!(await createResource(resourceType, name, body, setUpdate))) return null

    if (data?.alarms) {
      setUpdate({ status: 'info', message: 'Saving alarms...' })
      if (!(await createAlarms(resourceType, name, data.alarms, setUpdate))) return null
    }

    if (data?.schema && Object.keys(data.schema).length) {
      setUpdate({ status: 'info', message: 'Saving schema...' })
      if (!(await createSchema(resourceType, name, data.schema, setUpdate))) return null
    }
    return history.push(`/${resourceType}/details?resource=${resource.name}`)
  }

  if (!resource) return <div>EditPage</div>
  if (!resource.settings) return <div>Resource not found</div>
  const settingsType = resource.settings.type
  const SourceForm = SourceFormMap[settingsType]
  const SinkForm = SinkTypes[settingsType]
  const isAdmin = resource.allowedActions?.length > 5
  if (!isAdmin) return <div>Only the Admin can edit this resource</div>

  return (
    <Card className='eds-spacing--m-32 word-break'>
      <Text font='title-3' className='eds-spacing--mb-24'>
        Update {resource.name}
      </Text>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <TextGroup>
            <Controller
              control={methods.control}
              name='description'
              render={({ field: { onChange, onBlur, value } }) => (
                <TextArea
                  id='description'
                  placeholder="LLSv2 subscription to 'supply' domain with event type 'supply/store_stock_events' (id '8f8929239-d542-39383dtd'"
                  label='Add Description'
                  minRows={2}
                  resize='vertical'
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />
            <TextField
              {...methods.register('cloudRedGUID')}
              placeholder='3ca05652-c688-4212-a3ab-c8963e7e31a0'
              label='Cloud Red GUID'
            />
            <TagForm tags={tags} setTags={setTags} />
          </TextGroup>
          {resourceType === 'sources' ? <SourceForm /> : <SinkForm />}

          {resourceType === 'sinks' && settingsType !== 'kafka.consumer' && (
            <FilterForm fieldNamePrefix='filter' />
          )}

          {resourceType === 'sinks' && settingsType !== 'kafka.consumer' && <TransformersForm />}

          <AlarmForm />

          <div className='eds-spacing--mt-32' />
          <Link to={`/${resourceType}/details?resource=${resource.name}`}>
            <Button size='small' variant='secondary'>
              Cancel
            </Button>
          </Link>
          <Button size='small' variant='secondary' type='submit' className='eds-spacing--ml-24'>
            Update
          </Button>
        </form>
      </FormProvider>

      {update && (
        <Snack status={update.status} onDismiss={() => setUpdate(null)}>
          {update.status === 'info' && <Skeleton height={8} />}
          <p>{update.message}</p>
          {update.errors &&
            update.errors.map((error, i) => (
              <StatusIndicator key={i} status='inactive' label={error.description} />
            ))}
        </Snack>
      )}
    </Card>
  )
}

export default EditPage
