import React from 'react'
import { useFormContext } from 'react-hook-form'

import { TextField, Card } from '@nike/eds'

import { Label, Select } from '../../components/FormComponents'
import Connection from './Database/Connection'

function ibmSettings(data) {
  const settings = {
    type: 'kafka.connect.ibmmq',
    'kafka.connect.ibmmq': {
      Destination: {
        type: 'queue',
        queue: data.settings.Destination.queue,
      },
      host: data.settings.host,
      port: data.settings.port,
      username: data.settings.username,
      password: data.settings.password,
      characterEncoding: data.settings.characterEncoding,
      ...{ transport: data.settings.transport },
      queueManager: data.settings.queueManager,
    },
  }

  return settings
}

function IbmMq() {
  const { register, watch } = useFormContext()
  const settings = watch('settings') || {}
  if (!settings.format) settings.format = { type: 'json' }
  if (!settings.transport) settings.transport = { type: 'client', client: {} }

  return (
    <div>
      <Connection host='settings.host' port='settings.port' />

      <div className='eds-spacing--mb-24 eds-grid eds-grid--m-cols-12'>
        <Card className='eds-grid--m-col-3 no-padding'>
          <Label label='Credentials' />
        </Card>
        <Card className='eds-grid--m-col-2 no-padding'>
          <Label label='Username' />
          <TextField {...register('settings.username')} />
        </Card>
        <Card className='eds-grid--m-col-2 no-padding'>
          <Label label='Password' />
          <TextField {...register('settings.password')} />
        </Card>
      </div>

      <div className='eds-spacing--mb-24 eds-grid eds-grid--m-cols-12'>
        <Card className='eds-grid--m-col-3 no-padding'>
          <Label label='Character Encoding' />
        </Card>
        <Card className='eds-grid--m-col-9 no-padding'>
          <Select
            value={settings.characterEncoding}
            defaultValue='UTF-8'
            inputProps={register('settings.characterEncoding')}
            options={['ISO-8859-1', 'US-ASCII', 'UTF-16', 'UTF-16BE', 'UTF-16LE', 'UTF-8']}
          />
        </Card>

        <Card className='eds-grid--m-col-3 no-padding'>
          <Label label='Format' />
        </Card>
        <Card className='eds-grid--m-col-9 no-padding'>
          <Select
            value={settings.format.type}
            defaultValue='json'
            inputProps={register('settings.format.type')}
            options={['json']}
          />
        </Card>

        <Card className='eds-grid--m-col-3 no-padding'>
          <Label label='Transport' />
        </Card>
        <Card className='eds-grid--m-col-9 no-padding'>
          <Select
            value={settings.transport.type}
            inputProps={register('settings.transport.type')}
            options={['client']}
            optional
          />
        </Card>
      </div>
      <div className='eds-spacing--mb-24 eds-grid eds-grid--m-cols-12'>
        {settings.transport?.type === 'client' && (
          <Card className='eds-grid--m-col-3 no-padding'>
            <Label label='Client Channel' required />
          </Card>
        )}
        {settings.transport?.type === 'client' && (
          <Card className='eds-grid--m-col-9 no-padding'>
            <TextField
              {...register('settings.transport.client.channel', { required: true })}
              className='no-margin'
            />
          </Card>
        )}

        <Card className='eds-grid--m-col-3 no-padding'>
          <Label label='Queue Manager' required />
        </Card>
        <Card className='eds-grid--m-col-9 no-padding'>
          <TextField
            {...register('settings.queueManager', { required: true })}
            className='no-margin'
          />
        </Card>

        <Card className='eds-grid--m-col-3 no-padding'>
          <Label label='Destination Queue' required />
        </Card>
        <Card className='eds-grid--m-col-9 no-padding'>
          <TextField
            {...register('settings.Destination.queue', { required: true })}
            className='no-margin'
          />
        </Card>
      </div>
    </div>
  )
}

export { IbmMq, ibmSettings }
