import React, { useEffect } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { Card, TextField, Radio, Text, Select } from '@nike/eds'

import { CardTitle, Label } from '../../../components/FormComponents'

function ConnectionDetails() {
  const { register, setValue, watch, control } = useFormContext()
  const name = 'settings.fusion'
  const destination = watch(`${name}.destination.type`)
  const auth = watch(`${name}.auth.type`, '')
  const options = [
    { label: 'queue', value: 'queue' },
    { label: 'topic', value: 'topic' },
  ]
  const resetAuth = () => {
    setValue(`${name}.auth.type`, '')
    setValue(`${name}.auth`, {})
  }
  useEffect(() => {
    destination ?? setValue(`${name}.destination.type`, 'queue')
  }, [setValue, destination])

  return (
    <Card padding={24}>
      <CardTitle title='Connection Details' />
      <TextField
        {...register(`${name}.connectionFactory`, { required: true })}
        className='eds-spacing--mb-16'
        placeholder='jms/osbXAConnectionFactoryOne'
        required
        label={
          <Label
            label='Connection Factory'
            required
            tooltip='The JNDI name of the connection factory.'
          />
        }
      />
      <CardTitle title='Auth' tooltip='Information for auth for the JMS connection.' />
      <div className={'eds-spacing--mb-24 eds-grid eds-grid--m-cols-6'}>
        <Radio
          id='auth_none'
          value=''
          onClick={() => resetAuth()}
          checked={!auth}
          label={<Text font='body-2'>None (Default)</Text>}
        />
        <Radio
          id='auth_basic'
          value='basic'
          checked={!!auth}
          onClick={(e) => setValue(`${name}.auth.type`, e.target.value)}
          label={<Text font='body-2'>Basic</Text>}
        />
      </div>
      {auth && (
        <div className={'eds-spacing--mb-16 eds-grid eds-grid--m-cols-2'}>
          <div>
            <TextField
              {...register(`${name}.auth.basic.user`)}
              className='eds-spacing--mb-16'
              label={<Label label='Username' tooltip='The user name to connect with.' />}
            />
          </div>
          <div>
            <TextField
              {...register(`${name}.auth.basic.password`)}
              className='eds-spacing--mb-16'
              label={<Label label='Password' tooltip='The password to connect with.' />}
            />
          </div>
        </div>
      )}

      <Label label='Destination' tooltip='The type of JMS Fusion System to connect to.' required />
      <Controller
        control={control}
        // Defaults to first value in options array
        defaultValue={options.find((option) => option.value === 'queue')?.value}
        name={`${name}.destination.type`}
        render={({ field }) => (
          <Select
            value={options.filter((option) => field.value === option.value)}
            onChange={(e) => setValue(`${name}.destination.type`, e.value)}
            options={options}
            className={'eds-spacing--mb-16'}
          />
        )}
      />
      {destination === 'queue' && (
        <TextField
          {...register(`${name}.destination.queue`, { required: true })}
          className='eds-spacing--mb-16'
          label={
            <Label
              label='Queue'
              required
              tooltip='The name of the uniform distributed queue. Note that NSP will not auto-create the queue. It must already be created.'
            />
          }
        />
      )}
      {destination === 'topic' && (
        <TextField
          {...register(`${name}.destination.topic`, { required: true })}
          className='eds-spacing--mb-16'
          label={
            <Label
              label='Topic'
              required
              tooltip='The name of the uniform distributed topic. Note that NSP will not auto-create the topic. It must already be created.'
            />
          }
        />
      )}
    </Card>
  )
}

export default ConnectionDetails
