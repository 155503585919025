import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Checkbox } from '@nike/eds'

function UpdateTable({ updateTable, checked }) {
  const { setValue } = useFormContext()

  return (
    <div>
      <Checkbox
        checked={checked}
        onChange={() => setValue(updateTable, !checked)}
        label='Update Table - Whether the columns on a table will be updated in response to changes in the source data.'
        className='eds-spacing--mb-24'
      />
    </div>
  )
}

export default UpdateTable
