import Autocomplete from '@mui/material/Autocomplete'
import MuiTextField from '@mui/material/TextField'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import {
  Button,
  Card,
  Icon,
  Skeleton,
  Snack,
  StatusIndicator,
  Text,
  TextField,
  Tooltip,
} from '@nike/eds'

import { getResource, postFilter, getClientIds } from '../api'
import { Label, Select } from '../components/FormComponents'
import { useNarkOnPageLoad } from '../util/nark'
import { createResource, createPostResource } from '../util/utils'

function RegisterKStreamPage(props) {
  useNarkOnPageLoad('register-kstream-page')
  const params = qs.parse(props.location.search?.substring(1))
  const history = useHistory()
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm()

  const [sources, setSources] = useState([])
  const [streams, setStreams] = useState([])
  useEffect(() => {
    function setDefaults(sources) {
      if (params.source) {
        const source = sources.find((src) => src.name === params.source)
        setValue('source', source)
        setStreams(source ? source.streams.map((stream) => stream.name) : [])
        if (source) setValue('stream', source.streams[0].name)
      }
    }
    async function fetchSources() {
      const filter = { from: 0, size: 100, sourceAccess: { access: { equal: 'source:Sink' } } }
      const data = await postFilter('source', filter)
      if (!data) return null

      const promises = []
      for (filter.from += filter.size; filter.from < data.totalCount; filter.from += filter.size)
        promises.push(postFilter('source', filter))
      const results = await Promise.all(promises)

      const sources = results.reduce((sources, result) => {
        if (result) sources.push(...result.sources)
        return sources
      }, data.sources)
      setDefaults(sources)
      setSources(sources)
    }
    fetchSources()
    return () => setSources(null)
  }, [params.source, setValue])

  const [clientIds, setClientIds] = useState([])
  useEffect(
    () => getClientIds().then((items) => setClientIds(items.map((item) => item.clientId))),
    []
  )

  const [registration, setRegistration] = useState()

  const onSubmit = async (data) => {
    setRegistration({ status: 'info', message: 'Submitting registration' })
    const sinkName = data.source.name + '-consumer'
    const postData = {
      name: sinkName,
      settings: {
        type: 'kafka.consumer',
        'kafka.consumer': {
          users: [data.clientId],
        },
      },
      source: {
        name: data.source.name,
        stream: data.stream,
      },
    }
    if (!createPostResource('sinks', postData, setRegistration)) {
      return null
    }

    const consumer = await getResource('sinks', sinkName)

    const body = {
      settings: {
        type: 'kafka.producer',
        'kafka.producer': {
          streams: [
            {
              name: 'kstream',
              isKStream: true,
              users: [data.clientId],
              messageFormat: data.format,
            },
          ],
        },
      },
      tags: ['kstreams', `kstreams-${consumer.sink.id}`],
    }
    await createResource('sources', data.name, body, setRegistration)
      .then((r) => {
        if (r.status === 200) return history.push(`/sources/details?resource=${data.name}`)
        setRegistration({ status: 'error', message: r.body.message, errors: r.body.errors })
      })
      .catch((e) => console.log(e.message))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ backgroundColor: '#EFEFEF', padding: 48 }}>
      <Text font='title-4' as='span'>
        Register a KStream
      </Text>
      <Tooltip
        bodySlot={
          <Text font='subtitle-2'>
            Kstreams transform data from input streams into output streams.
          </Text>
        }
        placement='right'
        isDark={true}
      >
        <Icon name='InfoCircle' className='infoCircle' />
      </Tooltip>

      <Text font='title-5' as='p' className='eds-spacing--mt-24'>
        Input Stream (Sink)
      </Text>
      <Card padding={16} className='eds-spacing--mt-16'>
        <div
          style={{ display: 'grid', gridTemplateColumns: '400px 1fr', columnGap: 24 }}
          className='eds-spacing--mb-16'
        >
          <div>
            <Label label='Source' required />
            {sources && (
              <Autocomplete
                {...register('source', { required: true })}
                value={watch('source') || null}
                options={sources}
                getOptionLabel={(source) => source.name || ''}
                onChange={(e, source) => {
                  setValue('source', source)
                  setValue('stream', source ? source.streams[0].name : '')
                  setStreams(source ? source.streams.map((stream) => stream.name) : [])
                }}
                isOptionEqualToValue={(option, value) => option.name === value?.name}
                renderInput={(params) => <MuiTextField {...params} />}
                popupIcon={<Icon name='CaretDown' />}
                sx={{ '& .MuiOutlinedInput-root': { padding: '5px' } }}
              />
            )}
            {errors.source && <div className='errorStyle'>Please add a Source</div>}
          </div>
          <div>
            <Label label='Stream' required />
            <Select value={watch('stream')} options={streams} inputProps={register('stream')} />
          </div>
        </div>
      </Card>

      <Text font='title-5' as='p' className='eds-spacing--mt-24'>
        Output Stream (Source)
      </Text>
      <Card padding={16} className='eds-spacing--mt-16'>
        <div className='eds-spacing--mb-16' style={{ width: 410 }}>
          <TextField
            {...register('name', { required: true, pattern: /^[a-zA-Z_-]{4,}$/ })}
            placeholder='Name your KStream'
            hasErrors={!!errors.name}
            errorMessage='Name Restrictions: The name must be at least 4 characters long,
                    CANNOT include spaces, and only underscore ( _ ) or
                    dash ( - ) special characters are allowed.'
            label='KStream Source Name'
          />
        </div>

        <Label
          label='Message Format'
          required
          tooltip={
            <>
              The format of the messages. Note that &apos;string&apos; message
              <br />
              format expects any valid string while the &apos;string/xml&apos;
              <br />
              message format expects well formed XML strings.
            </>
          }
        />
        <Select
          value={watch('format')}
          options={['avro', 'json', 'string', 'string/xml']}
          inputProps={register('format', { required: true })}
        />
        {errors.format && <div className='errorStyle'>Please select a Message Format.</div>}
        <div className='eds-spacing--mb-16' />

        <Label
          label='Client ID'
          required
          tooltip={
            <>
              The list of users to allow. Users must be
              <br />
              applicants registered in the developer portal.
            </>
          }
        />
        <Select
          value={watch('clientId')}
          options={clientIds}
          inputProps={register('clientId', { required: true })}
        />
        {errors.clientId && <div className='errorStyle'>Please add a Client ID</div>}
      </Card>

      <div
        style={{ display: 'grid', gridTemplateColumns: '200px 1fr', columnGap: 24 }}
        className='eds-spacing--my-16'
      >
        <div>
          <Button size='small' type='submit' className='eds-spacing--mt-16'>
            Register KStream
          </Button>
        </div>
        <div>
          {registration && (
            <Snack status={registration.status} onDismiss={() => setRegistration(null)}>
              {registration.status === 'info' && <Skeleton height={8} />}
              <p>{registration.message}</p>
              {registration.errors &&
                registration.errors.map((error, i) => (
                  <StatusIndicator key={i} status='inactive' label={error.description} />
                ))}
            </Snack>
          )}
        </div>
      </div>
    </form>
  )
}

export default RegisterKStreamPage
