import React from 'react'
import { useFormContext } from 'react-hook-form'

import { TextField, Card } from '@nike/eds'

import { Label, Select } from '../../components/FormComponents'
import BatchCount from './Database/BatchCount'
import Connection from './Database/Connection'
import CreateTable from './Database/CreateTable'
import DatabaseTable from './Database/DatabaseTable'
import DeleteEnabled from './Database/DeleteEnabled'
import Mode from './Database/Mode'
import PrimaryKey from './Database/PrimaryKey'
import Timezone from './Database/Timezone'
import UpdateTable from './Database/UpdateTable'

function sqlServerSettings(data) {
  const settings = {
    type: 'kafka.connect.jdbc.sqlserver',
    'kafka.connect.jdbc.sqlserver': {
      host: data.settings.host,
      port: data.settings.port,
      ...(data.settings.auth.type === 'basic' && {
        auth: { type: 'basic', basic: data.settings.auth.basic },
      }),
      connectionEncryption:
        data.settings.connectionEncryption.type === 'optional'
          ? { type: 'optional', optional: {} }
          : { type: 'ssl', ssl: {} },
      database: data.settings.database,
      table: data.settings.table,
      createTable: data.settings.createTable,
      updateTable: data.settings.updateTable,
      mode: data.settings.mode,
      ...(data.settings.primaryKey.type === 'record' && { primaryKey: data.settings.primaryKey }),
      timezone: data.settings.timezone,
    },
  }

  return settings
}

function SqlServer() {
  const { register, watch } = useFormContext()
  const settings = watch('settings') || {
    auth: {},
    batchCount: 3000,
    createTable: true,
    updateTable: true,
    primaryKey: {},
    timezone: 'UTC',
  }
  if (!settings.primaryKey) settings.primaryKey = {}

  return (
    <div>
      <Connection host='settings.host' port='settings.port' />

      <div className='eds-spacing--mb-24 eds-grid eds-grid--m-cols-12'>
        <Card className='eds-grid--m-col-3 no-padding'>
          <Label label='Auth' />
          <Select
            value={settings.auth?.type}
            defaultValue='None'
            inputProps={register('settings.auth.type')}
            options={['None', 'basic']}
          />
        </Card>
        {settings.auth.type === 'basic' && (
          <Card className='eds-grid--m-col-3 no-padding'>
            <Label label='User' />
            <TextField {...register('settings.auth.basic.user')} />
          </Card>
        )}
        {settings.auth.type === 'basic' && (
          <Card className='eds-grid--m-col-3 no-padding'>
            <Label label='Password' />
            <TextField {...register('settings.auth.basic.password')} />
          </Card>
        )}
      </div>

      <div className='eds-spacing--mb-24 eds-grid eds-grid--m-cols-12'>
        <Card className='eds-grid--m-col-3 no-padding'>
          <Label label='Connection Encryption' required />
        </Card>
        <Card className='eds-grid--m-col-9 no-padding'>
          <Select
            value={settings.connectionEncryption?.type}
            defaultValue='None'
            inputProps={register('settings.connectionEncryption.type')}
            options={['None', 'ssl', 'unencrypted']}
          />
        </Card>
      </div>

      <DatabaseTable database='settings.database' table='settings.table' />

      <CreateTable createTable='settings.createTable' checked={settings.createTable} />
      <UpdateTable updateTable='settings.updateTable' checked={settings.updateTable} />

      <Mode mode='settings.mode' value={settings.mode} />

      <BatchCount batchCount='settings.batchCount' />

      <PrimaryKey
        type='settings.primaryKey.type'
        value={settings.primaryKey.type}
        recordFields='settings.primaryKey.record.fields'
      />

      <Timezone timezone='settings.timezone' value={settings.timezone} />

      <DeleteEnabled deleteEnabled='settings.deleteEnabled' checked={settings.deleteEnabled} />
    </div>
  )
}

export { SqlServer, sqlServerSettings }
