import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Checkbox } from '@nike/eds'

function DeleteEnabled({ deleteEnabled, checked }) {
  const { setValue } = useFormContext()

  return (
    <Checkbox
      checked={checked}
      onChange={() => setValue(deleteEnabled, !checked)}
      label='Delete Enabled - Whether to enable deletes on the sink. This is only supported for sinks of a CDC enabled source.'
    />
  )
}

export default DeleteEnabled
