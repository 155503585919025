import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useRef, useState, useCallback } from 'react'

import { Icon, Tooltip } from '@nike/eds'

import styles from './CopyableText.module.styl'

function CopyableText({
  text,
  maxLength,
  delaySec = 1.5,
  tooltipPlacement = 'right-end',
  noTooltip = false,
  showOnHover = false,
}) {
  const displayText = maxLength ? truncateMiddle(maxLength)(text) : text

  const [copied, setCopied] = useState(false)
  const tooltipContent = noTooltip || !copied ? `Copy to clipboard` : 'Copied'
  const timer = useRef()

  const onClick = useCallback(() => {
    navigator.clipboard.writeText(text)
    setCopied(true)
    timer.current = setTimeout(() => {
      setCopied(false)
    }, delaySec * 1000)
  }, [delaySec, setCopied, text, timer])

  return (
    // eslint-disable-next-line
    <div
      className={clsx(styles.text, { [styles.copied]: copied, [styles.showOnHover]: showOnHover })}
    >
      {displayText}
      {noTooltip ? (
        <CopyToClipboardButton copied={copied} onClick={onClick} />
      ) : (
        <Tooltip bodySlot={tooltipContent} placement={tooltipPlacement} enableFocus isDark>
          <CopyToClipboardButton copied={copied} onClick={onClick} />
        </Tooltip>
      )}
    </div>
  )
}

function CopyToClipboardButton({ copied, onClick }) {
  return (
    <div className={styles.copyButton}>
      <Icon name={copied ? 'Check' : 'CopyPaste'} onClick={onClick} size='m' />
    </div>
  )
}

function truncateMiddle(maxLength) {
  return (text) => {
    if (text.length <= maxLength) {
      return text
    }

    const half = Math.floor(maxLength / 2)
    return text.slice(0, half) + '...' + text.slice(-half)
  }
}

CopyableText.propTypes = {
  text: PropTypes.node.isRequired,
  maxLength: PropTypes.number,
  delaySec: PropTypes.number,
  // One of: auto, auto-start, auto-end, top, bottom, right, left, top-start, top-end,
  // bottom-start, bottom-end, right-start, right-end, left-start, left-end
  tooltipPlacement: PropTypes.string,
  noTooltip: PropTypes.bool,
  // Only show the copy affordance (button and tooltip) on hover or focus
  showOnHover: PropTypes.bool,
}

export default CopyableText
