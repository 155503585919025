import clsx from 'clsx'
import React from 'react'
import { useForm } from 'react-hook-form'

import {
  Button,
  Card,
  Icon,
  Label,
  Modal,
  Select,
  Snackbar,
  Snack,
  StatusIndicator,
  Text,
  TextField,
} from '@nike/eds'

import { putResource } from '../../../api'
import Hr from '../../../components/Hr'
import { findValue } from '../../../util/select'
import styles from './EditStream.module.styl'

function EditStream({ resourceName, resourceType, stream }) {
  const control = useForm()
  const { handleSubmit, register, setValue, watch } = control
  const settings = watch('settings') || stream.settings
  const name = stream.name
  const [modalVisible, setModalVisible] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const [error, setError] = React.useState({})
  const [success, setSuccess] = React.useState()

  const closeModal = React.useCallback(() => {
    setError({})
    setModalVisible(false)
  }, [setError, setModalVisible])

  const saveSettings = React.useCallback(
    async (settings) => {
      setSaving(true)

      const r = await putResource(
        resourceType === 'kstreams' ? 'sources' : resourceType,
        `${resourceName}/streams/${name}/settings`,
        settings
      )

      setSaving(false)
      if (r.status !== 200) setError({ message: r.body.message, errors: r.body.errors })
      else {
        setSuccess(r.body.message)
        closeModal()
      }
      return r
    },
    [closeModal, name, resourceName, resourceType]
  )

  const payloadOptions = [
    { value: '', label: '' },
    { value: 1, label: 1 },
    { value: 10, label: 10 },
  ]
  const payloadProps = register('settings.maxPayloadSize', { valueAsNumber: true })

  return (
    <>
      <Button size='small' variant='secondary' onClick={() => setModalVisible(true)}>
        <Icon name='Edit' className='eds-spacing--mr-8' />
        Settings
      </Button>
      {success && (
        <Snackbar>
          <Snack
            autoDismissDuration={5000}
            className={styles.successSnack}
            onDismiss={() => setSuccess('')}
            status='success'
          >
            <p>{success}</p>
          </Snack>
        </Snackbar>
      )}

      <Modal
        className='modal-1200'
        headerSlot={`Settings for stream ${name}`}
        isOpen={modalVisible}
        onDismiss={closeModal}
        footerSlot={
          <div className={styles.modalButtons}>
            <Button
              size='small'
              variant='Primary'
              disabled={saving}
              onClick={handleSubmit((data) => saveSettings(data))}
            >
              {saving ? 'Saving...' : 'Save'}
            </Button>
            <Button size='small' variant='secondary' onClick={closeModal}>
              Cancel
            </Button>
          </div>
        }
      >
        <form>
          <div className={clsx('eds-spacing--m-24 eds-spacing--p-24 eds-grid eds-grid--m-cols-12')}>
            <Card className='eds-grid--m-col-2 no-padding'>
              <Label>Max Payload Size</Label>
            </Card>
            <Card className='eds-grid--m-col-3 no-padding'>
              <Select
                {...payloadProps}
                hideLabel
                onChange={(selected) =>
                  setValue('settings.maxPayloadSize', selected ? selected.value : '', true)
                }
                options={payloadOptions}
                value={findValue(payloadOptions, settings?.maxPayloadSize)}
              />
            </Card>
            <Card className='eds-grid--m-col-7 no-padding'>
              <Text font='body-3' as='div'>
                The maximum payload request size in MB to be expected for this stream. Note this
                setting is only available for Producer-type sources. User must also be authorized to
                apply a setting larger than 1 MB. Please submit a request to gain access to this
                setting at https://confluence.nike.com/x/xBWUEw
              </Text>
            </Card>

            <Card className='eds-grid--m-col-2 no-padding'>
              <Label>Partition Count</Label>
            </Card>
            <Card className='eds-grid--m-col-3 no-padding'>
              <TextField
                {...register('settings.partitionCount', { valueAsNumber: true })}
                type='number'
                value={settings?.partitionCount}
              />
            </Card>
            <Card className='eds-grid--m-col-7 no-padding'>
              <Text font='body-3' as='div'>
                The number of partitions per topic. If not configured at the default level for a
                source or more specifically on an individual stream then topics will be configured
                with 1 partition.
              </Text>
            </Card>

            <Card className='eds-grid--m-col-12 no-padding'>
              <Hr />
            </Card>

            <Card className='eds-grid--m-col-5 no-padding'>
              <Label>Retention</Label>
            </Card>
            <Card className='eds-grid--m-col-7 no-padding'>
              <Text font='body-3' as='div'>
                The settings that control how to retain messages before they are discarded.
              </Text>
            </Card>

            <Card className='eds-grid--m-col-2 no-padding'>
              <Label>Size</Label>
            </Card>
            <Card className='eds-grid--m-col-3 no-padding'>
              <TextField
                {...register('settings.retention.size', { valueAsNumber: true })}
                type='number'
                value={settings?.retention?.size}
              />
            </Card>
            <Card className='eds-grid--m-col-7 no-padding'>
              <Text font='body-3' as='div'>
                The data retention window for all topics in megabytes. Messages are removed from
                Kafka once the topic&apos;s cumulative size exceeds this threshold. If not
                configured at the default level for a source or more specifically on an individual
                stream then messages will be retained up to 20000 megabytes.
              </Text>
            </Card>

            <Card className='eds-grid--m-col-2 no-padding'>
              <Label>Time</Label>
            </Card>
            <Card className='eds-grid--m-col-3 no-padding'>
              <TextField
                {...register('settings.retention.time', { valueAsNumber: true })}
                type='number'
                value={settings?.retention?.time}
              />
            </Card>
            <Card className='eds-grid--m-col-7 no-padding'>
              <Text font='body-3' as='div'>
                The data retention window for all topics in milliseconds. Messages are removed from
                Kafka once they have remained on the system for this length of time. If not
                configured at the default level for a source or more specifically on an individual
                stream then messages will be retained for 345600000 milliseconds (4 days).
              </Text>
            </Card>
          </div>
          {error.message && (
            <div>
              <Snack hideDismiss status='error'>
                <p>{error.message}</p>
                {error.errors &&
                  error.errors.map((e, i) => (
                    <StatusIndicator key={i} status='inactive' label={e.description} />
                  ))}
              </Snack>
            </div>
          )}
        </form>
      </Modal>
    </>
  )
}

export default EditStream
