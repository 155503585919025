import merge from 'deepmerge'

export function determineEnvConfig(location) {
  const url = new URL(location)
  const host = (x) => url.hostname.includes(x)
  const isInConsole = window.location.href.indexOf('platforms.nike.com') !== -1
  const basePath = isInConsole ? '/nsp' : ''

  const base = {
    isInConsole,
    basePath,
    apiUrl: 'https://api.gem-dev.platforms.nike.com/v1/',
    cloudRedUrl: 'https://cloudred-staging.nike.com',
    developerapiUrl: 'https://api-developer.preprod.niketech.com/v1/apps',
    narkUrl: 'https://api-preprod.nark.nikecloud.com/v1',
    narkPlatformId: '==eF9QpiN5g0NgOm00ad8Aw',
    oAuth: {
      // PKCE authorization flow
      clientId: 'nike.niketech.nsp',
      issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
      redirectUri: url.origin + basePath + '/login/callback',
      // https://support.okta.com/help/s/article/Signature-Validation-Failed-on-Access-Token?language=en_US
      ignoreSignature: true, // this prevents the exception "The key id, ..., was not found in the server's keys"
    },
    platformId: '30272795-d024-4b5c-885e-b4d4f40f32b0',
  }

  switch (true) {
    case host('nsp.cx-platform-prod.nikecloud.com'):
    case host('console.platforms.nike.com'):
      return merge(base, {
        env: 'prod',
        apiUrl: 'https://api.gem.platforms.nike.com/v1/',
        cloudRedUrl: 'https://cloudred.nike.com',
        narkUrl: 'https://api.nark.nikecloud.com/v1',
        narkPlatformId: '==1wvQxpoB2asVz_XsiIgF0',
        developerapiUrl: 'https://api-developer.niketech.com/prod/v1/apps',
        oAuth: {
          issuer: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7',
        },
      })

    case host('console-stage.platforms.nike.com'):
      return merge(base, {
        env: 'staging',
        apiUrl: 'https://api.gem-stage.platforms.nike.com/v1/',
        cloudRedUrl: 'https://cloudred-staging.nike.com',
      })

    case host('nsp-preprod.cx-platform-test.nikecloud.com'):
    case host('console-nonprod.platforms.nike.com'):
      return merge(base, {
        env: 'nonprod',
        apiUrl: 'https://api.gem-nonprod.platforms.nike.com/v1/',
        cloudRedUrl: 'https://cloudred-staging.nike.com',
      })

    case host('nsp-dev.cx-platform-test.nikecloud.com'):
    case host('console-dev.platforms.nike.com'):
      return merge(base, {
        env: 'dev',
      })

    case host('localhost'):
    default:
      return merge(base, {
        env: 'local',
      })
  }
}

const config = determineEnvConfig(window.location)

export default config
