import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Button, Card, Checkbox, Icon, Text, TextField } from '@nike/eds'

import { Label, Select } from '../../components/FormComponents'

const description = {
  and: 'A list of filters for which each must match.',
  boolean: 'A filter against a boolean-valued field.',
  integer: 'A filter against a integer-valued field',
  not: 'A filter that must not match.',
  or: 'A list of filters for which at least one must match. If the list is empty then this never matches.',
  string: 'A filter against a string-valued field.',
}

function FilterBoolean({ fieldNamePrefix }) {
  const { register, watch } = useFormContext()
  let booleanValue = watch(fieldNamePrefix + '.boolean.value')
  if (booleanValue === undefined) booleanValue = true

  return (
    <div className='eds-spacing--mt-16 eds-grid eds-grid--m-cols-8'>
      <Card className='eds-grid--m-col-2 no-padding'>
        <TextField
          {...register(fieldNamePrefix + '.boolean.field', { required: true })}
          className='eds-spacing--mb-16 no-margin'
          label={<Label label='Field' required />}
        />
      </Card>
      <Card className='eds-grid--m-col-6 no-padding'>
        <Label label='Value' />
        ==&nbsp;
        <Select
          options={['true', 'false']}
          value={booleanValue.toString()}
          inputProps={register(fieldNamePrefix + '.boolean.value')}
        />
        &nbsp;The boolean value to check for in the configured field.
      </Card>
    </div>
  )
}

const Comparisons = [
  { label: '==', value: 'equal' },
  { label: '>', value: 'greaterThan' },
  { label: '>=', value: 'greaterThanOrEqual' },
  { label: '<', value: 'lessThan' },
  { label: '<=', value: 'lessThanOrEqual' },
]

function FilterInteger({ fieldNamePrefix }) {
  const { register, watch } = useFormContext()
  const comparison = watch(fieldNamePrefix + '.integer.comparison') || 'equal'

  return (
    <div className='eds-spacing--mt-16 eds-grid eds-grid--m-cols-12'>
      <Card className='eds-grid--m-col-2 no-padding'>
        <TextField
          {...register(fieldNamePrefix + '.integer.field', { required: true })}
          className='eds-spacing--mb-16 no-margin'
          label={<Label label='Field' required />}
        />
      </Card>
      <Card className='eds-grid--m-col-2 no-padding'>
        <Label label='Comparison' />
        <Select
          value={comparison}
          options={Comparisons}
          inputProps={register(fieldNamePrefix + '.integer.comparison', { required: true })}
          className='eds-spacing--mb-16'
        />
      </Card>
      <Card className='eds-grid--m-col-8 no-padding'>
        <TextField
          {...register(fieldNamePrefix + '.integer.value', { required: true })}
          className='eds-spacing--mb-16 no-margin'
          label={<Label label='Value' required />}
        />
        &nbsp;The integer value to check for in the configured field.
      </Card>
    </div>
  )
}

const Logic = ['equal', 'contains', 'startsWith', 'endsWith']
function FilterString({ fieldNamePrefix }) {
  const { register, watch, setValue } = useFormContext()
  const string = watch(fieldNamePrefix + '.string') || {
    logic: Logic[0],
    caseInsensitive: false,
  }

  return (
    <div className='eds-spacing--mt-16 eds-grid eds-grid--m-cols-12'>
      <Card className='eds-grid--m-col-2 no-padding'>
        <TextField
          {...register(fieldNamePrefix + '.string.field', { required: true })}
          className='eds-spacing--mb-16 no-margin'
          label={<Label label='Field' required />}
        />
        <Text font='subtitle-2'>
          The name of the field to apply the filter against. Nested fields should be separated by a
          period.
        </Text>
      </Card>
      <Card className='eds-grid--m-col-2 no-padding'>
        <Label label='Logic' />
        <Select
          value={string.logic}
          options={Logic}
          inputProps={register(fieldNamePrefix + '.string.logic', { required: true })}
          className='eds-spacing--mb-16'
        />
        <Text font='subtitle-2'>The logic to apply in making the match.</Text>
      </Card>
      <Card className='eds-grid--m-col-8 no-padding'>
        <TextField
          {...register(fieldNamePrefix + '.string.value', { required: true })}
          className='eds-spacing--mb-16  no-margin'
          label={<Label label='Value' required />}
        />
        <div>The value to check for in the configured field.</div>
        <Checkbox
          checked={string.caseInsensitive}
          onChange={() =>
            setValue(fieldNamePrefix + '.string.caseInsensitive', !string.caseInsensitive)
          }
          label='Case Insensitive - Whether to match case.'
        />
      </Card>
    </div>
  )
}

function FilterAnd({ fieldNamePrefix }) {
  const { watch, setValue } = useFormContext()
  let and = watch(fieldNamePrefix)
  if (!and) and = { filters: [{}] }

  return (
    <div>
      {and.filters.map((filter, i) => (
        <div key={i}>
          <Button
            variant='secondary'
            size='s'
            onClick={() => {
              and.filters.splice(i, 1)
              setValue(fieldNamePrefix, and)
            }}
            className='eds-spacing--mt-8 no-padding'
          >
            <Icon name='Close' />
          </Button>
          <FilterForm fieldNamePrefix={fieldNamePrefix + '.filters.' + i} />
        </div>
      ))}
      <Button
        variant='secondary'
        size='s'
        onClick={() => {
          and.filters.push({})
          setValue(fieldNamePrefix, and)
        }}
        className='nsp-input-element no-padding'
      >
        <Icon name='CreateRule' />
      </Button>
    </div>
  )
}

function FilterOr({ fieldNamePrefix }) {
  const { watch, setValue } = useFormContext()
  let or = watch(fieldNamePrefix)
  if (!or) or = { filters: [{}] }

  return (
    <div>
      {or.filters.map((filter, i) => (
        <div key={i}>
          <Button
            variant='secondary'
            size='s'
            onClick={() => {
              or.filters.splice(i, 1)
              setValue(fieldNamePrefix, or)
            }}
            className='eds-spacing--mt-8 no-padding'
          >
            <Icon name='Close' />
          </Button>
          <FilterForm fieldNamePrefix={fieldNamePrefix + '.filters.' + i} />
        </div>
      ))}
      <Button
        variant='secondary'
        size='s'
        onClick={() => {
          or.filters.push({})
          setValue(fieldNamePrefix, or)
        }}
        className='eds-spacing--mb-16'
      >
        <Icon name='CreateRule' />
      </Button>
    </div>
  )
}

function FilterForm({ fieldNamePrefix }) {
  const { watch, register } = useFormContext()
  const filter = watch(fieldNamePrefix) || {}

  return (
    <Card padding={24} className='eds-spacing--mb-16'>
      <Label label='Filter' />
      <Select
        value={filter.type}
        options={['and', 'boolean', 'integer', 'not', 'or', 'string']}
        optional
        inputProps={register(fieldNamePrefix + '.type')}
      />

      <Text font='body-2' as='span' className='eds-spacing--ml-16'>
        {description[filter.type]}
      </Text>
      {filter.type === 'boolean' && <FilterBoolean fieldNamePrefix={fieldNamePrefix} />}
      {filter.type === 'integer' && <FilterInteger fieldNamePrefix={fieldNamePrefix} />}
      {filter.type === 'string' && <FilterString fieldNamePrefix={fieldNamePrefix} />}
      {filter.type === 'and' && <FilterAnd fieldNamePrefix={fieldNamePrefix + '.and'} />}
      {filter.type === 'or' && <FilterOr fieldNamePrefix={fieldNamePrefix + '.or'} />}
      {filter.type === 'not' && <FilterForm fieldNamePrefix={fieldNamePrefix + '.not.filter'} />}
    </Card>
  )
}

function getFilter(filter) {
  const type = filter?.type
  if (!type) return null
  const body = { type }
  if (type === 'boolean') {
    const value = filter.boolean.value
    filter.boolean.value = value === 'true' || value === true
    body.boolean = filter.boolean
  }
  if (type === 'integer') {
    body.integer = filter.integer
    body.integer.value = parseInt(body.integer.value)
  }
  if (type === 'string') body.string = filter.string
  if (type === 'and') body.and = { filters: filter.and?.filters.map((and) => getFilter(and)) }
  if (type === 'or') body.or = { filters: filter.or?.filters.map((or) => getFilter(or)) }
  if (type === 'not') body.not = { filter: getFilter(filter.not.filter) }
  return body
}

export { FilterForm, getFilter }
