import { useEffect } from 'react'

import { NarkPlatform } from '@nike/nark-sdk'

import config from '../config'
import { getOktaToken } from './utils'

const platform = {
  source: 'ui',
  url: config.narkUrl,
  platformId: config.narkPlatformId,
  token: null,
}
let nark // initialized and updated below

/**
 * Log something to the [Nark service](https://console.platforms.nike.com/developer/docs/projects/nark)
 *
 * `Feature` and `Operation` can be any string value. This is so, platforms, can define how to break up metrics when users, use a Feature and invoke a specific Operation
 *
 * @param {enum} ["ui", "api", "platform"] `source` - source from which metric is being sent to Nark
 * @param {string} `feature` - platform Feature to group metrics while searching (platform specific) **WHAT**
 * @param {string} `operation` - Platform Operation to group metrics while searching (platform specific) **HOW**
 * @param {string} `sessionId` - Associate a session context for querying (platform specific; should be unique keys)
 * @param {string} `traceId - Associate a trace ID to a set of metrics for querying (platform specific; should be unique keys)
 * @param {object} `data` - Any additional data you want to post
 * @return {void}
 */
export function NarkLog({ source = 'ui', feature, operation, sessionId, traceId, data }) {
  const token = getOktaToken()
  if (!token) return
  if (!nark || token !== platform.token) {
    platform.token = token
    nark = new NarkPlatform(platform)
  }

  const payload = {
    source,
    feature,
    operation,
    ...(sessionId && { sessionId }),
    ...(traceId && { traceId }),
    ...(data && { data }),
  }

  // POST to the nark resource
  nark.log(payload)
}

export const useNarkOnPageLoad = (pageName) => {
  useEffect(() => {
    NarkLog({
      source: 'ui',
      feature: pageName,
      operation: 'page.load',
    })
  }, [pageName])
}
