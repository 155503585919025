import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import '@nike/eds/dist/index.css'
import '@nike/epic-react-ui/dist/styles.css'

import App from './App'
import config from './config'
import './globals.styl'

ReactDOM.render(
  <BrowserRouter basename={config.basePath}>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)
