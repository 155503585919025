import React from 'react'

import RecordDetails from '../../../components/RecordDetails'
import ConnectionDetails from './ConnectionDetails'
import StreamSettings from './StreamSettings'

function SqlServerForm() {
  return (
    <>
      <ConnectionDetails />
      <RecordDetails name='settings.sqlserver' />
      <StreamSettings />
    </>
  )
}

export default SqlServerForm
