import React from 'react'

import { Text } from '@nike/eds'

import styles from './NotFound.module.styl'

const NotFoundPage = () => (
  <div className={styles.noMatch}>
    <Text as={'p'} font={'title-3'}>
      {"Oops! We couldn't find the page you were looking for."}
    </Text>
  </div>
)

export default NotFoundPage
