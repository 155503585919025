import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { TextField, Select } from '@nike/eds'

import { Label } from '../../../../components/FormComponents'

function Oscar() {
  const { setValue, register, control } = useFormContext()
  const name = 'settings.http.auth.oscar'
  const options = [
    { label: 'test', value: 'test' },
    { label: 'production', value: 'production' },
  ]

  return (
    <>
      <Label label='Environment' required tooltip={'The environment of the Oscar server.'} />
      <Controller
        control={control}
        // Defaults to first value in options array
        defaultValue={options.find((option) => option.value === 'test')?.value}
        name={`${name}.environment`}
        render={({ field }) => (
          <Select
            value={options.filter((option) => field.value === option.value)}
            onChange={(e) => setValue(`${name}.environment`, e.value)}
            options={options}
            className={'eds-spacing--mb-16'}
          />
        )}
      />
      <TextField
        {...register(`${name}.scope`)}
        required
        className='eds-spacing--mb-24'
        placeholder=''
        label={
          <Label
            label='Scope'
            required
            tooltip={
              'The scope that is required on each request. The scope will be taken from the "scope" claim on the input JWT.'
            }
          />
        }
      />
    </>
  )
}
export default Oscar
