import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { Icon, Radio, Select, Text, TextField, Tooltip } from '@nike/eds'

import { Label } from './FormComponents'

function AlarmAuth({ index }) {
  const { setValue, register, control, watch } = useFormContext()
  const alarmType = watch(`alarms[${index}].resource.type`, 'pagerduty')

  const options = [
    { label: 'v1', value: 'v1' },
    { label: 'v2', value: 'v2' },
  ]

  return (
    <>
      <Text font='title-6' className='eds-spacing--mt-24 eds-spacing--mb-24'>
        Auth
        <Tooltip bodySlot={<Text font='subtitle-2'>Auth</Text>} placement='right' isDark={true}>
          <Icon name='InfoCircle' className='infoCircle' />
        </Tooltip>
      </Text>
      <div className='eds-grid eds-grid--m-cols-8'>
        <Radio
          name={`alarm_auth_${index}`}
          id={`auth_pagerduty_${index}`}
          value='pagerduty'
          className='eds-spacing--mb-24'
          checked={alarmType !== 'slack'}
          label={<Text font='body-2'>pagerduty</Text>}
          onClick={(e) => setValue(`alarms[${index}].resource.type`, e.target.value)}
        />
        <Radio
          name={`alarm_auth_${index}`}
          id={`auth_slack_${index}`}
          value='slack'
          checked={alarmType === 'slack'}
          className='eds-spacing--mb-24'
          label={<Text font='body-2'>slack</Text>}
          onClick={(e) => setValue(`alarms[${index}].resource.type`, e.target.value)}
        />
      </div>
      {alarmType === 'slack' ? (
        <>
          <TextField
            {...register(`alarms[${index}].resource.slack.channel`, { required: true })}
            className='eds-spacing--mb-16'
            required
            label={
              <Label
                label='Channel'
                tooltip='The Slack Channel that these alarms should post to.'
                required
              />
            }
          />
          <TextField
            {...register(`alarms[${index}].resource.slack.webhookURL`, { required: true })}
            className='eds-spacing--mb-16'
            required
            label={
              <Label
                label='Webhook Url'
                tooltip='The Slack Webhook URL that the alarms should post to.'
                required
              />
            }
          />
        </>
      ) : (
        <>
          <Label label='API Version' tooltip='The PagerDuty Events API version.' required />
          <Controller
            control={control}
            defaultValue={options.find((option) => option.value === 'v2')?.value}
            name={`alarms[${index}].resource.pagerduty.apiVersion`}
            render={({ field }) => (
              <Select
                value={options.filter((option) => field.value === option.value)}
                onChange={(e) =>
                  setValue(`alarms[${index}].resource.pagerduty.apiVersion`, e.value)
                }
                options={options}
                className={'eds-spacing--mb-16'}
              />
            )}
          />
          <TextField
            {...register(`alarms[${index}].resource.pagerduty.integrationKey`, { required: true })}
            className='eds-spacing--mb-16'
            required
            label={
              <Label label='Integration Key' tooltip='The Pagerduty integration id.' required />
            }
          />
        </>
      )}
    </>
  )
}

export default AlarmAuth
