import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Button, Card, Icon, TextField } from '@nike/eds'

import { Label, Select } from '../../../components/FormComponents'

function PrimaryKey({ type, value, recordFields }) {
  const { register, setValue } = useFormContext()
  const [fields, setFields] = useState([])

  return (
    <>
      <div className='eds-spacing--mb-24 eds-grid eds-grid--m-cols-4'>
        <div>
          <Label label='Primary Key' />
        </div>
        <div>
          <Select
            value={value}
            defaultValue='None'
            inputProps={register(type)}
            options={['None', 'record']}
          />
        </div>
      </div>

      {value === 'record' && (
        <div className='eds-spacing--pl-24'>
          <Label label='Fields' required />
          {fields.map((field, i) => (
            <div key={i} className='eds-spacing--mb-16 eds-grid eds-grid--m-cols-10'>
              <Card className='eds-grid--m-col-3 no-padding'>
                <TextField
                  key={i}
                  value={field}
                  onChange={(e) => {
                    fields[i] = e.target.value
                    setFields([...fields])
                    setValue(recordFields, fields)
                  }}
                />
              </Card>
              <Card className='eds-grid--m-col-1 no-padding'>
                <Button
                  variant='secondary'
                  size='s'
                  onClick={() => {
                    fields.splice(i, 1)
                    setFields([...fields])
                    setValue(recordFields, fields)
                  }}
                  className='eds-spacing--mt-24'
                >
                  <Icon name='Close' size='s' />
                </Button>
              </Card>
            </div>
          ))}
          <Button
            variant='secondary'
            size='s'
            onClick={() => setFields([...fields, ''])}
            className='eds-spacing--mb-16'
          >
            Add Field
          </Button>
        </div>
      )}
    </>
  )
}

export default PrimaryKey
