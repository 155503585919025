import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Checkbox } from '@nike/eds'

function CreateTable({ createTable, checked }) {
  const { setValue } = useFormContext()

  return (
    <Checkbox
      checked={checked}
      onChange={() => setValue(createTable, !checked)}
      label='Create Table - Whether to create tables that do not exist in the destination database.'
      className='eds-spacing--mb-24'
    />
  )
}

export default CreateTable
