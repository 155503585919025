import React from 'react'

import { NarkLog } from './util/nark'

// *NOTE: Hooks is not having equivalent for componentDidCatch. please refer to https://reactjs.org/docs/hooks-faq.html
class ErrorBoundary extends React.Component {
  state = { hasError: false }
  static errorHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    ErrorBoundary.logErrorToNark(e?.error?.message)
    return false
  }
  static logErrorToNark = (message) => {
    NarkLog({
      feature: 'error', // *Note: It's a feature level so we can query/omit easier in New Relic
      operation: message,
    })
  }
  static getDerivedStateFromError() {
    return { hasError: true }
  }
  // TODO: When React version updates we can use functional component
  UNSAFE_componentWillMount() {
    window.addEventListener('error', ErrorBoundary.errorHandler, false)
  }
  componentWillUnmount() {
    window.removeEventListener('error', ErrorBoundary.errorHandler)
  }
  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong while rendering</h1>
    }
    return this.props.children
  }
}
export default ErrorBoundary
