import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'

import { Button, Text, Skeleton, Snack, TextField, Modal, ButtonGroup, Snackbar } from '@nike/eds'
import { Delete, Edit, Replay } from '@nike/nike-design-system-icons'

import { deleteResource, putResourceAction } from '../../api'
import styles from './DangerZone.module.styl'

const nonConnectorTypes = ['kafka.http.push', 'kafka.producer', 'kafka.consumer']

function DangerZone({ resourceType, resource }) {
  const history = useHistory()
  const [restartSnack, setRestartSnack] = useState()
  const [deleteSnack, setDeleteSnack] = useState()
  const [deleteModal, setDeleteModal] = useState(false)
  const [confirm, setConfirm] = useState('')
  const resourceName = resource.name

  function restart() {
    putResourceAction(resourceType, resourceName, 'restart')
      .then((r) => {
        const status = r?.status === 200 ? 'success' : 'error'
        setRestartSnack({ status: status, message: r.message })
      })
      .catch((error) => {
        setRestartSnack({ status: 'error', message: 'Failed to restart - ' + error.message })
      })
  }
  function deleteThis() {
    if (resourceType === 'kstreams') resourceType = 'sources'
    setDeleteSnack({ status: 'info', message: 'Deleting resource...' })
    deleteResource(resourceType, resourceName)
      .then((r) => history.push(`/${resourceType}?access=2`))
      .catch((error) =>
        setDeleteSnack({ status: 'error', message: `Failed to delete - (${error.message})` })
      )
  }

  return (
    <div data-testid='danger-zone' className='eds-spacing--mt-16'>
      <Text font='title-4' className='eds-spacing--mb-16'>
        Danger Zone
      </Text>
      {!nonConnectorTypes.includes(resource?.type) && (
        <div className='eds-spacing--mb-36'>
          <Button
            beforeSlot={<Replay />}
            variant='secondary'
            className={styles.buttonStyle}
            onClick={restart}
          >
            Restart
          </Button>
          <h4>Restart</h4>
          <p>Restart will cause a delay in data transmission.</p>
          {restartSnack && (
            <Snack status={restartSnack.status} onDismiss={() => setRestartSnack(null)}>
              {restartSnack.message}
            </Snack>
          )}
        </div>
      )}
      <div className='eds-spacing--mb-36'>
        <Link to={`/${resourceType}/edit?resource=${resource.name}`}>
          <Button beforeSlot={<Edit />} variant='secondary' className={styles.buttonStyle}>
            Edit
          </Button>
        </Link>
        <h4>Edit</h4>
        <p>This operation will change the behavior.</p>
      </div>
      <div className='eds-spacing--mb-36'>
        <Button
          beforeSlot={<Delete className={styles.deleteColor} />}
          variant='secondary'
          className={styles.deleteButton}
          onClick={() => setDeleteModal(true)}
          data-testid='danger-zone-delete-btn'
        >
          Delete
        </Button>
        <h4>Delete</h4>
        <p>This operation cannot be undone! Proceed with caution!</p>
      </div>
      {deleteSnack && (
        <Snackbar>
          <Snack status={deleteSnack.status} onDismiss={() => setDeleteSnack(null)}>
            {deleteSnack.message}
            {deleteSnack.status === 'info' && <Skeleton height={8} />}
          </Snack>
        </Snackbar>
      )}
      <Modal
        onDismiss={() => setDeleteModal(!deleteModal)}
        isOpen={deleteModal}
        headerSlot={'I sure hope you know what you are doing...'}
        footerSlot={
          <>
            <ButtonGroup>
              <Button onClick={() => setDeleteModal(!deleteModal)} size='small' variant='secondary'>
                Cancel
              </Button>
              <Button
                size='small'
                disabled={confirm !== resourceName || deleteSnack}
                onClick={deleteThis}
              >
                Delete
              </Button>
            </ButtonGroup>
          </>
        }
      >
        <Text font={'body-2'} as={'p'}>
          Deleting a resource is an operation that cannot be undone.
        </Text>
        <Text font={'body-2'} as={'p'}>
          The resource will no longer read/write to the configured destination.
        </Text>
        <Text font={'body-2'} as={'p'} className={'eds-spacing--mt-16'}>
          <b>Please enter {resourceName} to confirm delete:</b>
        </Text>
        <TextField
          data-testid='delete-resource-input'
          placeholder={resourceName}
          onChange={(e) => setConfirm(e.target.value)}
        />
      </Modal>
    </div>
  )
}

export default DangerZone
