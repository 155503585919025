import React from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'

import { Card, TextField, Button } from '@nike/eds'

import { CardTitle, Label } from '../../../components/FormComponents'
import Stream from './Stream'

function StreamWrapper() {
  const name = 'settings.producer'
  const { register, control } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${name}.streams`, // unique name for your Field Array
  })

  return (
    <Card padding={24}>
      <CardTitle title='Streams' tooltip='The list of stream objects for the source.' />
      {fields.map((field, index) => (
        <div key={field.id}>
          <CardTitle title={`Stream ${index + 1}`} />
          <TextField
            {...register(`${name}.streams[${index}].name`, { required: true })}
            className='eds-spacing--mb-16'
            required
            label={<Label label='Name' tooltip='The name of the stream' required />}
          />
          <Stream index={index} />
          <Button
            variant='secondary'
            style={{ float: 'right' }}
            className='eds-spacing--mb-24'
            onClick={() => remove(index)}
          >
            Remove Stream
          </Button>
        </div>
      ))}

      <Button variant='secondary' className='eds-spacing--mb-24' onClick={() => append({})}>
        Add Stream
      </Button>
    </Card>
  )
}

export default StreamWrapper
