import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Button, Card, Icon, TextField } from '@nike/eds'

import { Label, Select } from '../../components/FormComponents'

const Transformers = [
  'None',
  'cast',
  'convertDateTime',
  'encodeCollections',
  'flatten',
  'insertField',
  'mask',
  'rename',
]

const CastTypes = ['boolean', 'float32', 'float64', 'int16', 'int32', 'int64', 'int8', 'string']

function CastForm({ prefix }) {
  const { register, watch } = useFormContext()
  const cast = watch(prefix + '.cast') //|| { field: '', newType: 'boolean' }

  return (
    <>
      <Card className='eds-grid--m-col-3 no-padding'>
        <TextField
          label={<Label label='Field' required />}
          {...register(prefix + '.cast.fields[0].field', { required: true })}
          className='no-margin'
        />
      </Card>
      <Card className='eds-grid--m-col-6 no-padding'>
        <Label label='New Type' required />
        <Select
          value={cast?.fields[0].newType}
          options={CastTypes}
          inputProps={register(prefix + '.cast.fields[0].newType')}
          className='eds-spacing--mb-16'
        />
      </Card>
    </>
  )
}

const DateTimeTypes = ['unix', 'string']

function ConvertDateTimeForm({ prefix }) {
  const { register, watch } = useFormContext()
  let convertDateTime = watch(prefix + '.convertDateTime')
  if (!convertDateTime) convertDateTime = { fields: [{ to: { type: DateTimeTypes[0] } }] }

  return (
    <>
      <Card className='eds-grid--m-col-3 no-padding'>
        <TextField
          label={<Label label='Field' required />}
          {...register(prefix + '.convertDateTime.fields[0].field', { required: true })}
        />
      </Card>
      <Card className='eds-grid--m-col-3 no-padding'>
        <TextField
          label={<Label label='Original Format' required />}
          {...register(prefix + '.convertDateTime.fields[0].originalFormat', { required: true })}
        />
      </Card>
      <Card className='eds-grid--m-col-3 no-padding'>
        <Label label='To' required />
        <Select
          options={DateTimeTypes}
          value={convertDateTime.fields[0]?.to?.type}
          inputProps={register(prefix + '.convertDateTime.fields[0].to.type')}
          className='eds-spacing--mb-16'
        />
      </Card>
      {convertDateTime.to?.type === 'string' && (
        <Card className='eds-grid--m-col-3 no-padding'>
          <TextField
            label={<Label label='Format' required />}
            {...register(prefix + '.convertDateTime.fields[0].to.string.format', {
              required: true,
            })}
          />
        </Card>
      )}
    </>
  )
}

function InsertFieldForm({ prefix }) {
  const { register, watch } = useFormContext()
  let insertField = watch(prefix + '.insertField')
  if (!insertField)
    insertField = { fields: [{ field: '', value: { type: 'timestamp', timestamp: {} } }] }

  return (
    <>
      <Card className='eds-grid--m-col-3 no-padding'>
        <TextField
          label={<Label label='Field' required />}
          {...register(prefix + '.insertField.fields[0].field', { required: true })}
        />
      </Card>
      <Card className='eds-grid--m-col-3 no-padding'>
        <Label label='Value' required />
        <Select
          value={insertField.fields[0].value.type}
          options={['timestamp']}
          inputProps={register(prefix + '.insertField.fields[0].value.type')}
          className='eds-spacing--mb-16'
        />
      </Card>
    </>
  )
}

function MaskForm({ prefix }) {
  const { register } = useFormContext()

  return (
    <Card className='eds-grid--m-col-3 no-padding'>
      <TextField
        label={<Label label='Field' required />}
        {...register(prefix + '.mask.fields[0]', { required: true })}
      />
    </Card>
  )
}

function RenameForm({ prefix }) {
  const { register } = useFormContext()

  return (
    <>
      <Card className='eds-grid--m-col-3 no-padding'>
        <TextField
          label={<Label label='From' required />}
          {...register(prefix + '.rename.fields[0].from', { required: true })}
        />
      </Card>
      <Card className='eds-grid--m-col-3 no-padding'>
        <TextField
          label={<Label label='To' required />}
          {...register(prefix + '.rename.fields[0].to', { required: true })}
        />
      </Card>
    </>
  )
}

function TransformersForm() {
  const { setValue, register, watch } = useFormContext()
  const transformers = watch('transformers') || []

  return (
    <Card padding={24} className='eds-spacing--mb-24'>
      <Label label='Transformers' />
      {transformers.map((transformer, i) => (
        <div key={i} className='eds-spacing--mb-16 eds-grid eds-grid--m-cols-12'>
          <Card className='eds-grid--m-col-3 no-padding'>
            <Select
              value={transformer.type}
              defaultValue='None'
              options={Transformers}
              inputProps={register(`transformers[${i}].type`)}
              className='eds-spacing--mb-16'
            />
          </Card>
          <Card className='eds-grid--m-col-9 no-padding'>
            <Button
              variant='secondary'
              size='s'
              onClick={() => {
                transformers.splice(i, 1)
                setValue('transformers', transformers)
              }}
              className='eds-spacing--mt-8 no-padding'
            >
              <Icon name='Close' size='s' />
            </Button>
          </Card>
          {transformer.type === 'cast' && <CastForm prefix={`transformers[${i}]`} />}
          {transformer.type === 'convertDateTime' && (
            <ConvertDateTimeForm prefix={`transformers.${i}`} />
          )}
          {transformer.type === 'insertField' && <InsertFieldForm prefix={`transformers.${i}`} />}
          {transformer.type === 'mask' && <MaskForm prefix={`transformers[${i}]`} />}
          {transformer.type === 'rename' && <RenameForm prefix={`transformers[${i}]`} />}
        </div>
      ))}
      <Button
        variant='secondary'
        onClick={() => {
          transformers.push({ type: Transformers[0] })
          setValue('transformers', transformers)
        }}
        className='eds-spacing--mb-16'
      >
        Add Transformer
      </Button>
    </Card>
  )
}

function getTransformers(transformers) {
  if (!transformers) return null
  const body = transformers
    .filter((transformer) => transformer.type !== Transformers[0]) // skip default type
    .map((transformer) => {
      const transformerBody = { type: transformer.type }
      if (transformer.type === 'cast') transformerBody.cast = transformer.cast
      if (transformer.type === 'convertDateTime')
        transformerBody.convertDateTime = transformer.convertDateTime
      if (transformer.type === 'encodeCollections') transformerBody.encodeCollections = {}
      if (transformer.type === 'flatten') transformerBody.flatten = {}
      if (transformer.type === 'insertField') {
        transformer.insertField.fields[0].value.timestamp = {}
        transformerBody.insertField = transformer.insertField
      }
      if (transformer.type === 'mask') transformerBody.mask = transformer.mask
      if (transformer.type === 'rename') transformerBody.rename = transformer.rename
      return transformerBody
    })
  return body
}

export { TransformersForm, getTransformers }
