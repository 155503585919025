import React from 'react'

import ConnectionDetails from './ConnectionDetails'
import StreamSettings from './StreamSettings'

function IbmMqForm() {
  return (
    <>
      <ConnectionDetails />
      <StreamSettings />
    </>
  )
}

export default IbmMqForm
