import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Button, Snack, StatusIndicator, Card } from '@nike/eds'
import { Null } from '@nike/nike-design-system-icons'

import { postFilter, putResourceAction } from '../../api'
import { kafkaTypes } from '../../util/kafka'
import SinkStatus from './SinkStatus'
import styles from './Sinks.module.styl'

async function getSinks(source, streamName, setSinks) {
  const filter = {
    source: {
      type: 'string',
      string: {
        field: 'name',
        logic: { equal: source.name },
      },
    },
  }
  const r = await postFilter('sink', filter)
  const sinks = r.sinks.filter((sink) => sink.source?.stream === streamName)
  setSinks(sinks)
}

function Sinks({ source, stream }) {
  const [sinks, setSinks] = useState()
  const [revoke, setRevoke] = useState({})
  const canRevoke = source.allowedActions.includes('source:RevokeSink')

  useEffect(() => {
    getSinks(source, stream.name, setSinks)
  }, [source, stream.name])

  const revokeSink = React.useCallback(
    async (sinkName) => {
      try {
        const response = await putResourceAction('sinks', sinkName, 'revoke')
        setRevoke({ sink: sinkName, status: 'success', message: response.message })
      } catch (error) {
        setRevoke({ sink: sinkName, status: 'error', message: error.message })
      }
      getSinks(source, stream.name, setSinks)
    },
    [setRevoke, setSinks, source, stream.name]
  )

  if (!sinks) return null
  return sinks.map((sink, i) => (
    <div key={`Sink-${i}`} className={clsx('eds-grid eds-grid--m-cols-12', styles.sink)}>
      <div className='eds-grid--m-col-4'>
        <Link to={`/sinks/details?resource=${sink.name}`}>{sink.name}</Link>
      </div>
      <div className='eds-grid--m-col-3'>
        Status: <SinkStatus state={sink.status.state} />
      </div>
      <div className='eds-grid--m-col-3'>
        <strong>Type: </strong>
        {kafkaTypes[sink.type]}
      </div>
      <div className='action eds-grid--m-col-2'>
        {canRevoke && sink.status.state !== 'revoked' && (
          <Button
            variant='secondary'
            size='small'
            onClick={() =>
              setRevoke(revoke.sink === sink.name ? null : { sink: sink.name, status: 'info' })
            }
          >
            <Null className={styles.revoke} /> Revoke
          </Button>
        )}
      </div>

      {revoke.sink === sink.name && (
        <Card className='eds-grid--m-col-12 no-padding'>
          <Snack status={revoke.status} onDismiss={() => setRevoke({})}>
            {revoke.status === 'success' ? (
              revoke.message
            ) : (
              <div>
                Revoking access from <strong>{sink.name}</strong> will stop all data flow. Would you
                like to continue?
                <Button
                  size='small'
                  variant='secondary'
                  className='eds-spacing--ml-24'
                  onClick={() => revokeSink(sink.name)}
                >
                  Revoke
                </Button>
              </div>
            )}
            {revoke.errors &&
              revoke.errors.map((error, i) => (
                <StatusIndicator key={i} status='inactive' label={error.description} />
              ))}
          </Snack>
        </Card>
      )}
    </div>
  ))
}

export default Sinks
