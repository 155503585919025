import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Card, TextField, Checkbox } from '@nike/eds'

import { Label } from '../../../components/FormComponents'

function FusionSettings() {
  const { register, setValue, watch } = useFormContext()
  const name = 'settings.fusion'
  const isTransacted = watch(`${name}.transacted`, false)
  return (
    <Card padding={24} className='eds-spacing--mb-24'>
      <TextField
        {...register(`${name}.jmsModule`, { required: true })}
        className='eds-spacing--mb-16'
        placeholder=''
        required
        label={<Label label='JMS Module' required tooltip='The name of the JMS Fusion Module.' />}
      />
      <TextField
        {...register(`${name}.messageSelector`)}
        className='eds-spacing--mb-16'
        placeholder=''
        label={
          <Label
            label='Message Selector'
            tooltip='The message selector that should be applied to messages in the destination. Only messages with properties matching the message selector expression are delivered.'
          />
        }
      />
      <TextField
        {...register(`${name}.url`, { required: true })}
        className='eds-spacing--mb-16'
        required
        placeholder=''
        label={
          <Label
            label='Url'
            required
            tooltip='The URL to send data to. Note JMS Fusion connectors only support the t3 protocol. TODO: Add validation or auto-prepend t3 to JMS Fusion URLs'
          />
        }
      />
      <Label label='Transacted' />
      <Checkbox
        name={`${name}.transacted`}
        className='eds-spacing--mb-24'
        value={'fips'}
        checked={!!isTransacted}
        onChange={(e) => setValue(`${name}.transacted`, e.target.checked)}
        label={
          <span>
            Whether to confirm transactions between a JMS queue or topic and the NSP source. <br />
            If set to true, messages will be acknowledged and delivery will be guaranteed as <br />
            messages will be rolled back in the event that a transaction fails. If set to false,
            <br />
            performance may be improved but messages will not be confirmed and delivery may not be
            <br />
            guaranteed.
          </span>
        }
      />
    </Card>
  )
}

export default FusionSettings
