import clsx from 'clsx'
import qs from 'qs'
import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { Button, Icon, Skeleton, Text, TextField, Table, TableHeading, TableCell } from '@nike/eds'

import { postFilterAbort } from '../api'
import { SelectType, SelectAccess } from '../components/Filters'
import StatusIcon from '../components/StatusIcon'
import { Pagination } from '../components/TableComponents'
import useResizableTable from '../hooks/ResizeColumn'
import { useNarkOnPageLoad } from '../util/nark'
import { getFullStatusString } from '../util/utils'
import styles from './Page.module.styl'

export const TYPES = [
  { value: 'kafka.http.push', label: 'HTTP' },
  { value: 'kafka.connect.ibmmq', label: 'IBM MQ' },
  { value: 'kafka.connect.jdbc.mysql', label: 'MySQL' },
  { value: 'kafka.connect.jdbc.oracledb', label: 'Oracle' },
  { value: 'kafka.connect.jdbc.postgres', label: 'Postgres' },
  { value: 'kafka.producer', label: 'Producer' },
  { value: 'kafka.connect.jdbc.sqlserver', label: 'SQL Server' },
  { value: 'kafka.connect.jms.fusion', label: 'Fusion (Beta)' },
]
const LABELS = TYPES.reduce((labels, type) => ({ ...labels, [type.value]: type.label }), {})

const ACCESS = {
  All: 'All',
  DescribeSettings: 'Read',
  UpdateSettings: 'Admin',
}

export function paramTypes(types) {
  if (types === undefined) return []
  if (Array.isArray(types)) return types.map((type) => TYPES[type].value)
  if (TYPES[types]) return [TYPES[types].value]
  return []
}

function Sources(props) {
  useNarkOnPageLoad('sources-page')
  const params = qs.parse(props.location.search?.substring(1))
  const history = useHistory()
  const [table, setTable] = useState({ page: 1, rows: 100 })
  const controllerRef = useRef()
  const [filters, setFilters] = useState({
    search: params?.search || '',
    types: paramTypes(params.type),
    access:
      params.access === '1' ? 'DescribeSettings' : params.access === '2' ? 'UpdateSettings' : 'All',
  })
  const [sources, setSources] = useState()
  const headerColums = [
    { name: 'Name', isResizable: true },
    { name: 'Description', width: '300px' },
    { name: 'Status', width: '200px' },
    { name: 'Type', width: '200px' },
    { name: 'Created', width: '120px' },
  ]
  const { mouseDown } = useResizableTable()

  useEffect(() => {
    const filter = { from: (table.page - 1) * table.rows, size: table.rows }
    const typeFilters = filters.types.map((type) => ({
      string: { field: 'type', logic: { equal: type } },
    }))
    if (filters.search) {
      const searchFilters = ['name', 'id', 'description', 'state'].map((field) => ({
        string: { field, logic: { contains: filters.search.trim() } },
      }))
      searchFilters.push({ tag: { value: { contains: filters.search.trim() } } })
      if (typeFilters.length)
        filter.source = {
          and: { filters: [{ or: { filters: searchFilters } }, { or: { filters: typeFilters } }] },
        }
      else filter.source = { or: { filters: searchFilters } }
    } else if (typeFilters.length) filter.source = { or: { filters: typeFilters } }
    if (filters.access !== 'All')
      filter.sourceAccess = { access: { equal: 'source:' + filters.access } }
    postFilterAbort('source', filter, controllerRef).then(setSources)

    // update the URL query string
    const new_params = []
    for (const value of filters.types)
      new_params.push('type=' + TYPES.findIndex((type) => type.value === value))
    if (filters.access === 'DescribeSettings') new_params.push('access=1')
    if (filters.access === 'UpdateSettings') new_params.push('access=2')
    if (filters.search) new_params.push(`search=${filters.search}`)
    const search = new_params ? '?' + new_params.join('&') : ''
    if (props.location.search !== search)
      history.replace({ pathname: props.location.pathname, search })

    return () => setSources(null)
  }, [table, filters, history, props.location])

  return (
    <div className={styles.TablePage}>
      <div className={styles.TablePageGrid}>
        <div>
          <TextField
            className={styles.tableSearchFilter}
            data-test-id='search-input'
            value={filters.search}
            beforeSlot={<Icon name='Search' />}
            placeholder='Search sources by id, name, description'
            onChange={(e) =>
              setFilters({ search: e.target.value, types: filters.types, access: filters.access })
            }
          />
        </div>
        <div>
          <SelectType
            types={TYPES}
            values={filters.types}
            setTypes={(types) =>
              setFilters({ search: filters.search, types, access: filters.access })
            }
          />
          <SelectAccess
            access={filters.access}
            setAccess={(access) =>
              setFilters({ search: filters.search, types: filters.types, access })
            }
          />
        </div>
        <div>
          <Link to={`/sources/register`}>
            <Button
              data-test-id='register-button'
              size='small'
              style={{ float: 'right', WebkitAppearance: 'none' }}
            >
              Register Source
            </Button>
          </Link>
        </div>
      </div>

      {!sources && <Skeleton height={24} />}

      {sources && !sources.sources?.length && (
        <div className={styles.tableNoResultMessage}>
          <Text font='title-5' as='div'>
            Oops, no results found for
            {filters.search && <> “{filters.search}”</>}
            {filters.types && <p>{filters.types.map((type) => LABELS[type]).join(', ')}</p>}
            Access: {ACCESS[filters.access]}
          </Text>
          <Text font='body-2' color='#757575'>
            Try to adjust your search
          </Text>
        </div>
      )}

      {sources?.sources?.length > 0 && (
        <div>
          <Table
            data-testid='sources-table'
            className={clsx('eds-type--body-3', styles.tableLayout)}
          >
            <thead>
              <tr>
                {headerColums.map((column, i) => (
                  <TableHeading
                    key={column.name}
                    className={column.isResizable ? `resizable-table-${column.name}` : ''}
                    style={{ width: column.width }}
                  >
                    <span>{column.name}</span>
                    {column.isResizable ? (
                      <span
                        onMouseDown={() => mouseDown(i, `resizable-table-${column.name}`)}
                        className={styles.resizeHandle}
                      />
                    ) : null}
                  </TableHeading>
                ))}
              </tr>
            </thead>
            <tbody>
              {sources &&
                sources.sources.map((source, index) => (
                  <tr
                    key={source.id}
                    onClick={() => history.push(`/sources/details?resource=${source.name}`)}
                  >
                    <TableCell
                      className={
                        headerColums[0].isResizable ? `resizable-table-${headerColums.name}` : ''
                      }
                    >
                      <div title={source.name} className={styles.ellipsisStyle}>
                        {source.name}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div title={source.description} className={styles.ellipsisStyle}>
                        {source.description}
                      </div>
                    </TableCell>
                    <TableCell beforeSlot={<StatusIcon state={source.status.state} size='s' />}>
                      {getFullStatusString(source.status.state)}
                    </TableCell>
                    <TableCell>{LABELS[source.type] || source.type}</TableCell>
                    <TableCell>{new Date(source.createdAt).toLocaleDateString()}</TableCell>
                  </tr>
                ))}
            </tbody>
          </Table>

          <Pagination totalCount={sources.totalCount} table={table} setTable={setTable} />
        </div>
      )}
    </div>
  )
}

export default Sources
