import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Button, Icon, Skeleton, Text, Tooltip, Table, TableHeading, TableCell } from '@nike/eds'

import { postFilter } from '../api'
import { Pagination } from '../components/TableComponents'
import { useNarkOnPageLoad } from '../util/nark'
import styles from './Page.module.styl'

function KStream() {
  useNarkOnPageLoad('kstreams-page')
  const [table, setTable] = useState({ page: 1, rows: 10 })
  const [kstreams, setKstreams] = useState()

  async function fetchKstreams() {
    let from = 0
    const size = 100
    const filter = {
      from,
      size,
      source: { or: { filters: [{ tag: { value: { equal: 'kstreams' } } }] } },
    }
    const data = await postFilter('source', filter)
    if (!data) return null

    const promises = []
    for (from += size; from < data.totalCount; from += size) {
      filter.from = from
      promises.push(postFilter('source', filter))
    }
    const results = await Promise.all(promises)

    const sources = results.reduce((sources, result) => {
      if (result) sources.push(...result.sources)
      return sources
    }, data.sources)
    setKstreams(sources)
  }
  useEffect(() => {
    fetchKstreams()
    return () => setKstreams(null)
  }, [])

  const start = (table.page - 1) * table.rows

  return (
    <div style={{ padding: '0px 48px 48px 48px' }}>
      {kstreams ? <div style={{ height: 48 }} /> : <Skeleton height={48} width={'100%'} />}

      <Text font='title-4' as='span'>
        KStreams
      </Text>
      <Tooltip
        bodySlot={
          <Text font='subtitle-2'>
            Kstreams transform data from input streams into output streams.
          </Text>
        }
        placement='right'
        isDark={true}
      >
        <Icon name='InfoCircle' className='infoCircle' />
      </Tooltip>
      <Link to='/kstreams/register'>
        <Button size='small' style={{ float: 'right' }}>
          Register KStream
        </Button>
      </Link>
      <div style={{ clear: 'right' }} />

      <Table className={clsx('eds-spacing--mt-36', 'eds-type--body-3', styles.tableLayout)}>
        <thead>
          <tr>
            <TableHeading>Name</TableHeading>
            <TableHeading>Input Stream</TableHeading>
            <TableHeading>Created</TableHeading>
          </tr>
        </thead>
        <tbody>
          {kstreams &&
            kstreams.slice(start, start + table.rows).map((kstream) => (
              <tr key={kstream.id}>
                <TableCell>
                  <Link to={`/sources/details?resource=${kstream.name}`}>{kstream.name}</Link>
                </TableCell>
                <TableCell>
                  <Link to={`/sources/details?resource=${kstream.streams[0].name}`}>
                    {kstream.streams[0].name}
                  </Link>
                </TableCell>
                <TableCell>{new Date(kstream.createdAt).toLocaleDateString()}</TableCell>
              </tr>
            ))}
        </tbody>
      </Table>

      <Pagination totalCount={kstreams?.length} table={table} setTable={setTable} />
    </div>
  )
}

export default KStream
