import FormControl from '@mui/material/FormControl'
import InputBase from '@mui/material/InputBase'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import React, { useState } from 'react'

import { Checkbox, Icon, Popover, Text } from '@nike/eds'

import styles from './Filters.module.styl'

const SelectInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    position: 'relative',
    padding: '12px 0px 11px 12px',
  },
}))

function SelectType({ types, values, setTypes }) {
  const [show, setShow] = useState(false)
  const [checked, setChecked] = useState(new Set(values))
  const numValues = checked.size

  return (
    <Popover
      placement='bottom-start'
      onClickOutside={() => setShow(false)}
      isOpen={show}
      bodySlot={
        <div>
          {types.map((type, i) => (
            <div key={i} style={i ? { marginTop: 16 } : {}}>
              <Checkbox
                id={i}
                label={type.label}
                value={type.value}
                checked={checked.has(type.value)}
                onChange={() => {
                  if (checked.has(type.value)) checked.delete(type.value)
                  else checked.add(type.value)
                  setChecked(new Set([...checked]))
                  setTypes([...checked])
                }}
              />
            </div>
          ))}
        </div>
      }
    >
      <Text className={styles.dropdownStyle} font='body-2' onClick={() => setShow(true)}>
        Type ({numValues ? numValues : 'All'})
        <Icon name='CaretDown' className='eds-spacing--ml-24' />
      </Text>
    </Popover>
  )
}

function SelectAccess({ access, setAccess }) {
  return (
    <FormControl>
      <Select
        data-testid='select-resource-access'
        className={clsx('eds-spacing--ml-24', styles.selectAccess)}
        input={<SelectInput />}
        value={access}
        onChange={(e) => setAccess(e.target.value)}
        IconComponent={() => <Icon name='CaretDown' className={styles.caretWidth} />}
      >
        <MenuItem value={'All'}>Access: All</MenuItem>
        <MenuItem value={'DescribeSettings'}>Access: Read</MenuItem>
        <MenuItem value={'UpdateSettings'}>Access: Admin</MenuItem>
      </Select>
    </FormControl>
  )
}

export { SelectType, SelectAccess }
