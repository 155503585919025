const kafkaTypes = {
  'kafka.http.push': 'HTTP',
  'kafka.connect.azure.blob': 'Azure Blob Storage',
  'kafka.connect.datafabric.s3': 'Data Fabric',
  'kafka.connect.elasticsearch': 'Elasticsearch',
  'kafka.connect.http.push': 'HTTP',
  'kafka.connect.ibmmq': 'IBM MQ',
  'kafka.connect.jdbc.mysql': 'MySQL',
  'kafka.connect.jdbc.oracledb': 'Oracle',
  'kafka.connect.jdbc.postgres': 'Postgres',
  'kafka.connect.jdbc.sqlserver': 'SQL Server',
  'kafka.connect.jdbc.snowflake': 'Snowflake',
  'kafka.connect.jms.fusion': 'Fusion (Beta)',
  'kafka.connect.s3': 'S3',
  'kafka.connect.splunk': 'Splunk',

  'kafka.consumer': 'Consumer',
  'kafka.producer': 'Producer',
}

export { kafkaTypes }
