import React from 'react'
import { useFormContext } from 'react-hook-form'

import { TextField } from '@nike/eds'

import { Label } from '../../../components/FormComponents'

function BatchCount({ batchCount }) {
  const { register } = useFormContext()

  return (
    <div className='eds-spacing--mb-24 eds-grid eds-grid--m-cols-4'>
      <div>
        <Label label='Batch Count' />
      </div>
      <div>
        <TextField {...register(batchCount)} className='no-margin' />
      </div>
    </div>
  )
}

export default BatchCount
