import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { Card, TextField, Select } from '@nike/eds'

import { CardTitle, Label } from '../../../components/FormComponents'

function StreamSettings() {
  const { register, setValue, control } = useFormContext()
  const name = 'settings.postgres.defaultStreamSettings'
  const selectValues = [
    { label: 'intOrFloat (Default)', value: 'intOrFloat' },
    { label: 'intOrBytes', value: 'intOrBytes' },
    { label: 'bytes', value: 'bytes' },
  ]

  return (
    <Card padding={24}>
      <CardTitle title='Default Stream Settings' />
      <input
        type='hidden'
        value={1}
        {...register(`${name}.maxPayloadSize`, { required: true, valueAsNumber: true })}
      />
      <TextField
        {...register(`${name}.partitionCount`, { valueAsNumber: true })}
        className='eds-spacing--mb-16'
        defaultValue={1}
        placeholder='1'
        label={<Label label='Partition Count' />}
      />

      <CardTitle title='Retention' />
      <TextField
        {...register(`${name}.retention.size`, { valueAsNumber: true })}
        className='eds-spacing--mb-16'
        defaultValue={20000}
        placeholder='20000'
        label={<Label label='Size' />}
      />
      <TextField
        {...register(`${name}.retention.time`, { valueAsNumber: true })}
        className='eds-spacing--mb-16'
        placeholder='345600000'
        defaultValue={345600000}
        label={<Label label='Time' />}
      />
      <Label
        label='Numeric Mapping'
        required
        tooltip='The method used to convert from SQL NUMERIC and DECIMAL types with explicit precision and scale into Avro data types. Caution should be taken when setting this value as it may result in serialization issues or loss in decimal precision.'
      />
      <Controller
        control={control}
        // Defaults to first value in options array
        defaultValue={selectValues.find((option) => option.value === 'intOrFloat')?.value}
        name={`settings.postgres.numericMapping.type`}
        render={({ field }) => (
          <Select
            value={selectValues.filter((option) => field.value === option.value)}
            onChange={(e) => setValue(`settings.postgres.numericMapping.type`, e.value)}
            options={selectValues}
            className={'eds-spacing--mb-16'}
          />
        )}
      />
      <TextField
        {...register(`settings.postgres.timestampDelay`, { required: true, valueAsNumber: true })}
        className='eds-spacing--mb-16'
        defaultValue={0}
        placeholder='0'
        label={
          <Label
            label='Timestamp Delay'
            tooltip="The timestamp delay (in milliseconds) to use when checking for timestamps in query. When 'updateColumn' is configured a query will be run for rows with 'updateColumn' > 'greatest timestamp from last batch' and 'updateColumn' < 'now - timestampDelay'. For databases in which timestamps are generated inside of transactions the rows might be committed out of order resulting in rows with 'newer' timestamps being visible before 'older' ones. If the 'newer' row is visible first then the next query for 'updateColumn' > 'greatest timestamp from last batch' will miss the 'older' row. By setting 'timestampDelay' to a value greater than 0 any timestamps within the last 'timestampDelay' milliseconds will be ignored giving time for all in-flight transactions to complete."
          />
        }
      />
      <TextField
        {...register(`settings.postgres.timezone`, { required: true })}
        className='eds-spacing--mb-16'
        defaultValue='UTC'
        placeholder='UTC'
        label={
          <Label
            label='Timezone'
            tooltip='The timezone of the database. This setting should be configured when sequenceColumn or updateColumn points to a column with time-based values. Must be equal to any valid JDK timezone.'
          />
        }
      />
    </Card>
  )
}

export default StreamSettings
