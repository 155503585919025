import { createTheme, ThemeProvider } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import MuiSelect from '@mui/material/Select'
import React from 'react'

import { Icon, Text, Tooltip } from '@nike/eds'

function Title({ title }) {
  return (
    <Text font='title-4' className='eds-spacing--mb-24 eds-spacing--ml-24'>
      {title}
    </Text>
  )
}

function CardTitle({ title, tooltip }) {
  return (
    <Text font='title-4' className='eds-spacing--mb-24'>
      {title}
      {tooltip && (
        <Tooltip
          bodySlot={<Text font='subtitle-2'>{tooltip}</Text>}
          placement='right'
          isDark={true}
        >
          <Icon name='InfoCircle' className='eds-spacing--ml-12' />
        </Tooltip>
      )}
    </Text>
  )
}

function Label({ label, required, tooltip }) {
  return (
    <Text font='title-6' className='eds-spacing--mb-16'>
      {label} {required && <span className='errorStyle'>*</span>}
      {tooltip && (
        <Tooltip
          bodySlot={<Text font='subtitle-2'>{tooltip}</Text>}
          placement='right'
          isDark={true}
        >
          <Icon name='InfoCircle' size='s' className='eds-spacing--ml-12' />
        </Tooltip>
      )}
    </Text>
  )
}

const selectTheme = createTheme({
  components: { MuiSelect: { styleOverrides: { select: { padding: '12px 0px 11px 12px' } } } },
})

function Select({ value, defaultValue, options, optional, inputProps, className }) {
  if (!Array.isArray(options)) return <div>Select options must be an array</div>
  const type = options.length > 0 ? typeof options[0] : null
  if (!value) value = defaultValue || ''

  return (
    <ThemeProvider theme={selectTheme}>
      <MuiSelect
        value={value}
        displayEmpty
        inputProps={{ ...inputProps }}
        IconComponent={() => <Icon name='CaretDown' style={{ width: 42 }} />}
        className={className}
      >
        {optional && <MenuItem value=''>(Optional)</MenuItem>}
        {!optional && !value && <MenuItem value=''>(Select an option)</MenuItem>}
        {type === 'string' &&
          options.map((option) => (
            <MenuItem key={option} value={option}>
              {option} {option === defaultValue ? ' (default)' : ''}
            </MenuItem>
          ))}
        {type === 'object' &&
          options[0].label &&
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label} {option.value === defaultValue ? ' (default)' : ''}
            </MenuItem>
          ))}
      </MuiSelect>
    </ThemeProvider>
  )
}

export { Title, CardTitle, Label, Select }
