import qs from 'qs'
import React from 'react'
import { Link } from 'react-router-dom'

import { Card, Text, Skeleton, Chip } from '@nike/eds'

import { putResourceRoles } from '../api'
import Hr from '../components/Hr'
import { useNarkOnPageLoad } from '../util/nark'
import DangerZone from './Details/DangerZone'
import DeadLetterQueue from './Details/DeadLetterQueue'
import ResourceAttributes from './Details/ResourceAttributes'
import ResourceHeader from './Details/ResourceHeader'
import Roles from './Details/Roles'
import Usage from './Details/Usage'
import Streams from './Details/streams/Streams'
import useResource from './Details/useResource'
import useResourceRoles from './Details/useResourceRoles'

function SinkDetails(props) {
  useNarkOnPageLoad('details-page')
  const pathnames = props.location.pathname.split('/')
  const resourceType = pathnames[1] === 'nsp' ? pathnames[2] : pathnames[1]
  const params = qs.parse(props.location.search?.substring(1))
  const name = params['resource']
  const { resource } = useResource(resourceType, name)
  const { getRoles, roles } = useResourceRoles(resourceType, name, resource?.allowedActions)
  const isAdmin = resource?.allowedActions?.length > 5

  async function putGroups(title, groups) {
    const role = title === 'Admin' ? 'admin' : 'readOnly'
    await putResourceRoles(resourceType, name, role, groups)
    getRoles()
  }

  if (!resource) return <Skeleton height={24} />

  if (!resource.id) return <div className='eds-spacing--p-48'>Error: source does not exist</div>

  return (
    <Card className='eds-spacing--m-32'>
      <div>
        <ResourceHeader resource={resource} label='Sink' resourceType={resourceType} />
        <ResourceAttributes resource={resource} />

        <div className='eds-spacing--mt-16'>
          <Text font='title-4' className='eds-spacing--mb-16'>
            Usage
          </Text>
          {resource.usage && <Usage usage={resource.usage} />}
          <Hr />
        </div>

        {resource.source && (
          <div className='eds-spacing--mt-16'>
            <Text font='title-4' className='eds-spacing--mb-16'>
              Source
            </Text>
            {resource.source.deleted && 'Source has been deleted'}
            <ul>
              <li>
                Name:{' '}
                {resource.source.deleted ? (
                  resource.source.name
                ) : (
                  <Link
                    to={{
                      pathname: `/sources/details`,
                      search: `?resource=${resource.source.name}`,
                    }}
                  >
                    {resource.source.name}
                  </Link>
                )}
              </li>
              <li>Stream: {resource.source.stream}</li>
            </ul>
            <Hr />
          </div>
        )}

        <DeadLetterQueue queue={resource.deadLetterQueue} />

        {resource?.streams && (
          <div className='eds-spacing--mt-16'>
            <Text font='title-4' className='eds-spacing--mb-24'>
              Streams
            </Text>
            <Streams resource={resource} resourceType={resourceType} isAdmin={isAdmin} />
            <Hr />
          </div>
        )}

        {isAdmin && roles.length && (
          <>
            <div className='eds-spacing--mt-16'>
              <Text font='title-4'>Roles</Text>
            </div>
            <div className='eds-spacing--my-16 eds-grid eds-grid--m-cols-2'>
              <div>
                <Roles
                  title='Admin'
                  role={roles.find((role) => role.name.includes('/admin/managed'))}
                  putGroups={putGroups}
                />
              </div>
              <div>
                <Roles
                  title='Read Only'
                  role={roles.find((role) => role.name.includes('/read-only/managed'))}
                  putGroups={putGroups}
                />
              </div>
            </div>
            <hr className='no-margin' />
          </>
        )}

        <div className='eds-spacing--mt-16'>
          <Text font='title-4'>Tags</Text>
          <div className='eds-spacing--mt-16'>
            {resource.tags.length ? (
              resource.tags.map((item, i) => (
                <Chip key={i} className='eds-spacing--ml-8'>
                  {item}
                </Chip>
              ))
            ) : (
              <p>No Tags</p>
            )}
          </div>
          <Hr />
        </div>

        {isAdmin && <DangerZone resourceType={resourceType} resource={resource} />}
      </div>
    </Card>
  )
}

export default SinkDetails
