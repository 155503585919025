import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Card } from '@nike/eds'

import { Label, Select } from '../../components/FormComponents'

function splunkSettings(data) {
  const settings = {
    type: 'kafka.connect.splunk',
    'kafka.connect.splunk': {
      destination: {
        type: 'nikeSplunk',
        nikeSplunk: { index: data.settings.destination.nikeSplunk.index },
      },
    },
  }
  return settings
}

function Splunk() {
  const { register, watch } = useFormContext()
  const settings = watch('settings') || {}
  if (!settings.destination) settings.destination = {}
  if (!settings.destination.nikeSplunk) settings.destination.nikeSplunk = { index: '' }

  return (
    <>
      <div className='eds-spacing--mt-24 eds-grid eds-grid--m-cols-12'>
        <Card className='eds-grid--m-col-3 no-padding'>
          <Label label='Destination' />
        </Card>
        <Card className='eds-grid--m-col-9 no-padding'>
          <Select
            value={settings.type}
            defaultValue='nikeSplunk'
            inputProps={register('settings.type')}
            options={['nikeSplunk']}
          />
        </Card>
        <Card className='eds-grid--m-col-3 no-padding'>
          <Label label='Index' required />
        </Card>
        <Card className='eds-grid--m-col-9 no-padding'>
          <Select
            value={settings.destination.nikeSplunk.index}
            inputProps={register('settings.destination.nikeSplunk.index', { required: true })}
            options={Index}
          />
        </Card>
      </div>
    </>
  )
}

export { Splunk, splunkSettings }

const Index = [
  'akamai',
  'app',
  'app-bots',
  'app-gold',
  'app-hc',
  'app-platinum',
  'app-ru',
  'app-silver',
  'baat',
  'berm',
  'bigpanda',
  'carrier-silver',
  'cloudtrail',
  'cloudwatch',
  'cmp-prod-hc',
  'cmp-preprod-hc',
  'converse',
  'core',
  'core-hc',
  'core-ru',
  'cmp-hc',
  'dds',
  'dns',
  'dockerlogs',
  'dockerlogs-gold',
  'dockerlogs-platinum',
  'dockerlogs-silver',
  'dw-prod',
  'eda-tdp',
  'eda-tdp-hc',
  'eda-tdp-ru',
  'emm',
  'experience',
  'experience-hc',
  'experience-ru',
  'firedalerts',
  'foundational',
  'foundational-hc',
  'foundational-ru',
  'gcf',
  'gtm_preprod',
  'gtm_prod',
  'innovation-nsrl',
  'jamfpro',
  'jenkins_artifact',
  'jenkins_statistics',
  'l7r',
  'lambda',
  'lambda-gold',
  'lambda-platinum',
  'lambda-silver',
  'lastchanceindex',
  'newrelic',
  'ngap',
  'ngap-tableau',
  'ngap2_databricks',
  'ngpdap',
  'nifi',
  'np-akamai',
  'np-app',
  'np-app-bots',
  'np-app-gold',
  'np-app-hc',
  'np-app-platinum',
  'np-app-ru',
  'np-app-silver',
  'np-berm',
  'np-carrier-silver',
  'np-cloudwatch',
  'np-cmp-hc',
  'np-converse',
  'np-cps-silver',
  'np-datastores',
  'np-dds',
  'np-dockerlogs',
  'np-dockerlogs-gold',
  'np-dockerlogs-platinum',
  'np-dockerlogs-silver',
  'np-eda-tdp',
  'np-eda-tdp-hc',
  'np-eda-tdp-ru',
  'np-emm',
  'np-gcf',
  'np-innovation',
  'np-l7r',
  'np-lambda',
  'np-lambda-gold',
  'np-lambda-platinum',
  'np-lambda-silver',
  'np-ngpdap',
  'np-nifi',
  'np-onplatformtools',
  'np-papi',
  'np-sap-cpi',
  'np-silver',
  'np-tech',
  'np-web',
  'np-web-gold',
  'np-web-platinum',
  'np-web-ru',
  'np-web-silver',
  'oc_itsi',
  'onplatformtools',
  'opsintel',
  'os',
  'product_production',
  'product_test_7d',
  'sap-cpi',
  'silver',
  'snow_incident_state_index',
  'tech',
  'test_7d',
  'trace',
  'web',
  'web-gold',
  'web-hc',
  'web-platinum',
  'web-ru',
  'web-silver',
  'windows_retail',
  'windows_retail_cdt',
  'windows_retail_devqa',
]
