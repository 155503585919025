import React from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'

import { Card, TextField, Button } from '@nike/eds'
import { Delete } from '@nike/nike-design-system-icons'

import { Label } from '../../../../components/FormComponents'

function Oauth() {
  const name = 'settings.http.auth.oauth'
  const { register, control } = useFormContext()
  const {
    fields: subFields,
    append: subAppend,
    remove: subRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${name}.allowedSubs`, // unique name for your Field Array
  })

  const {
    fields: scopeFields,
    append: scopeAppend,
    remove: scopeRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${name}.scopes`, // unique name for your Field Array
  })
  return (
    <>
      <TextField
        {...register(`${name}.url`)}
        className='eds-spacing--mb-24'
        placeholder='https://oauth-server.com'
        required
        label={
          <Label
            label='Url'
            required
            tooltip={
              'The base URL for the OAuth server. This should include a discovery route at GET [URL]/.well-known/oauth-authorization-server.'
            }
          />
        }
      />
      <TextField
        {...register(`${name}.clientID`)}
        className='eds-spacing--mb-24'
        placeholder='my_client_id'
        label={
          <Label
            label='Client Id'
            tooltip={
              'If no specific client ID is set, then all client IDs will be allowed through as long as all other claims are valid.'
            }
          />
        }
      />
      <TextField
        {...register(`${name}.discoveryRoute`)}
        className='eds-spacing--mb-24'
        placeholder='/.well-known/openid-configuration'
        label={
          <Label
            label='Discovery Route'
            tooltip={
              'The route for OAuth server discovery. This value will be concatenated with the Issuer URL and must begin with a slash.'
            }
          />
        }
      />
      <Label
        label='Allowed Subs'
        tooltip={
          'If no sub is set, all subs will be allowed through as long as all other claims are valid'
        }
      />
      {subFields.map((field, index) => (
        <div key={field.id} className={'eds-grid eds-grid--m-cols-12'}>
          <Card className='eds-grid--m-col-9 no-padding'>
            <TextField
              {...register(`${name}.allowedSubs[${index}]`)}
              className='eds-spacing--mb-16'
              defaultValue={''}
              key={field.id}
              required
            />
          </Card>
          <Card className='eds-spacing--mt-24 eds-grid--m-col-3 no-padding'>
            <Delete onClick={() => subRemove(index)} />
          </Card>
        </div>
      ))}
      <Button variant='secondary' className='eds-spacing--mb-24' onClick={() => subAppend('')}>
        Add Sub
      </Button>
      <Label
        label='Scopes'
        tooltip={
          'The list of scopes that are required on each request. If empty then scopes will not be checked. Otherwise every scope in this list must be present in order for the request to be accepted. The scopes will be taken from the "scp" claim on the input JWT.'
        }
      />
      {scopeFields.map((field, index) => (
        <div key={field.id} className={'eds-grid eds-grid--m-cols-12'}>
          <Card className='eds-grid--m-col-9 no-padding'>
            <TextField
              {...register(`${name}.scopes[${index}]`)}
              className='eds-spacing--mb-16'
              defaultValue={''}
              key={field.id}
              required
            />
          </Card>
          <Card className='eds-spacing--mt-24 eds-grid--m-col-3 no-padding'>
            <Delete onClick={() => scopeRemove(index)} />
          </Card>
        </div>
      ))}
      <Button variant='secondary' className='eds-spacing--mb-16' onClick={() => scopeAppend('')}>
        Add Scope
      </Button>
    </>
  )
}
export default Oauth
