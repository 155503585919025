import config from './config'

function authorization() {
  const okta = JSON.parse(window.localStorage.getItem('okta-token-storage'))
  if (!okta) throw new Error('Missing Okta token')
  return {
    Authorization: 'Bearer ' + okta.accessToken.accessToken,
  }
}
async function postFilterAbort(resource, filter, controllerRef) {
  if (controllerRef?.current) controllerRef.current.abort()
  const headers = authorization()
  Object.assign(headers, {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  })

  const controller = new AbortController()
  controllerRef.current = controller

  const requestBody = {
    headers,
    method: 'POST',
    body: JSON.stringify(filter),
    signal: controllerRef?.current?.signal,
  }

  try {
    return await fetch(config.apiUrl + resource + 's/filters', requestBody).then((response) =>
      response.json()
    )
  } catch (err) {
    if (err && err.name === 'AbortError') {
      console.log(err.message)
    } else {
      console.error(err)
    }
  }
}
// https://console.platforms.nike.com/developer/docs/projects/GEM?tab=api#operation/postSourcesFilters
async function postFilter(resource, filter) {
  const headers = authorization()
  Object.assign(headers, {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  })

  try {
    return await fetch(config.apiUrl + resource + 's/filters', {
      headers,
      method: 'POST',
      body: JSON.stringify(filter),
    }).then((response) => response.json())
  } catch (err) {
    console.error(err)
  }
}

async function getResource(resourceType, name) {
  const headers = authorization()
  try {
    return await fetch(config.apiUrl + resourceType + '/' + name, { headers }).then((response) =>
      response.json()
    )
  } catch (err) {
    // console.error(err)   // this should return the status code
  }
}

async function getResourceMetrics(resourceType, name) {
  const headers = authorization()
  return fetch(`${config.apiUrl}${resourceType}/${name}/metrics?dashboard=true`, { headers }).then(
    (response) => response.json()
  )
}

async function getResourceRoles(resourceType, name) {
  const headers = authorization()
  return fetch(`${config.apiUrl}${resourceType}/${name}/roles`, { headers }).then((response) =>
    response.json()
  )
}

async function putResourceRoles(resourceType, name, role, groups) {
  const headers = authorization()
  Object.assign(headers, {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  })
  return fetch(`${config.apiUrl}${resourceType}/${name}/roles/${role}/groups`, {
    headers,
    method: 'PUT',
    body: JSON.stringify({ groups }),
  }).then((response) => response.json())
}

async function getCloudRed(guid) {
  const headers = authorization()
  Object.assign(headers, {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  })
  try {
    return await fetch(`${config.cloudRedUrl}/api/ssot/systems/${guid}`, { headers }).then(
      (response) => response.json()
    )
  } catch (err) {
    console.error(err)
    return null
  }
}

async function postResource(resourceType, body) {
  const headers = authorization()
  Object.assign(headers, {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  })
  return fetch(`${config.apiUrl}${resourceType}`, {
    headers,
    method: 'POST',
    body: JSON.stringify(body),
  }).then((response) => response.json().then((data) => ({ status: response.status, body: data })))
}

async function putResource(resourceType, name, body) {
  const headers = authorization()
  Object.assign(headers, {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  })
  return fetch(`${config.apiUrl}${resourceType}/${name}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(body),
  }).then((response) => response.json().then((data) => ({ status: response.status, body: data })))
}

async function putResourceAction(resourceType, name, action) {
  const headers = authorization()
  Object.assign(headers, {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  })
  return fetch(`${config.apiUrl}${resourceType}/${name}/${action}`, {
    headers,
    method: 'PUT',
  }).then((response) => response.json())
}

async function deleteResource(resourceType, name) {
  const headers = authorization()
  return fetch(`${config.apiUrl}${resourceType}/${name}`, { headers, method: 'DELETE' }).then(
    (response) => response.json()
  )
}

async function getClientIds() {
  const okta = JSON.parse(window.localStorage.getItem('okta-token-storage'))
  if (!okta) throw new Error('Missing Okta token')
  const headers = { Authorization: 'Bearer ' + okta.accessToken.accessToken }
  const email = okta.idToken.claims.email.toLowerCase()
  return fetch(config.developerapiUrl + '?username=' + email, { headers })
    .then((response) => response.json())
    .then((response) => response.items)
    .catch((e) => console.error(`Error requesting user info from ${config.developerapiUrl}: `, e))
}

export {
  postFilter,
  getResource,
  getResourceMetrics,
  getResourceRoles,
  putResourceRoles,
  getCloudRed,
  postResource,
  putResource,
  putResourceAction,
  deleteResource,
  getClientIds,
  postFilterAbort,
}
