import { useCallback, useState, useEffect } from 'react'

import { getResource, getResourceMetrics } from '../../api'

/**
 * Fetch resource and add metrics dashboard URL if available. Returns object
 * from API response, or an error string.
 *
 * @param {string} resourceType: one of 'sources', 'sinks', 'kstreams'
 * @param {string} name
 * @returns
 */
function useResource(resourceType, name) {
  const [resource, setResource] = useState({})
  const [errorMessage, setError] = useState('')

  const fetchResource = useCallback(async () => {
    const type = resourceType === 'kstreams' ? 'sources' : resourceType
    let metricsResponse
    try {
      const response = await getResource(type, name)
      if (response.source) {
        metricsResponse = await getResourceMetrics(
          type,
          name + '/streams/' + response.source.streams[0].name
        )
        response.source.metricsDashboardUrl = metricsResponse.metrics?.dashboard.url
        setResource(response.source)
      } else if (response.sink) {
        metricsResponse = await getResourceMetrics(resourceType, name)
        response.sink.metricsDashboardUrl = metricsResponse.metrics.dashboard.url
        setResource(response.sink)
      } else {
        setResource({})
      }
    } catch (err) {
      console.error(err)
      setError(err)
    }
  }, [resourceType, name])

  useEffect(() => {
    fetchResource()

    return () => {
      setError('')
      setResource({})
    }
  }, [fetchResource])

  return { errorMessage, resource }
}

export default useResource
