import React from 'react'

import { Card, Text, Chip } from '@nike/eds'

import Hr from '../../components/Hr'
import StatusIcon from '../../components/StatusIcon.jsx'
import { kafkaTypes } from '../../util/kafka'
import AdminActions from './AdminActions'
import CloudRedMessage from './CloudRedMessage'

function ResourceHeader({ label, resource, resourceType }) {
  const { cloudRedGUID, name, type } = resource
  const status = resource.status.state
  const errors = resource.status.errors
  return (
    <>
      <div className='eds-grid eds-grid--m-cols-12'>
        <Card className='eds-grid--m-col-8 no-padding'>
          <Text as='div' font='title-4' data-testid='details-title' className='word-break'>
            <Chip className='eds-spacing--mr-16'>{label}</Chip>
            {name}
            <StatusIcon state={status} className='eds-spacing--ml-16' />
            <Chip className='eds-spacing--ml-16'>{kafkaTypes[type]}</Chip>
          </Text>
        </Card>
      </div>
      {errors.length > 0 && (
        <div className='eds-spacing--mt-16'>
          <Text font='title-4' className='eds-spacing--mb-16'>
            <span style={{ color: '#D43F21' }}>Errors</span>
          </Text>
          {errors.map((error, i) => (
            <div key={i} style={{ color: '#D43F21' }}>
              {error}
            </div>
          ))}
          <Hr />
        </div>
      )}
      <CloudRedMessage guid={cloudRedGUID} />
      <Hr />
      <AdminActions resource={resource} resourceType={resourceType} />
    </>
  )
}

export default ResourceHeader
