import React from 'react'

import { Card, Text } from '@nike/eds'

import CopyableText from '../../components/CopyableText'
import Hr from '../../components/Hr'

function ResourceAttributes({ resource }) {
  const { id, createdAt, description, updatedAt } = resource
  return (
    <>
      <div className='eds-grid eds-grid--m-cols-12 eds-spacing--mt-24'>
        <Card className='eds-grid--m-col-8 no-padding'>
          <Text font='title-4' className='eds-spacing--mb-16'>
            ID
          </Text>
          <CopyableText text={id} showOnHover />
          <Hr />
        </Card>
        <Card className='eds-grid--m-col-4 no-padding'>
          <Text font='title-4' className='eds-spacing--mb-16'>
            Created
          </Text>
          <Text font='body-2'>{new Date(createdAt).toLocaleString()}</Text>
          <Hr />
        </Card>
      </div>
      <div className='eds-grid eds-grid--m-cols-12 eds-spacing--mt-16'>
        <Card className='eds-grid--m-col-8 no-padding'>
          <Text font='title-4' className='eds-spacing--mb-16'>
            Description
          </Text>
          <Text font='body-2' id='description'>
            {description || 'No Data'}
          </Text>
          <Hr />
        </Card>
        <Card className='eds-grid--m-col-4 no-padding'>
          <Text font='title-4' className='eds-spacing--mb-16'>
            Last Updated
          </Text>
          <Text font='body-2'>{new Date(updatedAt).toLocaleString()}</Text>
          <Hr />
        </Card>
      </div>
    </>
  )
}

export default ResourceAttributes
