import React from 'react'
import { useFieldArray, useFormContext, Controller } from 'react-hook-form'

import { TextField, Checkbox, Button, Card, Select } from '@nike/eds'
import { Delete } from '@nike/nike-design-system-icons'

import { Label } from '../../../components/FormComponents'

function Stream({ index }) {
  const name = 'settings.producer'
  const { register, control, setValue, watch } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${name}.streams[${index}].users`, // unique name for your Field Array
  })
  const hasSchema = watch(`${name}.streams[${index}].hasSchema`, false)
  const selectValues = [
    { label: 'avro', value: 'avro' },
    { label: 'json', value: 'json' },
    { label: 'string', value: 'string' },
    { label: 'string/xml', value: 'string/xml' },
  ]

  return (
    <>
      <Label
        label='Message Format'
        required
        tooltip="The format of the messages. Note that 'string' message format expects any valid string while the 'string/xml' message format expects well formed XML strings."
      />
      <Controller
        control={control}
        // Defaults to first value in options array
        defaultValue={selectValues.find((option) => option.value === 'avro')?.value}
        name={`${name}.streams[${index}].messageFormat`}
        render={({ field }) => (
          <Select
            value={selectValues.filter((option) => option.value === field.value)}
            onChange={(e) => setValue(`${name}.streams[${index}].messageFormat`, e.value)}
            options={selectValues}
            className={'eds-spacing--mb-16'}
          />
        )}
      />

      <Label label='Schema' tooltip='Whether a schema is required' />
      <Checkbox
        name={`${name}.streams[${index}].hasSchema`}
        className='eds-spacing--mb-24'
        value={'hasSchema'}
        checked={!!hasSchema}
        onChange={(e) => setValue(`${name}.streams[${index}].hasSchema`, e.target.checked)}
        label={
          <span>
            Only message formats AVRO and JSON can support schemas. Schema registry URLs will
            <br />
            be provided. This value cannot be changed after the source has been created.
          </span>
        }
      />
      <Label
        label='Users'
        required
        tooltip={
          'The list of users to allow. Users must be applications registered in developer portal.'
        }
      />
      {fields.map((field, idx) => (
        <div key={field.id} className={'eds-grid eds-grid--m-cols-12'}>
          <Card className='eds-grid--m-col-9 no-padding'>
            <TextField
              {...register(`${name}.streams[${index}].users[${idx}]`)}
              className='eds-spacing--mb-16'
              defaultValue={''}
              key={field.id}
              required
            />
          </Card>
          <Card className='eds-spacing--mt-24 eds-grid--m-col-3 no-padding'>
            <Delete onClick={() => remove(idx)} />
          </Card>
        </div>
      ))}
      <Button variant='secondary' className='eds-spacing--mb-24' onClick={() => append('')}>
        Add User
      </Button>
    </>
  )
}

export default Stream
