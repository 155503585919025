import React from 'react'

import { HomePage as IntegratedHomePage } from '@nike/console-integrated-platform'

import config from '../config'
import { useNarkOnPageLoad } from '../util/nark'
import { getOktaToken } from '../util/utils'

const homePageEnvs = {
  prod: 'prod',
  staging: 'nonprod',
  nonprod: 'nonprod',
}

const homePageConfig = {
  techSolutionId: config.platformId,
  env: homePageEnvs[config.env] || 'dev',
}

export default function HomePage() {
  useNarkOnPageLoad('home-page')
  homePageConfig.accessToken = getOktaToken()
  return <IntegratedHomePage {...homePageConfig} />
}
