import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Text, TextField } from '@nike/eds'

import { Label, Select } from '../../components/FormComponents'
import styles from './Azure.module.styl'
import Flush from './Bucket/Flush'
import { Naming, getNaming } from './Bucket/Naming'

function azureSettings(data) {
  const settings = {
    type: 'kafka.connect.azure.blob',
    'kafka.connect.azure.blob': {
      accountKey: data.settings.accountKey,
      accountName: data.settings.accountName,
      containerName: data.settings.containerName,
      objectDelimiter: data.settings.objectDelimiter,
      flush: {
        count: data.settings.flush?.count,
        frequency: data.settings.flush?.frequency,
      },
      ...(data.settings.format.type && {
        format: {
          type: data.settings.format.type,
          [data.settings.format.type]: {
            ...(data.settings.format.type === 'json' &&
              data.settings.format.json.compression && {
                compression: data.settings.format.json.compression,
              }),
          },
        },
      }),
      prefix: data.settings.prefix,
      naming: getNaming(data.settings.naming),
    },
  }
  return settings
}

function Azure() {
  const { register, watch, formState } = useFormContext()
  const errors = formState.errors
  const settings = watch('settings') || { format: 'json' }

  return (
    <div>
      <Label label='Account Key' required />
      <TextField
        {...register('settings.accountKey', { required: true })}
        hasErrors={errors['settings.accountKey']}
        placeholder='The account key to connect with'
        label=''
        type='password'
        className='eds-spacing--mb-24'
      />

      <Label label='Account Name' required />
      <TextField
        {...register('settings.accountName', { required: true })}
        hasErrors={errors['settings.accountName']}
        placeholder='The account name to connect with. Must be between 2-23 alphanumeric characters.'
        label=''
        className='eds-spacing--mb-24'
      />

      <Label label='Container Name' required />
      <TextField
        {...register('settings.containerName', { required: true })}
        hasErrors={errors['settings.containerName']}
        placeholder='The Azure Blob Storage container to connect to. Must be between 3 and 63 alphanumeric and -
        characters.'
        label=''
        className='eds-spacing--mb-24'
      />

      <Label label='Object Delimiter' />
      <TextField {...register('settings.objectDelimiter')} className={styles.objectDelimiter} />
      <Text font='subtitle-2' className='eds-spacing--mb-16'>
        All objects will have paths/names of the format
        &quot;[kafka-topic]/[prefix]/[naming]/[kafka-topic]+[id].[format]&quot; where kafka-topic
        and id are automatically generated and the rest can be set by the user. This setting
        controls the object delimiter; by default all objects will be delimited by the + character
        but this setting can be used to change to a different character.
      </Text>

      <Flush name='settings.flush' />

      <div className='eds-spacing--my-16 eds-grid eds-grid--m-cols-8'>
        <div>
          <Label label='Format' />
          <Select
            value={settings.format?.type}
            options={['json', 'parquet', 'avro']}
            optional
            inputProps={register('settings.format.type')}
          />
        </div>
        {settings.format.type === 'json' && (
          <div>
            <Label label='Compression' />
            <Select
              value={settings.format?.json?.compression}
              options={['gzip']}
              inputProps={register('settings.format.json.compression')}
            />
          </div>
        )}
      </div>

      <TextField {...register('settings.prefix')} label={<Label label='Prefix' />} />
      <Text font='subtitle-2' as='div' className='eds-spacing--mb-16'>
        All objects will have paths/names of the format
        &quot;[kafka-topic]/[prefix]/[naming]/[kafka-topic]+[id].[format]&quot; where kafka-topic
        and id are automatically generated and the rest can be set by the user. This setting
        controls [prefix]; it specifies the directory in which to store all objects relative to the
        root of the container with each sub-directory separated by a / character. If the Prefix ends
        in a / character this will create an empty sub-directory in the container (e.g. foo/bar//).
      </Text>

      <Naming name='settings.naming' />
    </div>
  )
}

export { Azure, azureSettings }
