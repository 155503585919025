import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Card, TextField } from '@nike/eds'

import CheckboxForwardToDLQ from '../../components/CheckboxForwardToDLQ'
import { Label, Select } from '../../components/FormComponents'
import Flush from './Bucket/Flush'
import { Naming, getNaming } from './Bucket/Naming'
import styles from './S3.module.styl'

const int = (value) => parseInt(value) || undefined

function s3Settings(data) {
  let encryption, format
  if (data.settings.encryption.type === 'aes256') encryption = { type: 'aes256', aes256: {} }
  if (data.settings.encryption.type === 'kms')
    encryption = {
      type: 'kms',
      kms: { keyID: data.settings.encryption.kms.keyID },
    }
  const format_type = data.settings.format.type
  if (format_type) format = { type: format_type, [format_type]: {} }
  if (format_type === 'bytes') {
    if (data.settings.format.compression)
      format.bytes.compression = data.settings.format.compression
    if (data.settings.format.bytes.extension)
      format.bytes.extension = data.settings.format.bytes.extension
    if (data.settings.format.bytes.separator)
      format.bytes.separator = data.settings.format.bytes.separator
  }
  if (data.settings.format.type === 'json')
    format = {
      type: 'json',
      json: data.settings.format.compression === 'gzip' ? { compression: 'gzip' } : {},
    }

  const settings = {
    type: 'kafka.connect.s3',
    'kafka.connect.s3': {
      bucket: data.settings.bucket,
      region: data.settings.region,
      ...(encryption && { encryption }),
      acl: data.settings.acl,
      flush: {
        count: int(data.settings.flush?.count),
        frequency: int(data.settings.flush?.frequency),
      },
      forwardErrorsToDLQ: data.settings.forwardErrorsToDLQ,
      ...(data.settings.prefix && { prefix: data.settings.prefix }),
      ...(data.settings.filePrefix && { filePrefix: data.settings.filePrefix }),
      ...(data.settings.fileDelimiter && { fileDelimiter: data.settings.fileDelimiter }),
      ...(format && { format }),
      naming: getNaming(data.settings.naming),
    },
  }

  return settings
}

function S3() {
  const { register, setValue, watch } = useFormContext()
  const settings = watch('settings') || { destination: { type: 'queue' } }
  if (!settings.destination) settings.destination = { type: 'queue' }
  if (!settings.format) settings.format = {}

  return (
    <div>
      <Label label='Bucket' required />
      <TextField {...register('settings.bucket', { required: true })} />

      <div className='eds-spacing--my-24 eds-grid eds-grid--m-cols-12'>
        <Card className='eds-grid--m-col-2 no-padding'>
          <Label label='Region' required />
        </Card>
        <Card className='eds-grid--m-col-4 no-padding'>
          <Select
            value={settings.region}
            inputProps={register('settings.region', { required: true })}
            options={Regions}
          />
        </Card>
      </div>
      <div className='eds-spacing--my-24 eds-grid eds-grid--m-cols-12'>
        <Card className='eds-grid--m-col-2 no-padding'>
          <Label label='Encryption' />
        </Card>
        <Card className='eds-grid--m-col-4 no-padding'>
          <Select
            value={settings.encryption?.type}
            defaultValue='None'
            inputProps={register('settings.encryption.type')}
            options={['None', 'aes256', 'kms']}
          />
        </Card>
      </div>
      {settings.encryption?.type === 'kms' && (
        <>
          <div className='eds-spacing--my-24 eds-grid eds-grid--m-cols-12'>
            <Card className='eds-grid--m-col-2 no-padding'>
              <Label label='Key ID' required />
            </Card>
            <Card className='eds-grid--m-col-4 no-padding'>
              <TextField {...register('settings.encryption.kms.keyID', { required: true })} />
            </Card>
          </div>
        </>
      )}
      <div className='eds-spacing--my-24 eds-grid eds-grid--m-cols-12'>
        <Card className='eds-grid--m-col-2 no-padding'>
          <Label label='ACL' />
        </Card>
        <Card className='eds-grid--m-col-4 no-padding'>
          <Select
            value={settings.acl}
            defaultValue='bucket-owner-full-control'
            inputProps={register('settings.acl')}
            options={ACL}
          />
        </Card>
      </div>

      <Flush name='settings.flush' />

      <div className='eds-spacing--mt-24 eds-grid eds-grid--m-cols-6'>
        <div>
          <Label label='Format' />
          <Select
            value={settings.format.type}
            inputProps={register('settings.format.type')}
            options={['avro', 'bytes', 'json', 'parquet']}
            optional
          />
        </div>
        <div>
          {['bytes', 'json'].includes(settings.format.type) && (
            <>
              <Label label='Compression' />
              <Select
                value={settings.format.compression}
                inputProps={register('settings.format.compression')}
                options={['gzip']}
                optional
              />
            </>
          )}
        </div>
      </div>
      {settings.format.type === 'bytes' && (
        <div className={styles.formatBytes}>
          <div className='eds-spacing--mb-24 eds-grid eds-grid--m-cols-12'>
            <Card className='eds-grid--m-col-2 no-padding'>
              <Label label='Extension' />
            </Card>
            <Card className='eds-grid--m-col-4 no-padding'>
              <TextField {...register('settings.format.bytes.extension')} />
            </Card>
          </div>
          <div className='eds-spacing--mb-24 eds-grid eds-grid--m-cols-12'>
            <Card className='eds-grid--m-col-2 no-padding'>
              <Label label='Separator' />
            </Card>
            <Card className='eds-grid--m-col-4 no-padding'>
              <TextField {...register('settings.format.bytes.separator')} />
            </Card>
          </div>
        </div>
      )}
      <div className='eds-spacing--my-24 eds-grid eds-grid--m-cols-12'>
        <Card className='eds-grid--m-col-2 no-padding'>
          <Label label='Prefix' />
        </Card>
        <Card className='eds-grid--m-col-4 no-padding'>
          <TextField {...register('settings.prefix')} className='no-margin' />
        </Card>
      </div>
      <div className='eds-spacing--my-24 eds-grid eds-grid--m-cols-12'>
        <Card className='eds-grid--m-col-2 no-padding'>
          <Label label='File Prefix' />
        </Card>
        <Card className='eds-grid--m-col-4 no-padding'>
          <TextField {...register('settings.filePrefix')} className='no-margin' />
        </Card>
      </div>
      <div className='eds-spacing--my-24 eds-grid eds-grid--m-cols-12'>
        <Card className='eds-grid--m-col-2 no-padding'>
          <Label label='File Delim' />
        </Card>
        <Card className='eds-grid--m-col-4 no-padding'>
          <TextField {...register('settings.fileDelimiter')} className='no-margin' />
        </Card>
      </div>
      <div className='eds-spacing--my-24 eds-grid eds-grid--m-cols-12'>
        <Card className='eds-grid--m-col-6 no-padding'>
          <CheckboxForwardToDLQ
            checked={settings.forwardErrorsToDLQ}
            onChange={() => setValue(`settings.forwardErrorsToDLQ`, !settings.forwardErrorsToDLQ)}
            settingType='global'
          />
        </Card>
      </div>

      <Naming name='settings.naming' />
    </div>
  )
}

export { S3, s3Settings }

const Regions = [
  'ap-northeast-1',
  'ap-northeast-2',
  'ap-northeast-3',
  'ap-south-1',
  'ap-southeast-1',
  'ap-southeast-2',
  'ca-central-1',
  'cn-north-1',
  'eu-central-1',
  'eu-north-1',
  'eu-west-1',
  'eu-west-2',
  'eu-west-3',
  'sa-east-1',
  'us-east-1',
  'us-east-2',
  'us-gov-east-1',
  'us-gov-west-1',
  'us-west-1',
  'us-west-2',
]

const ACL = [
  'authenticated-read',
  'aws-exec-read',
  'bucket-owner-full-control',
  'bucket-owner-read',
  'log-delivery-write',
  'private',
  'public-read',
  'public-read-write',
]
