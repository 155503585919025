import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Text, TextField } from '@nike/eds'

import { Label } from '../../../components/FormComponents'
import Info from '../../../components/Info'

function Flush({ name }) {
  const { register } = useFormContext()

  return (
    <>
      <Label label='Flush' />
      <div className='eds-grid eds-grid--m-cols-8'>
        <div>
          <TextField
            {...register(name + '.count', { valueAsNumber: true })}
            label={
              <Text font='subtitle-1' as='div'>
                Count
                <Info
                  tooltip={
                    <>
                      The number of records that should be buffered before writing to the container.
                      <br />
                      If not specified then this value defaults to 100.
                    </>
                  }
                />
              </Text>
            }
          />
        </div>
        <div>
          <TextField
            {...register(name + '.frequency', { valueAsNumber: true })}
            label={
              <Text font='subtitle-1' as='div'>
                Frequency
                <Info
                  tooltip={
                    <>
                      {' '}
                      The frequency (in milliseconds) to write to file regardless of whether the
                      buffer size has been reached.
                      <br />
                      If set to -1 records are only written when the flush count has been reached
                    </>
                  }
                />
              </Text>
            }
          />
        </div>
      </div>
    </>
  )
}

export default Flush
