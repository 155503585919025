import React, { useState } from 'react'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { SelectCloudRedGuids } from '@nike/cx-x-product'
import { Button, Card, TextArea, TextField, Snack, StatusIndicator, TextGroup } from '@nike/eds'

import AlarmForm from '../components/AlarmForm'
import { Title } from '../components/FormComponents'
import TagForm from '../components/TagForm'
import config from '../config'
import { useNarkOnPageLoad } from '../util/nark'
import {
  createAlarms,
  createResource,
  createSchema,
  replaceTypeShortName,
  validateResourceName,
} from '../util/utils'
import SourceType from './RegisterSource/SourceType'

function RegisterSource() {
  const okta = JSON.parse(window.localStorage.getItem('okta-token-storage'))
  const selectCloudRedGuidsOptions = {
    accessToken: okta?.accessToken?.accessToken,
    userEmail: okta?.idToken?.claims?.email,
    env: config.env === 'prod' ? 'prod' : 'qa',
    id: 'cloudRedGUID',
    placeholder: '3ca05652-c688-4212-a3ab-c8963e7e31a0',
    label: 'Cloud Red GUID',
    isMulti: false,
  }

  useNarkOnPageLoad('register-source-page')
  const [tags, setTags] = useState([])
  const [registration, setRegistration] = useState()
  const methods = useForm()
  const history = useHistory()
  const {
    clearErrors,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = methods

  async function onSubmit(rawData) {
    await validateResourceName(rawData?.name, setError, clearErrors)
    if (!rawData.settings || Object.keys(errors).length) return null
    setRegistration({ status: 'info', message: 'Submitting registration....' })

    rawData.tags = tags
    const extraFields = {
      alarms: rawData?.alarms,
      sourceName: rawData.name,
      schema: rawData?.schema,
    }
    // Remove extra fields from serialized form object
    // Alarms and schema are updated through separate API calls
    delete rawData?.alarms
    delete rawData.name
    delete rawData?.schema

    // Adds correct source type name to body due to the field name containing '.'
    const formattedBody = replaceTypeShortName(rawData)

    if (!(await createResource('sources', extraFields.sourceName, formattedBody, setRegistration)))
      return null

    if (extraFields.alarms.length > 0) {
      setRegistration({ status: 'info', message: 'Saving alarms...' })
      if (
        !(await createAlarms(
          'sources',
          extraFields.sourceName,
          extraFields.alarms,
          setRegistration
        ))
      )
        return null
    }
    if (extraFields?.schema && Object.keys(extraFields.schema).length) {
      setRegistration({ status: 'info', message: 'Saving schema...' })
      if (
        !(await createSchema(
          'sources',
          extraFields.sourceName,
          extraFields.schema,
          setRegistration
        ))
      )
        return null
    }

    history.push(history.push(`/sources/details?resource=${extraFields.sourceName}`))
  }

  return (
    <FormProvider {...methods}>
      <form style={{ padding: 44 }} onSubmit={handleSubmit(onSubmit)}>
        <Title title='Register a Source' />

        <Card padding={24} className='eds-spacing--mb-24'>
          <TextGroup>
            <TextField
              id='name'
              {...register('name', { required: true })}
              placeholder='llsv2-suppy-store-stock_events'
              hasErrors={!!errors.name}
              onBlur={(e) => validateResourceName(e.target.value, setError, clearErrors)}
              errorMessage={errors.name?.message}
              label='Name your source'
            />
            <Controller
              control={methods.control}
              name='description'
              render={({ field: { onChange, onBlur, value } }) => (
                <TextArea
                  id='description'
                  placeholder="LLSv2 subscription to 'supply' domain with event type 'supply/store_stock_events' (id '8f8929239-d542-39383dtd'"
                  hasErrors={!!errors.description}
                  label='Add Description'
                  minRows={2}
                  resize='vertical'
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />
            <Controller
              control={methods.control}
              name='cloudRedGUID'
              render={({ field }) => (
                <SelectCloudRedGuids
                  {...selectCloudRedGuidsOptions}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                />
              )}
            />
          </TextGroup>
        </Card>

        <Card size={24} className='eds-spacing--mb-24'>
          <TagForm tags={tags} setTags={setTags} />
        </Card>

        <SourceType />
        <AlarmForm />
        <div
          style={{ display: 'grid', gridTemplateColumns: '200px 1fr', columnGap: 24 }}
          className='eds-spacing--mb-16'
        >
          <div>
            <Button
              type='submit'
              disabled={registration?.status === 'info' || Object.keys(errors).length}
            >
              Create &amp; Finish
            </Button>
          </div>
          <div>
            {registration && (
              <Snack status={registration.status} onDismiss={() => setRegistration(null)}>
                <p>{registration.message}</p>
                {registration.errors &&
                  registration.errors.map((error, i) => (
                    <StatusIndicator key={i} status='inactive' label={error.description} />
                  ))}
              </Snack>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

export default RegisterSource
