import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Label, Select } from '../../../components/FormComponents'

function Mode({ mode, value }) {
  const { register } = useFormContext()

  return (
    <div className='eds-spacing--mb-24 eds-grid eds-grid--m-cols-4'>
      <div>
        <Label label='Mode' />
      </div>
      <div>
        <Select
          value={value}
          defaultValue='insert'
          inputProps={register(mode)}
          options={['insert', 'update', 'upsert']}
        />
      </div>
    </div>
  )
}

export default Mode
