import React from 'react'

import { Checkbox } from '@nike/eds'

const globalSetting =
  'Forward Errors To DLQ - Forward to a dead letter queue all messages that cannot be sent. This applies to all errors regardless of settings on individual handlers.'
const handlerSetting =
  'Forward Errors To DLQ - For all messages that fail with the response code on this handler'
const globalOverrides =
  'This setting has no effect if the global ForwardErrorsToDLQ property is set to true since all errors will be forwarded to the dead letter queue.'
const forwardWithRetry = 'more than MaxRetryCount times forward the message to a dead letter queue.'
const forwardOnFailure = 'forward the message to a dead letter queue.'

function CheckboxForwardToDLQ({ settingType, ...props }) {
  let phrases = []
  switch (settingType) {
    case 'failure':
      phrases = [handlerSetting, forwardOnFailure, globalOverrides]
      break
    case 'retry':
      phrases = [handlerSetting, forwardWithRetry, globalOverrides]
      break
    case 'global':
    default:
      phrases = [globalSetting]
      break
  }

  return <Checkbox {...props} label={phrases.join(' ')} />
}

export default CheckboxForwardToDLQ
