import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security, SecureRoute } from '@okta/okta-react'
import React from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'

import styles from './App.module.styl'
import ErrorBoundary from './ErrorBoundary'
import NavBar from './components/NavBar'
import NotFoundPage from './components/NotFound'
import config from './config'
import EditPage from './pages/EditPage.jsx'
import HomePage from './pages/HomePage.jsx'
import ImplicitCallback from './pages/ImplicitCallback'
import KStreamsPage from './pages/KStreamsPage.jsx'
import RegisterKStreamPage from './pages/RegisterKStreamPage.jsx'
import RegisterSinkPage from './pages/RegisterSinkPage.jsx'
import RegisterSourcePage from './pages/RegisterSourcePage.jsx'
import SinkDetails from './pages/SinkDetails.jsx'
import SinksPage from './pages/SinksPage.jsx'
import SourceDetails from './pages/SourceDetails.jsx'
import SourcesPage from './pages/SourcesPage.jsx'

const okta = new OktaAuth(config.oAuth)

function App() {
  const history = useHistory()

  const restoreOriginalUri = (_, originalUri) => {
    if (originalUri.startsWith('/nsp')) originalUri = originalUri.substr(4)
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  return (
    <Security oktaAuth={okta} restoreOriginalUri={restoreOriginalUri}>
      <NavBar>
        <ErrorBoundary>
          <Switch>
            <Route path='/login/callback' component={ImplicitCallback} />
            <SecureRoute exact path='/' component={HomePage} />
            <SecureRoute exact path='/sources/edit' component={EditPage} />
            <SecureRoute exact path='/sinks/edit' component={EditPage} />
            <SecureRoute exact path='/sources' component={SourcesPage} />
            <SecureRoute exact path='/sources/details' component={SourceDetails} />
            <SecureRoute exact path='/sinks/details' component={SinkDetails} />
            <SecureRoute exact path='/kstreams' component={KStreamsPage} />
            <SecureRoute exact path='/sinks' component={SinksPage} />
            <SecureRoute exact path='/sources/register' component={RegisterSourcePage} />
            <SecureRoute exact path='/kstreams/register' component={RegisterKStreamPage} />
            <SecureRoute exact path='/sinks/register' component={RegisterSinkPage} />
            <SecureRoute path='*' component={NotFoundPage} />
          </Switch>
        </ErrorBoundary>
      </NavBar>
      <div className={styles.appBottomSpacing}></div>
    </Security>
  )
}

export default App
