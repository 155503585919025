import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Button, Card, Icon, Text, TextField } from '@nike/eds'

import { Label } from '../../components/FormComponents'

function consumerSettings(data) {
  const settings = {
    type: 'kafka.consumer',
    'kafka.consumer': {
      users: data.settings.users,
    },
  }
  return settings
}

function Consumer() {
  const { register, setValue, watch } = useFormContext()
  const settings = watch('settings') || {}
  if (!settings.users) settings.users = ['']

  return (
    <div>
      <Label label='Users' required />
      <Text font='subtitle-2' className='eds-spacing--mb-24'>
        The list of users to allow. Users must be applications registered in developer portal.
      </Text>
      {settings.users.map((user, i) => (
        <div key={i} className='eds-spacing--mb-16 eds-grid eds-grid--m-cols-6'>
          <Card className='eds-grid--m-col-2 no-padding'>
            <TextField {...register(`settings.users.${i}`, { required: true })} />
          </Card>
          {i > 0 && (
            <Card className='eds-grid--m-col-2'>
              <Button
                variant='secondary'
                size='s'
                onClick={() => {
                  settings.users.splice(i, 1)
                  setValue('settings.users', settings.users)
                }}
                className='eds-spacing--mt-8 no-padding'
              >
                <Icon name='Close' />
              </Button>
            </Card>
          )}
        </div>
      ))}
      <Button
        variant='secondary'
        onClick={() => setValue('settings.users', [...settings.users, ''])}
      >
        Add User
      </Button>
    </div>
  )
}

export { Consumer, consumerSettings }
