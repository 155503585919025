import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Card, TextField, Radio, Text } from '@nike/eds'

import { CardTitle, Label } from '../../../components/FormComponents'

function ConnectionDetails() {
  const { register, watch, setValue } = useFormContext()
  const name = 'settings.sqlserver'
  const auth = watch(`${name}.auth.type`, '')
  const encryption = watch(`${name}.connectionEncryption.type`, '')

  const resetAuth = () => {
    setValue(`${name}.auth.type`, '')
    setValue(`${name}.auth`, {})
  }
  const resetEncryption = () => {
    setValue(`${name}.connectionEncryption.type`, '')
    setValue(`${name}.connectionEncryption`, {})
  }
  return (
    <Card padding={24}>
      <CardTitle title='Connection Details' />
      <TextField
        {...register(`${name}.database`, { required: true })}
        required
        className='eds-spacing--mb-16'
        placeholder='Specify database name'
        label={
          <Label label='Database' required tooltip='The name of the database to connect to.' />
        }
      />
      <div className={'eds-spacing--mb-16 eds-grid eds-grid--m-cols-2'}>
        <div>
          <TextField
            {...register(`${name}.host`, { required: true })}
            className='eds-spacing--mb-16'
            placeholder='sqlserver.host'
            label={<Label label='Host' required />}
          />
        </div>
        <div>
          <TextField
            {...register(`${name}.port`, { required: true, valueAsNumber: true })}
            className='eds-spacing--mb-16'
            placeholder='5432'
            defaultValue={5432}
            label={<Label label='Port' />}
          />
        </div>
      </div>
      <CardTitle
        title='Auth'
        tooltip='Information for auth against the database. All requests will be made using the default schema for the configured user unless the tables and/or views are prefixed with a schema name (e.g. my_schema.my_table).'
      />
      <div className={'eds-spacing--mb-24 eds-grid eds-grid--m-cols-6'}>
        <Radio
          id='auth_none'
          value=''
          onClick={() => resetAuth()}
          checked={!auth}
          label={<Text font='body-2'>None (Default)</Text>}
        />
        <Radio
          id='auth_basic'
          value='basic'
          checked={!!auth}
          onClick={(e) => setValue(`${name}.auth.type`, e.target.value)}
          label={<Text font='body-2'>Basic</Text>}
        />
      </div>
      {auth && (
        <div className={'eds-grid eds-grid--m-cols-2'}>
          <div>
            <TextField
              {...register(`${name}.auth.basic.user`)}
              className='eds-spacing--mb-16'
              label='Username'
              subtitle='The user name to connect with'
            />
          </div>
          <div>
            <TextField
              {...register(`${name}.auth.basic.password`)}
              className='eds-spacing--mb-16'
              label='Password'
              subtitle='The password to connect with'
            />
          </div>
        </div>
      )}
      <CardTitle
        title='Connection Encryption'
        tooltip='The mode for making encrypted connections. If not defined then this defaults to ssl with no additional settings'
      />
      <div className={'eds-spacing--mb-16 eds-grid eds-grid--m-cols-3'}>
        <Radio
          name='encryption'
          id='encryption_none'
          value='none'
          onClick={(e) => resetEncryption()}
          checked={!encryption}
          label={<Text font='body-2'>None (Default)</Text>}
        />
        <Radio
          name='encryption'
          id='encryption_ssl'
          value='ssl'
          checked={encryption === 'ssl'}
          onClick={(e) => setValue(`${name}.connectionEncryption.type`, e.target.value)}
          label={<Text font='body-2'>SSL</Text>}
        />
        <Radio
          name='encryption'
          id='encryption_unencrypted'
          value='unencrypted'
          checked={encryption === 'unencrypted'}
          onClick={(e) => setValue(`${name}.connectionEncryption.type`, e.target.value)}
          label={<Text font='body-2'>Unencrypted</Text>}
        />
      </div>
    </Card>
  )
}

export default ConnectionDetails
