import { Azure } from './pages/RegisterSink/Azure'
import { Consumer } from './pages/RegisterSink/Consumer'
import { ElasticSearch } from './pages/RegisterSink/ElasticSearch'
import { Fusion } from './pages/RegisterSink/Fusion'
import { Http } from './pages/RegisterSink/Http'
import { IbmMq } from './pages/RegisterSink/IbmMq'
import { MySql } from './pages/RegisterSink/MySql'
import { S3 } from './pages/RegisterSink/S3'
import { Snowflake } from './pages/RegisterSink/Snowflake'
import { Splunk } from './pages/RegisterSink/Splunk'
import { SqlServer } from './pages/RegisterSink/SqlServer'
import FusionForm from './pages/RegisterSource/Fusion/FusionForm'
import HttpForm from './pages/RegisterSource/Http/HttpForm'
import IbmMqForm from './pages/RegisterSource/IbmMq/IbmMqForm'
import MySqlForm from './pages/RegisterSource/MySql/MySqlForm'
import OracleForm from './pages/RegisterSource/Oracle/OracleForm'
import PostgresForm from './pages/RegisterSource/Postgres/PostgresForm'
import ProducerForm from './pages/RegisterSource/Producer/ProducerForm'
import SqlServerForm from './pages/RegisterSource/SqlServer/SqlServerForm'

export const SourceTypeMap = {
  'kafka.http.push': 'http',
  'kafka.connect.jdbc.sqlserver': 'sqlserver',
  'kafka.connect.jdbc.mysql': 'mysql',
  'kafka.connect.jdbc.postgres': 'postgres',
  'kafka.connect.jdbc.oracledb': 'oracledb',
  'kafka.connect.ibmmq': 'ibmmq',
  'kafka.connect.jms.fusion': 'fusion',
  'kafka.producer': 'producer',
}

export const SourceFormMap = {
  'kafka.connect.jdbc.sqlserver': SqlServerForm,
  'kafka.http.push': HttpForm,
  'kafka.connect.jdbc.postgres': PostgresForm,
  'kafka.producer': ProducerForm,
  'kafka.connect.jdbc.oracledb': OracleForm,
  'kafka.connect.jdbc.mysql': MySqlForm,
  'kafka.connect.ibmmq': IbmMqForm,
  'kafka.connect.jms.fusion': FusionForm,
}

export const SinkTypes = {
  'kafka.connect.azure.blob': Azure,
  'kafka.connect.elasticsearch': ElasticSearch,
  'kafka.connect.http.push': Http,
  'kafka.connect.ibmmq': IbmMq,
  'kafka.connect.jdbc.mysql': MySql,
  'kafka.connect.jdbc.snowflake': Snowflake,
  'kafka.connect.jdbc.sqlserver': SqlServer,
  'kafka.connect.jms.fusion': Fusion,
  'kafka.connect.s3': S3,
  'kafka.connect.splunk': Splunk,
  'kafka.consumer': Consumer,
}
