import React from 'react'
import { useFormContext, useFieldArray, Controller } from 'react-hook-form'

import { Card, TextArea, TextField, Button } from '@nike/eds'
import { Delete } from '@nike/nike-design-system-icons'

import { Label } from '../../../../components/FormComponents'

function Jwt() {
  const name = 'settings.http.auth.jwt'
  const { register, control } = useFormContext()
  const {
    fields: subFields,
    append: subAppend,
    remove: subRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${name}.allowedSubs`, // unique name for your Field Array
  })

  const {
    fields: scopeFields,
    append: scopeAppend,
    remove: scopeRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${name}.scopes`, // unique name for your Field Array
  })
  return (
    <>
      <Controller
        control={control}
        name={`${name}.publicKey`}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextArea
            id='source-jwt-publicKey'
            style={{ marginBottom: '16px' }} // TODO: soon to be a patch for this in EDS libary (issue is with the `className` prop)
            required
            minRows={4}
            label='Public Key'
            subtitle='Use PEM format including begin and end certificate'
            resize='vertical'
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
        )}
      />

      <Label
        label='Allowed Subs'
        tooltip={
          'If no sub is set, all subs will be allowed through as long as all other claims are valid'
        }
      />
      {subFields.map((field, index) => (
        <div key={field.id} className={'eds-grid eds-grid--m-cols-12'}>
          <Card className='eds-grid--m-col-9 no-padding'>
            <TextField
              {...register(`${name}.allowedSubs[${index}]`)}
              className='eds-spacing--mb-16'
              defaultValue={''}
              key={field.id}
              required
            />
          </Card>
          <Card className='eds-spacing--mt-24 eds-grid--m-col-3 no-padding'>
            <Delete onClick={() => subRemove(index)} />
          </Card>
        </div>
      ))}
      <Button variant='secondary' className='eds-spacing--mb-24' onClick={() => subAppend('')}>
        Add Sub
      </Button>
      <Label
        label='Scopes'
        tooltip={
          'The list of scopes that are required on each request. If empty then scopes will not be checked. Otherwise every scope in this list must be present in order for the request to be accepted. The scopes will be taken from the "scp" claim on the input JWT.'
        }
      />
      {scopeFields.map((field, index) => (
        <div key={field.id} className={'eds-grid eds-grid--m-cols-12'}>
          <Card className='eds-grid--m-col-9 no-padding'>
            <TextField
              {...register(`${name}.scopes[${index}]`)}
              className='eds-spacing--mb-16'
              defaultValue={''}
              key={field.id}
              required
            />
          </Card>
          <Card className='eds-spacing--mt-24 eds-grid--m-col-3 no-padding'>
            <Delete onClick={() => scopeRemove(index)} />
          </Card>
        </div>
      ))}
      <Button variant='secondary' className='eds-spacing--mb-16' onClick={() => scopeAppend('')}>
        Add Scope
      </Button>
    </>
  )
}
export default Jwt
