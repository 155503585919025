import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { useFieldArray } from 'react-hook-form'

import { TextField, Button, Card, Select } from '@nike/eds'
import { Delete } from '@nike/nike-design-system-icons'

import { Label } from '../../../../components/FormComponents'

function Shoestring() {
  const { setValue, register, control } = useFormContext()
  const name = 'settings.http.auth.shoestring'
  const {
    fields: subFields,
    append: subAppend,
    remove: subRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${name}.allowedSubs`, // unique name for your Field Array
  })

  const {
    fields: scopeFields,
    append: scopeAppend,
    remove: scopeRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${name}.scopes`, // unique name for your Field Array
  })

  const options = [
    { label: 'test', value: 'test' },
    { label: 'production', value: 'production' },
  ]

  return (
    <>
      <TextField
        {...register(`${name}.audience`)}
        className='eds-spacing--mb-24'
        placeholder=''
        label={
          <Label
            label='Audience'
            tooltip={
              'The audience that will be allowed through. If empty or not set then all audiences will be allowed through (if all other claims are valid). The audience will be taken from the "aud" claim on the input JWT and compared to this value.'
            }
          />
        }
      />
      <Label
        label='Environment'
        required
        tooltip={'The name of the AWS Environment where the key set is located.'}
      />
      <Controller
        control={control}
        // Defaults to first value in options array
        defaultValue={options.find((option) => option.value === 'test')?.value}
        name={`${name}.environment`}
        render={({ field }) => (
          <Select
            value={options.filter((option) => field.value === option.value)}
            onChange={(e) => setValue(`${name}.environment`, e.value)}
            options={options}
            className={'eds-spacing--mb-16'}
          />
        )}
      />
      <TextField
        {...register(`${name}.keySetName`, { required: true })}
        required
        className='eds-spacing--mb-24'
        placeholder=''
        label={
          <Label label='Key Set Name' required tooltip={'The name of the Shoestring key set.'} />
        }
      />
      <Label
        label='Allowed Subs'
        tooltip={
          'If no sub is set, all subs will be allowed through as long as all other claims are valid'
        }
      />
      {subFields.map((field, index) => (
        <div key={field.id} className={'eds-grid eds-grid--m-cols-12'}>
          <Card className='eds-grid--m-col-9 no-padding'>
            <TextField
              {...register(`${name}.allowedSubs[${index}]`)}
              className='eds-spacing--mb-16'
              defaultValue={''}
              key={field.id}
              required
            />
          </Card>
          <Card className='eds-spacing--mt-24 eds-grid--m-col-3 no-padding'>
            <Delete onClick={() => subRemove(index)} />
          </Card>
        </div>
      ))}
      <Button variant='secondary' className='eds-spacing--mb-24' onClick={() => subAppend('')}>
        Add Sub
      </Button>
      <Label
        label='Scopes'
        tooltip={
          'The list of scopes that are required on each request. If empty then scopes will not be checked. Otherwise every scope in this list must be present in order for the request to be accepted. The scopes will be taken from the "scp" claim on the input JWT.'
        }
      />
      {scopeFields.map((field, index) => (
        <div key={field.id} className={'eds-grid eds-grid--m-cols-12'}>
          <Card className='eds-grid--m-col-9 no-padding'>
            <TextField
              {...register(`${name}.scopes[${index}]`)}
              className='eds-spacing--mb-16'
              defaultValue={''}
              key={field.id}
              required
            />
          </Card>
          <Card className='eds-spacing--mt-24 eds-grid--m-col-3 no-padding'>
            <Delete onClick={() => scopeRemove(index)} />
          </Card>
        </div>
      ))}
      <Button variant='secondary' className='eds-spacing--mb-16' onClick={() => scopeAppend('')}>
        Add Scope
      </Button>
    </>
  )
}
export default Shoestring
