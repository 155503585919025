import clsx from 'clsx'
import React from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'

import { TextField, Button, Card, TextGroup } from '@nike/eds'
import { Delete } from '@nike/nike-design-system-icons'

import { CardTitle, Label, Title } from './FormComponents'
import styles from './RecordDetails.module.styl'

function RecordDetails({ name }) {
  const {
    control,
    register,
    formState: { errors },
    getValues,
    clearErrors,
  } = useFormContext()
  const recordDetailsErrorName = name.split('.')[1]
  const hasSequenceColumnValue = Boolean(getValues(`${name}.sequenceColumn`))

  const {
    fields: tableFields,
    append: tableAppend,
    remove: tableRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${name}.tables`, // unique name for your Field Array
  })

  const {
    fields: viewFields,
    append: viewAppend,
    remove: viewRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${name}.views`, // unique name for your Field Array
  })
  const columnDetailsError =
    errors?.settings && errors.settings[recordDetailsErrorName]?.updateColumn

  return (
    <>
      <Title title='Record Details' />

      <Card padding={24}>
        <div className='eds-grid eds-grid--m-cols-2'>
          <div>
            <CardTitle title='Table &amp; Views' />
            <Label label='Table' />
            {tableFields.map((field, index) => (
              <div key={field.id}>
                <div className='eds-grid eds-grid--m-cols-4'>
                  <Card className='eds-grid--m-col-3 no-padding'>
                    <TextField
                      className='eds-spacing--mb-16'
                      placeholder='Specified Table'
                      defaultValue={''}
                      key={field.id}
                      {...register(`${name}.tables[${index}]`)}
                    />
                  </Card>
                  <Card className={clsx('eds-grid--m-col-1 no-padding', styles.clearInputIcon)}>
                    <div className='eds-spacing--my-16'>
                      <Delete onClick={() => tableRemove(index)} />
                    </div>
                  </Card>
                </div>
              </div>
            ))}

            <Button
              variant='secondary'
              className='eds-spacing--mb-16'
              onClick={() => tableAppend('')}
            >
              Add Table
            </Button>

            <Label label='View' />

            {viewFields.map((field, index) => (
              <div key={field.id}>
                <div className='eds-grid eds-grid--m-cols-4'>
                  <Card className='eds-grid--m-col-3 no-padding'>
                    <TextField
                      className='eds-spacing--mb-16'
                      placeholder='Specified View'
                      defaultValue={''}
                      key={field.id}
                      {...register(`${name}.views[${index}]`)}
                    />
                  </Card>
                  <Card className={clsx('eds-grid--m-col-1 no-padding', styles.clearInputIcon)}>
                    <div className='eds-spacing--my-16'>
                      <Delete onClick={() => viewRemove(index)} />
                    </div>
                  </Card>
                </div>
              </div>
            ))}
            <Button
              variant='secondary'
              className={'eds-spacing--mb-16'}
              onClick={() => viewAppend('')}
            >
              Add View
            </Button>
          </div>
          <div>
            <CardTitle title='Column Details' />
            <TextGroup>
              <Label
                label='Sequence Column'
                required={columnDetailsError}
                tooltip='The name of the column that is strictly incrementing and unique to each row. Values in this column will be used to determine when new rows are added. One of (or both) of SequenceColumn and UpdateColumn must be defined.'
              />
              <TextField
                {...register(`${name}.sequenceColumn`)}
                placeholder='Specify Sequence Column'
                hideLabel={true}
                onChange={() => clearErrors(`${name}.updateColumn`)}
                hasErrors={columnDetailsError}
              />

              <Label
                label='Update Column'
                required={columnDetailsError}
                tooltip={
                  'The name of the column that is monotonically increasing with each create or update operation to the database but not necessarily unique across rows.'
                }
              />
              <TextField
                {...register(`${name}.updateColumn`, {
                  validate: (value) => Boolean(value) || hasSequenceColumnValue,
                })}
                onChange={() => clearErrors(`${name}.updateColumn`)}
                placeholder='Specify Sequence Column'
                hideLabel={true}
                hasErrors={columnDetailsError}
                errorMessage={'At least one of update column and sequence column must be set'}
              />
            </TextGroup>
          </div>
        </div>
        <TextField
          {...register(`${name}.frequency`, { required: true, valueAsNumber: true })}
          className='eds-spacing--mb-16'
          defaultValue={5000}
          placeholder='5000'
          label={
            <Label
              label='Frequency'
              required
              tooltip='The frequency (in milliseconds) to check for changes to the table.'
            />
          }
        />
      </Card>
    </>
  )
}

export default RecordDetails
