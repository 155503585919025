import { useState, useEffect } from 'react'
import { useCallback } from 'react'

export default function useResizableTable() {
  const [activeIndex, setActiveIndex] = useState(null)
  const [resizableColumn, setResizableColumn] = useState(null)
  const mouseMove = useCallback(
    (e) => {
      const elements = document.getElementsByClassName(resizableColumn)

      const width = e.clientX - elements[0]?.offsetLeft
      for (let i = 0; i < elements.length; i++) {
        if (width >= 200) {
          return (elements[i].style.width = width + 'px')
        }
        return (elements[i].style.width = e.clientX + 'px')
      }
    },
    [resizableColumn]
  )
  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove)
    window.removeEventListener('mouseup', removeListeners)
  }, [mouseMove])
  const mouseUp = useCallback(() => {
    setActiveIndex(null)
    removeListeners()
  }, [setActiveIndex, removeListeners])

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove)
      window.addEventListener('mouseup', mouseUp)
    }

    return () => {
      removeListeners()
    }
  }, [activeIndex, mouseMove, mouseUp, removeListeners])

  const mouseDown = (index, column) => {
    setActiveIndex(index)
    setResizableColumn(column)
  }

  return { mouseDown }
}
