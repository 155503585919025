import React from 'react'

import Stream from './Stream'

function Streams({ resource, resourceType, isAdmin }) {
  if (!resource.streams) return null

  return resource.streams.map((stream, i) => (
    <Stream
      index={i}
      isAdmin={isAdmin}
      key={`stream-${i}`}
      stream={stream}
      resource={resource}
      resourceType={resourceType}
    />
  ))
}

export default Streams
