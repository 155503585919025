import { putResource, getResource, postResource } from '../api'
import { SourceTypeMap } from '../types'
import { NarkLog } from './nark'

export function getOktaToken() {
  try {
    const oktaTokenStorage = window.localStorage.getItem('okta-token-storage')
    if (!oktaTokenStorage) return ''
    const oktaToken = JSON.parse(oktaTokenStorage)
    return oktaToken?.accessToken?.accessToken
  } catch (error) {
    console.error(error)
    return ''
  }
}

export function createNarkObj(feature, resourceType, resourceName, response, sourceType = '') {
  const errors = response.body?.errors?.map((error) => error?.description).join(',')
  return {
    feature,
    operation: `${resourceType}${sourceType}:${resourceName}`,
    data: {
      resourceType,
      resourceName,
      sourceType,
      statusCode: response.status,
      message: response?.body.message,
      errors,
      action: feature,
    },
  }
}

export function replaceTypeShortName(data) {
  const currentType = data.settings.type
  data.settings[currentType] = data.settings[SourceTypeMap[currentType]]
  delete data.settings[SourceTypeMap[currentType]]
  return data
}

export async function validateResourceName(name, setError, clearErrors) {
  if (!name) {
    return setError(
      'name',
      {
        type: 'focus',
        message: 'The name field is required',
      },
      { shouldFocus: true }
    )
  }
  const nameRe = /^[a-zA-Z0-9_-]{4,}$/
  if (!nameRe.exec(name))
    return setError(
      'name',
      {
        type: 'focus',
        message: `Name Restrictions: The name must be at least 4 characters long,
            CANNOT include spaces, and only underscore ( _ ) or
            dash ( - ) special characters are allowed.`,
      },
      { shouldFocus: true }
    )
  const resource = await getResource('sources', name)
  if (resource?.source)
    return setError('name', {
      type: 'manual',
      message: `The resource '${name}' already exists. Please use another name.`,
    })
  clearErrors('name')
}

export async function createSchema(resourceType, resourceName, rawSchema, setUserMessage) {
  const schema = { schema: rawSchema }
  return await putResource(`${resourceType}/${resourceName}`, 'streams/main/schema', schema)
    .then((r) => {
      const isSchemaCreated = r.status === 200
      const narkObj = createNarkObj('schema_created', resourceType, resourceName, r)

      let userMessage = { status: 'success', message: 'Schema saved' }

      if (!isSchemaCreated) {
        userMessage = { status: 'error', message: r.body.message, errors: r.body.errors }
        narkObj.feature = 'error'
        narkObj.operation = narkObj.data.errors
      }

      setUserMessage(userMessage)
      NarkLog({ ...narkObj })

      return isSchemaCreated
    })
    .catch((e) => console.log(e.message))
}

export async function createAlarms(resourceType, resourceName, rawAlarms, setUserMessage) {
  const alarms = { alarms: rawAlarms }
  return await putResource(`${resourceType}/${resourceName}`, 'alarms', alarms)
    .then((r) => {
      const isAlarmsCreated = r.status === 200
      const narkObj = createNarkObj('alarms_created', resourceType, resourceName, r)

      let userMessage = { status: 'success', message: 'Alarms saved' }

      if (!isAlarmsCreated) {
        userMessage = { status: 'error', message: r.body.message, errors: r.body.errors }
        narkObj.feature = 'error'
        narkObj.operation = narkObj.data.errors
      }

      setUserMessage(userMessage)
      NarkLog({ ...narkObj })
      return isAlarmsCreated
    })
    .catch((e) => console.log(e.message))
}

export async function createResource(resourceType, resourceName, data, setUserMessage) {
  return await putResource(resourceType, resourceName, data)
    .then((r) => {
      const isResourceCreated = r.status === 200
      // *NOTE: DO NOT post the complete `data` param object to nark, for it can contain sensitive information
      const sourceType = data?.settings?.type ? `:${data.settings.type}` : ':'
      const narkObj = createNarkObj('resource_created', resourceType, resourceName, r, sourceType)

      let userMessage = { status: 'success', message: 'Resource saved' }

      if (!isResourceCreated) {
        userMessage = { status: 'error', message: r.body.message, errors: r.body.errors }
        narkObj.feature = 'error'
        narkObj.operation = narkObj.data.errors
      }

      setUserMessage(userMessage)
      NarkLog({ ...narkObj })

      return isResourceCreated
    })
    .catch((e) => console.log(e.message))
}

export async function createPostResource(resourceType, data, setUserMessage) {
  return await postResource(resourceType, data)
    .then((r) => {
      const isResourceCreated = r.status === 200
      const streamingMethod = data?.settings?.type ? `:${data.settings.type}` : ':'
      const narkObj = createNarkObj('resource_created', resourceType, data.name, r, streamingMethod)
      let userMessage = { status: 'success', message: 'Resource saved' }
      if (!isResourceCreated) {
        userMessage = {
          status: 'error',
          message: r.body.message,
          errors: r.body.errors,
        }
        narkObj.feature = 'error'
        narkObj.operation = narkObj.data.errors
      }

      setUserMessage(userMessage)
      NarkLog({ ...narkObj })

      return isResourceCreated
    })
    .catch((e) => console.log(e.message))
}

const statusTypes = {
  running: 'Running',
  paused: 'Paused',
  degraded: 'Degraded',
  error: 'Error',
  initializing: 'Initializing',
  missingSchema: 'Missing Schema',
  revoked: 'Revoked',
  deleted: 'Deleted',
}

export const getFullStatusString = (status) => {
  return statusTypes[status] ?? status
}
