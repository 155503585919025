import React from 'react'

import StreamSettings from '../../../components/StreamSettings'
import ConnectionDetails from './ConnectionDetails'
import FusionSettings from './FusionSettings'

function FusionForm() {
  return (
    <>
      <ConnectionDetails />
      <StreamSettings sourceType='fusion' />
      <FusionSettings />
    </>
  )
}

export default FusionForm
