import React, { useEffect, useState } from 'react'

import { Button, Icon } from '@nike/eds'

import { getResourceMetrics } from '../../../api'

function SignalFx({ type, resourceName, streamName }) {
  const [metricsUrl, setMetricsUrl] = useState()
  useEffect(() => {
    getResourceMetrics(type, resourceName + '/streams/' + streamName).then((resource) =>
      setMetricsUrl(resource.metrics?.dashboard.url)
    )
  }, [type, resourceName, streamName])

  if (!metricsUrl) return null
  return (
    <a target='_blank' rel='noreferrer' href={metricsUrl}>
      <Button size='small' variant='secondary'>
        <Icon name='ExternalLink' className='eds-spacing--mr-8' /> Metrics
      </Button>
    </a>
  )
}

export default SignalFx
