import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Card } from '@nike/eds'

import { Label, Select } from '../../components/FormComponents'
import { Azure, azureSettings } from './Azure'
import { Consumer, consumerSettings } from './Consumer'
import { ElasticSearch, esSettings } from './ElasticSearch'
import { FilterForm } from './FilterForm'
import { Fusion, fusionSettings } from './Fusion'
import { Http, httpSettings } from './Http'
import { IbmMq, ibmSettings } from './IbmMq'
import { MySql, mySqlSettings } from './MySql'
import { S3, s3Settings } from './S3'
import { Snowflake, snowflakeSettings } from './Snowflake'
import { Splunk, splunkSettings } from './Splunk'
import { SqlServer, sqlServerSettings } from './SqlServer'
import { TransformersForm } from './TransformersForm'

const SinkTypes = [
  { value: 'azure', label: 'Azure Blob Storage' },
  { value: 'es', label: 'Elasticsearch' },
  { value: 'http', label: 'HTTP' },
  { value: 'ibm', label: 'IBM MQ' },
  { value: 'mysql', label: 'MySQL' },
  { value: 'snowflake', label: 'Snowflake' },
  { value: 'sql', label: 'SQL Server' },
  { value: 'fusion', label: 'Fusion (Beta)' },
  { value: 's3', label: 'S3' },
  { value: 'splunk', label: 'Splunk' },
  { value: 'consumer', label: 'Kafka Consumer' },
]

function SinkType() {
  const { register, watch } = useFormContext()
  const type = watch('type')

  return (
    <>
      <Card padding={24} className='eds-spacing--mb-16' name='sink-type'>
        <Label label='Sink Type' required />
        <Select
          value={type}
          options={SinkTypes}
          inputProps={register('type', { required: true })}
          className='eds-spacing--mb-16'
        />

        {type === 'azure' && <Azure />}
        {type === 'es' && <ElasticSearch />}
        {type === 'http' && <Http />}
        {type === 'ibm' && <IbmMq />}
        {type === 'mysql' && <MySql />}
        {type === 'snowflake' && <Snowflake />}
        {type === 'sql' && <SqlServer />}
        {type === 'fusion' && <Fusion />}
        {type === 's3' && <S3 />}
        {type === 'splunk' && <Splunk />}
        {type === 'consumer' && <Consumer />}
      </Card>

      {type && type !== 'consumer' && <FilterForm fieldNamePrefix='filter' />}
      {type && type !== 'consumer' && <TransformersForm />}
    </>
  )
}

function getSettings(data) {
  if (data.type === 'azure') return azureSettings(data)
  if (data.type === 'es') return esSettings(data)
  if (data.type === 'http') return httpSettings(data)
  if (data.type === 'ibm') return ibmSettings(data)
  if (data.type === 'mysql') return mySqlSettings(data)
  if (data.type === 'snowflake') return snowflakeSettings(data)
  if (data.type === 'sql') return sqlServerSettings(data)
  if (data.type === 'fusion') return fusionSettings(data)
  if (data.type === 's3') return s3Settings(data)
  if (data.type === 'splunk') return splunkSettings(data)
  if (data.type === 'consumer') return consumerSettings(data)
}

export { SinkType, getSettings }
