import React from 'react'
import { useFormContext } from 'react-hook-form'

import { TextField, Box } from '@nike/eds'

import { Label, Select } from '../../components/FormComponents'
import BatchCount from './Database/BatchCount'
import Connection from './Database/Connection'
import CreateTable from './Database/CreateTable'
import DatabaseTable from './Database/DatabaseTable'
import DeleteEnabled from './Database/DeleteEnabled'
import Mode from './Database/Mode'
import PrimaryKey from './Database/PrimaryKey'
import Timezone from './Database/Timezone'
import UpdateTable from './Database/UpdateTable'

function snowflakeSettings(data) {
  const settings = {
    type: 'kafka.connect.jdbc.snowflake',
    'kafka.connect.jdbc.snowflake': {
      host: data.settings.host,
      auth:
        data.settings.auth.type === 'nikeOkta'
          ? {
              type: 'nikeOkta',
              nikeOkta: { user: data.settings.auth.user, password: data.settings.auth.password },
            }
          : {
              type: 'basic',
              basic: { user: data.settings.auth.user, password: data.settings.auth.password },
            },
      role: data.settings.role,
      warehouse: data.settings.warehouse,
      schema: data.settings.schema,
      table: data.settings.table,
      database: data.settings.database,
      createTable: data.settings.createTable,
      updateTable: data.settings.updateTable,
      mode: data.settings.mode || 'insert',
    },
  }

  if (data.settings.primaryKey.type !== 'None') {
    settings['kafka.connect.jdbc.snowflake'].primaryKey = data.settings.primaryKey
  }
  return settings
}

function Snowflake() {
  const { register, watch } = useFormContext()
  const settings = watch('settings') || { createTable: true, updateTable: true, primaryKey: {} }
  if (!settings.primaryKey) settings.primaryKey = {}

  return (
    <div>
      <Connection host='settings.host' />

      <div className='eds-spacing--mb-24 eds-grid eds-grid--m-cols-4'>
        <div>
          <Label label='Auth' />
          <Select
            value={settings.auth?.type}
            defaultValue=''
            inputProps={register('settings.auth.type')}
            options={['basic', 'nikeOkta']}
          />
        </div>
        <div>
          <Label label='User' />
          <TextField {...register('settings.auth.user')} />
        </div>
        <div>
          <Label label='Password' />
          <TextField {...register('settings.auth.password')} />
        </div>
      </div>

      <Box className='eds-spacing--mt-24' maxWidth='400px'>
        <Label label='Role' required />
        <TextField
          {...register('settings.role', { required: true })}
          className='eds-spacing--mb-24'
        />
        <Label label='Warehouse' required />
        <TextField
          {...register('settings.warehouse', { required: true })}
          className='eds-spacing--mb-24'
        />
        <Label label='Schema' required />
        <TextField
          {...register('settings.schema', { required: true })}
          className='eds-spacing--mb-24'
        />
      </Box>

      <DatabaseTable database='settings.database' table='settings.table' />

      <CreateTable createTable='settings.createTable' checked={settings.createTable} />
      <UpdateTable updateTable='settings.updateTable' checked={settings.updateTable} />

      <Mode mode='settings.mode' value={settings.mode} />

      <BatchCount batchCount='settings.batchCount' />

      <PrimaryKey
        type='settings.primaryKey.type'
        value={settings.primaryKey.type}
        recordFields='settings.primaryKey.record.fields'
      />

      <Timezone timezone='settings.timezone' value={settings.timezone} />

      <DeleteEnabled deleteEnabled='settings.deleteEnabled' checked={settings.deleteEnabled} />
    </div>
  )
}

export { Snowflake, snowflakeSettings }
