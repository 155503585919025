/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import qs from 'qs'
import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { Button, Icon, Skeleton, Text, TextField, Table, TableHeading, TableCell } from '@nike/eds'

import { postFilterAbort } from '../api'
import { SelectType, SelectAccess } from '../components/Filters'
import StatusIcon from '../components/StatusIcon'
import { Pagination } from '../components/TableComponents'
import useResizableTable from '../hooks/ResizeColumn'
import { useNarkOnPageLoad } from '../util/nark'
import { getFullStatusString } from '../util/utils'
import styles from './Page.module.styl'

const TYPES = [
  { value: 'kafka.consumer', label: 'Consumer' },
  { value: 'kafka.connect.datafabric.s3', label: 'Data Fabric' },
  { value: 'kafka.connect.elasticsearch', label: 'Elasticsearch' },
  { value: 'kafka.connect.http.push', label: 'HTTP' },
  { value: 'kafka.connect.jdbc.mysql', label: 'MySQL' },
  { value: 'kafka.connect.jdbc.snowflake', label: 'Snowflake' },
  { value: 'kafka.connect.jdbc.sqlserver', label: 'SQL Server' },
  { value: 'kafka.connect.s3', label: 'S3' },
  { value: 'kafka.connect.jms.fusion', label: 'Fusion (Beta)' },
  { value: 'kafka.connect.azure.blob', label: 'Azure Blob Storage' },
  { value: 'kafka.connect.ibmmq', label: 'IBM MQ' },
  { value: 'kafka.connect.splunk', label: 'Splunk' },
]
const LABELS = TYPES.reduce((labels, type) => ({ ...labels, [type.value]: type.label }), {})

const ACCESS = {
  All: 'All',
  DescribeSettings: 'Read',
  UpdateSettings: 'Admin',
}

/**
 * param (object) filters - {search, types, access}
 */
function buildApiFiltersFromQuerystring(filters, table) {
  const filter = { from: (table.page - 1) * table.rows, size: table.rows }
  const typeFilters = filters.types.map((type) => ({
    string: { field: 'type', logic: { equal: type } },
  }))

  if (filters.search) {
    const searchFilters = ['name', 'id', 'description', 'state'].map((field) => ({
      string: { field, logic: { contains: filters.search.trim() } },
    }))
    searchFilters.push({ tag: { value: { contains: filters.search.trim() } } })
    if (typeFilters.length)
      filter.sink = {
        and: { filters: [{ or: { filters: searchFilters } }, { or: { filters: typeFilters } }] },
      }
    else filter.sink = { or: { filters: searchFilters } }
  } else if (typeFilters.length) filter.sink = { or: { filters: typeFilters } }

  if (filters.access !== 'All') filter.sinkAccess = { access: { equal: 'sink:' + filters.access } }

  return filter
}

function getNewHistoryIfNeeded(filters, location) {
  const new_params = []
  for (const value of filters.types)
    new_params.push('type=' + TYPES.findIndex((type) => type.value === value))
  if (filters.access === 'DescribeSettings') new_params.push('access=1')
  if (filters.access === 'UpdateSettings') new_params.push('access=2')
  if (filters.search) new_params.push(`search=${filters.search}`)
  const search = new_params ? '?' + new_params.join('&') : ''

  if (location.search !== search) {
    return { pathname: location.pathname, search }
  }
  return null
}

function paramTypes(types) {
  if (types === undefined) return []
  if (Array.isArray(types)) return types.map((type) => TYPES[type].value)
  return [TYPES[types].value]
}

function Sinks({ location }) {
  useNarkOnPageLoad('sinks-page')
  const params = qs.parse(location.search?.substring(1))
  const history = useHistory()
  const [table, setTable] = useState({ page: 1, rows: 100 })
  const controllerRef = useRef()
  const [filters, setFilters] = useState({
    search: params?.search || '',
    types: paramTypes(params.type),
    access:
      params.access === '1' ? 'DescribeSettings' : params.access === '2' ? 'UpdateSettings' : 'All',
  })
  const [sinks, setSinks] = useState()
  const headerColums = [
    { name: 'Name', isResizable: true },
    { name: 'Description', width: '300px' },
    { name: 'Status', width: '200px' },
    { name: 'Type', width: '200px' },
    { name: 'Created', width: '120px' },
  ]

  useEffect(() => {
    const filter = buildApiFiltersFromQuerystring(filters, table)
    postFilterAbort('sink', filter, controllerRef).then(setSinks)

    const newHistory = getNewHistoryIfNeeded(filters, location)
    newHistory && history.replace(newHistory)

    return () => setSinks(null)
  }, [table, filters, history, location])

  const { mouseDown } = useResizableTable()

  return (
    <div className={styles.TablePage}>
      <div className={styles.TablePageGrid}>
        <div>
          <TextField
            className={styles.tableSearchFilter}
            data-test-id='search-input'
            value={filters.search}
            beforeSlot={<Icon name='Search' />}
            placeholder='Search sinks by id, name, description'
            onChange={(e) =>
              setFilters({ search: e.target.value, types: filters.types, access: filters.access })
            }
          />
        </div>
        <div>
          <SelectType
            types={TYPES}
            values={filters.types}
            setTypes={(types) =>
              setFilters({ search: filters.search, types, access: filters.access })
            }
          />

          <SelectAccess
            access={filters.access}
            setAccess={(access) =>
              setFilters({ search: filters.search, types: filters.types, access })
            }
          />
        </div>
        <div>
          <Link to={'/sinks/register'}>
            <Button
              data-test-id='register-button'
              size='small'
              style={{ float: 'right', WebkitAppearance: 'none' }}
            >
              Register Sink
            </Button>
          </Link>
        </div>
      </div>

      {!sinks && <Skeleton height={24} />}

      {sinks && !sinks.sinks?.length && (
        <div className={styles.tableNoResultMessage}>
          <Text font='title-5' as='div'>
            Oops, no results found for
            {filters.search && <> “{filters.search}”</>}
            {filters.types && <p>{filters.types.map((type) => LABELS[type]).join(', ')}</p>}
            Access: {ACCESS[filters.access]}
          </Text>
          <Text font='body-2' color='#757575'>
            Try to adjust your search
          </Text>
        </div>
      )}

      {sinks?.sinks?.length > 0 && (
        <div>
          <Table className={clsx('eds-type--body-3', styles.resizeTable)}>
            <thead>
              <tr>
                {headerColums.map((column, i) => (
                  <TableHeading
                    key={column.name}
                    className={column.isResizable ? `resizable-table-${column.name}` : ''}
                    style={{ width: column.width }}
                  >
                    <span>{column.name}</span>
                    {column.isResizable ? (
                      <span
                        onMouseDown={() => mouseDown(i, `resizable-table-${column.name}`)}
                        className={styles.resizeHandle}
                      />
                    ) : null}
                  </TableHeading>
                ))}
              </tr>
            </thead>
            <tbody>
              {sinks &&
                sinks.sinks.map((sink) => (
                  <tr
                    key={sink.id}
                    onClick={() => history.push('/sinks/details?resource=' + sink.name)}
                  >
                    <TableCell
                      className={
                        headerColums[0].isResizable ? `resizable-table-${headerColums.name}` : ''
                      }
                    >
                      <div title={sink.name} className={styles.ellipsisStyle}>
                        {sink.name}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div title={sink.description} className={styles.ellipsisStyle}>
                        {sink.description}
                      </div>
                    </TableCell>
                    <TableCell beforeSlot={<StatusIcon state={sink.status.state} size='s' />}>
                      {getFullStatusString(sink.status.state)}
                    </TableCell>
                    <TableCell>{LABELS[sink.type] || sink.type}</TableCell>
                    <TableCell>{new Date(sink.createdAt).toLocaleDateString()}</TableCell>
                  </tr>
                ))}
            </tbody>
          </Table>

          <Pagination totalCount={sinks?.totalCount} table={table} setTable={setTable} />
        </div>
      )}
    </div>
  )
}

export default Sinks
