import React, { useState } from 'react'
import AceEditor from 'react-ace'

import { Button, Icon, Modal, Text, Tooltip } from '@nike/eds'

import 'ace-builds/webpack-resolver'

function StreamSchema({ index, stream }) {
  const [visible, setVisible] = useState(false)
  if (!stream) return null
  const { name, schema } = stream

  if (!schema) {
    return (
      <Tooltip
        bodySlot={<Text font='subtitle-2'>There is no schema defined for this stream.</Text>}
        placement='right'
        isDark={true}
      >
        <Button size='small' variant='secondary' disabled>
          <Icon name='Receipt' className='eds-spacing--mr-8' /> View Schema
        </Button>
      </Tooltip>
    )
  }

  const streamSchema = JSON.stringify(schema, null, 2)
  return (
    <>
      <Button size='small' variant='secondary' onClick={() => setVisible(true)}>
        <Icon name='Receipt' className='eds-spacing--mr-8' /> View Schema
      </Button>
      <Modal
        onDismiss={() => setVisible(false)}
        isOpen={visible}
        headerSlot={`Stream ${name}`}
        footerSlot={
          <AceEditor
            mode='json'
            theme='tomorrow'
            value={streamSchema}
            readOnly
            name={`stream-${index}`}
            tabSize={2}
            fontSize={18}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            useWorker={false}
          />
        }
      >
        <Text font='body-1' as='p'>
          Viewing schema for stream {name}
        </Text>
      </Modal>
    </>
  )
}

export default StreamSchema
