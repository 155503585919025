import React, { useState } from 'react'

import { TextField, TextGroup, Chip, Icon } from '@nike/eds'

function TagForm({ tags, setTags }) {
  const [enteredText, setEnteredText] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  function addTag(event, tag) {
    tag ? setErrorMsg('The tag is not saved, press enter to save') : setErrorMsg('')
    if (!tags.includes(tag) && tag.length > 0 && event.keyCode === 13) {
      setTags([...tags, tag])
      setEnteredText('')
      setErrorMsg('')
    }

    if (tags.includes(tag) && event.keyCode === 13) {
      setErrorMsg('Tag already added')
    }
  }

  function deleteTag(tag) {
    const remainingTags = tags.filter((t) => {
      return t !== tag
    })
    setTags([...remainingTags])
  }

  return (
    <TextGroup data-testid='tags'>
      <TextField
        label='Tags'
        subtitle='Press enter after typing each tag to add'
        type='text'
        onChange={(e) => setEnteredText(e.target.value)}
        hasErrors={errorMsg}
        errorMessage={errorMsg}
        value={enteredText}
        placeholder='production, integration-test, development'
        onKeyDown={(e) => {
          if (e.key === 'Enter') e.preventDefault()
          addTag(e, e.target.value.trim())
        }}
      />
      <div className='eds-spacing--pt-4 eds-spacing--pb-4 eds-spacing--pl-8 eds-spacing--pl-8'>
        {tags.map((item) => (
          <Chip
            as='button'
            key={item}
            onClick={() => deleteTag(item)}
            afterSlot={<Icon size='sm' name='Close' />}
            className='eds-spacing--m-4'
          >
            {item}
          </Chip>
        ))}
      </div>
    </TextGroup>
  )
}

export default TagForm
