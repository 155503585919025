import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import React from 'react'

import { Button, Icon, Text } from '@nike/eds'

import styles from './TableComponents.module.styl'

function Pagination({ totalCount, table, setTable }) {
  const from = (table.page - 1) * table.rows
  const to = Math.min(from + table.rows, totalCount)
  const num_pages = Math.ceil(totalCount / table.rows)
  const pages = []
  let p = Math.max(table.page - 5, 0)
  while (p < num_pages && pages.length < 5) pages.push(++p)

  function changePage(page) {
    if (page < 1 || page === table.page) return
    setTable({ page, rows: table.rows })
  }

  return (
    <table className='eds-spacing--mt-24' style={{ width: '100%' }}>
      <tbody>
        <tr>
          <td>
            <Text font='subtitle-1'>
              {from + 1}-{to} of {totalCount} Results
            </Text>
          </td>
          <td align='center'>
            <Text font='subtitle-1'>
              <Button variant='ghost' onClick={() => changePage(1)}>
                First
              </Button>
              <Button
                variant='ghost'
                onClick={() => changePage(table.page - 1)}
                className={styles.caretLeft}
              >
                <Icon name='CaretLeft' />
              </Button>
              {pages.map((page) => (
                <span
                  key={page}
                  onClick={() => changePage(page)}
                  className={page === table.page ? styles.underlineStyle : styles.pageStyle}
                >
                  {page}
                </span>
              ))}
              <Button
                variant='ghost'
                onClick={() => changePage(Math.min(table.page + 1, num_pages))}
                className={styles.caretRight}
              >
                <Icon name='CaretRight' />
              </Button>
              <Button variant='ghost' onClick={() => changePage(num_pages)}>
                Last
              </Button>
            </Text>
          </td>
          <td align='right'>
            <Text font='subtitle-1' as='span'>
              Rows Per Page:
            </Text>
            <Select
              className={styles.selectRows}
              variant='standard'
              IconComponent={() => <Icon name='CaretDown' size='l' />}
              value={table.rows}
              onChange={(e) => setTable({ page: 1, rows: parseInt(e.target.value) })}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export { Pagination }
