import React from 'react'

import StreamSettings from '../../../components/StreamSettings'
import StreamWrapper from './StreamWrapper'

function ProducerForm() {
  return (
    <>
      <StreamSettings sourceType='producer' />
      <StreamWrapper />
    </>
  )
}

export default ProducerForm
