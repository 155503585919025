import React, { useEffect } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { Card, TextField, Select } from '@nike/eds'

import { CardTitle, Label } from '../../../components/FormComponents'

function ConnectionDetails() {
  const { register, setValue, watch, control } = useFormContext()
  const name = 'settings.ibmmq'
  const destination = watch(`${name}.destination.type`)
  const options = [
    { label: 'queue', value: 'queue' },
    { label: 'topic', value: 'topic' },
  ]

  useEffect(() => {
    destination ?? setValue(`${name}.destination.type`, 'queue')
  }, [setValue, destination])

  return (
    <Card padding={24}>
      <CardTitle title='Connection Details' />
      <TextField
        {...register(`${name}.queueManager`, { required: true })}
        className='eds-spacing--mb-16'
        placeholder=''
        required
        label={<Label label='Queue Manager' required tooltip='The name of the queue manager.' />}
      />
      <div className={'eds-spacing--mb-16 eds-grid eds-grid--m-cols-2'}>
        <div>
          <TextField
            {...register(`${name}.host`, { required: true })}
            className='eds-spacing--mb-16'
            placeholder='mysql.host'
            label={<Label label='Host' required />}
          />
        </div>
        <div>
          <TextField
            {...register(`${name}.port`, { required: true, valueAsNumber: true })}
            className='eds-spacing--mb-16'
            placeholder='1414'
            defaultValue={1414}
            label={<Label label='Port' />}
          />
        </div>
      </div>
      <TextField
        {...register(`${name}.transport.client.channel`)}
        className='eds-spacing--mb-16'
        label={
          <Label
            label='Transport Channel'
            tooltip='Optional channel for client connections via TCP/IP.'
          />
        }
      />

      <Label label='Destination' tooltip='The topic or queue to connect to.' required />
      <Controller
        control={control}
        // Defaults to first value in options array
        defaultValue={options.find((option) => option.value === 'queue')?.value}
        name={`${name}.destination.type`}
        render={({ field }) => (
          <Select
            value={options.filter((option) => field.value === option.value)}
            onChange={(e) => setValue(`${name}.destination.type`, e.value)}
            options={options}
            className={'eds-spacing--mb-16'}
          />
        )}
      />
      {destination === 'queue' && (
        <TextField
          {...register(`${name}.destination.queue`, { required: true })}
          className='eds-spacing--mb-16'
          label={<Label label='Queue' required tooltip='Name of the queue' />}
        />
      )}
      {destination === 'topic' && (
        <TextField
          {...register(`${name}.destination.topic`, { required: true })}
          className='eds-spacing--mb-16'
          label={<Label label='Topic' required tooltip='Name of the topic' />}
        />
      )}
      <CardTitle title='Credentials' />
      <div className={'eds-grid eds-grid--m-cols-2'}>
        <div>
          <TextField
            {...register(`${name}.username`)}
            className='eds-spacing--mb-16'
            label='Username'
            subtitle='The user name to connect with'
          />
        </div>
        <div>
          <TextField
            {...register(`${name}.password`)}
            className='eds-spacing--mb-16'
            label='Password'
            subtitle='The password to connect with'
          />
        </div>
      </div>
    </Card>
  )
}

export default ConnectionDetails
