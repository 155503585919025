import React, { useState } from 'react'

import { Button, Card, Icon, Snack } from '@nike/eds'

import { putResourceAction } from '../../api'

const nonConnectorTypes = ['kafka.http.push', 'kafka.producer', 'kafka.consumer']

// PausePlayButton displays a Pause or Play button
function PausePlayButton(resource, resourceType, setSnackbarMessage) {
  const [isPaused, setIsPaused] = useState(resource.paused)

  function click(pause) {
    putResourceAction(resourceType, resource.name, pause ? 'pause' : 'resume')
      .then((r) => {
        setIsPaused(pause)
        setSnackbarMessage(r.message)
      })
      .catch((e) => setSnackbarMessage(e.message))
  }

  const type = resourceType.slice(0, -1) // remove trailing 's'
  if (isPaused) {
    if (resource.allowedActions.includes(type + ':Resume'))
      return (
        <Button size='small' onClick={() => click(false)}>
          <Icon name='Play' /> Play
        </Button>
      )
  } else {
    if (resource.allowedActions.includes(type + ':Pause'))
      return (
        <Button size='small' onClick={() => click(true)}>
          <Icon name='Pause' /> Pause
        </Button>
      )
  }
  return null
}

// AdminActions displays a set of buttons
function AdminActions({ resource, resourceType }) {
  const [snackbarMessage, setSnackbarMessage] = useState()

  return (
    <div className='eds-grid eds-grid--m-cols-12'>
      <Card className='eds-grid--m-col-8 no-padding'></Card>
      <Card className='eds-grid--m-col-4 no-padding'>
        {!nonConnectorTypes.includes(resource.type) &&
          PausePlayButton(resource, resourceType, setSnackbarMessage)}
        {resource.metricsDashboardUrl && resourceType === 'sinks' && (
          <a
            href={resource.metricsDashboardUrl}
            target='_blank'
            rel='noreferrer'
            className='eds-spacing--ml-24'
          >
            <Button size='small'>
              <Icon name='ExternalLink' /> SignalFx
            </Button>
          </a>
        )}
        {Boolean(snackbarMessage) && (
          <Snack onDismiss={() => setSnackbarMessage(null)}>{snackbarMessage}</Snack>
        )}
      </Card>
    </div>
  )
}

export default AdminActions
