import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Checkbox, Card, Text, TextField } from '@nike/eds'

import { Label, Select } from '../../../components/FormComponents'
import styles from './Naming.module.styl'

const int = (value) => parseInt(value) || undefined

function getNaming(naming) {
  const body = { type: naming.type, [naming.type]: {} }
  if (naming.type === 'record')
    body.record = {
      field: naming.record.field,
      overwriteOnPrimaryKey: naming.record.overwriteOnPrimaryKey,
    }
  if (naming.type === 'time') {
    body.time = {
      duration: int(naming.time.duration),
      format: naming.time.format,
    }
    if (naming.time.source.type)
      body.time.source = {
        type: naming.time.source.type,
        [naming.time.source.type]: {},
      }
    if (naming.time.source.type === 'record')
      body.time.source.record = { field: naming.time.source.record.field }
  }
  return body
}

function Naming({ name }) {
  const { register, setValue, watch } = useFormContext()
  const naming = watch(name) || {}
  if (!naming.record) naming.record = {}

  return (
    <>
      <Label
        label='Naming'
        tooltip={
          <>
            All objects will have paths/names of the format
            &quot;[kafka-topic]/[prefix]/[naming]/[kafka-topic]+[id].[format]&quot;
            <br />
            where kafka-topic and id are automatically generated and the rest can be set by the
            user.
            <br />
            This setting controls [naming] using one of the strategies within this object.
            <br />
            If not defined then this defaults to partition naming. Otherwise exactly one type should
            be defined.
          </>
        }
      />
      <Select
        value={naming.type}
        defaultValue='partition'
        options={['partition', 'record', 'time']}
        inputProps={register(name + '.type')}
      />
      {naming.type === 'record' && (
        <div className={styles.namingForm}>
          <Text font='subtitle-2'>
            Record naming uses the value of a field within each message to name objects. This
            requires that the source stream has a schema.
          </Text>
          <TextField
            {...register(name + '.record.field', { required: true })}
            className='eds-spacing--mb-16'
            label={<Label label='Field' required />}
          />

          <Checkbox
            label='Overwrite On Primary Key'
            checked={naming.record.overwriteOnPrimaryKey}
            onChange={(e) => setValue(name + '.record.overwriteOnPrimaryKey', e.target.checked)}
          />
          <Text font='subtitle-2' className='eds-spacing--mb-16'>
            All objects will have path/names of the format
            &quot;[kafka-topic]/[prefix]/[naming]/[kafka-topic]+[id].[format]&quot; where
            kafka-topic and id are automatically generated and the rest can be set by the user. The
            default behavior (when this setting is false) is to automatically generate new and
            different objects on each write. In the special case that Field points to a primary key
            this setting can be set to true to configure a fixed value of objectName equal to
            [kafka-topic]+id.[format]. The result is that each object will contain exactly one
            record and it will always be the most recent write for data with that primary key.
          </Text>
        </div>
      )}
      {naming.type === 'time' && (
        <div className={styles.namingForm}>
          <Text font='subtitle-2' className='eds-spacing--mb-16'>
            Time naming uses the timestamps to name objects. All times will be written in the UTC
            timezone.
          </Text>

          <Label label='Duration' required />
          <TextField {...register(name + '.time.duration', { required: true })} />
          <Text font='subtitle-2' className='eds-spacing--mb-16'>
            The duration in milliseconds to use when generating a new name.
          </Text>

          <Label label='Format' required />
          <TextField {...register(name + '.time.format', { required: true })} />

          <Text font='subtitle-2' className='eds-spacing--mb-16'>
            The format of the timestamp added to the object name. Refer to
            https://docs.oracle.com/javase/8/docs/api/java/time/format/DateTimeFormatter.html#patterns
            for a complete description of the symbols that are available. These symbols can be
            combined with free text and / characters to form complex paths. For example
            &quot;&apos;year&apos;=YYYY/&apos;month&apos;=MM/&apos;day&apos;=dd/&apos;hour&apos;=HH&quot;
            would generate a name of the format &quot;year=2015/month=12/day=07/hour=15&quot;. Be
            sure to use &apos; characters around strings that are not in the reserved list of
            symbols.
          </Text>

          <div className='eds-grid eds-grid--m-cols-12'>
            <Card className='eds-grid--m-col-3 no-padding'>
              <Label label='Source' />
              <Select
                value={naming.time.source?.type}
                options={['egress', 'ingress', 'record']}
                optional
                inputProps={register(name + '.time.source.type')}
              />
            </Card>
            {naming.time.source.type === 'record' && (
              <Card className='eds-grid--m-col-9 no-padding'>
                <Label
                  label='Field'
                  required
                  tooltip='The field that contains the timestamp used to name the file.'
                />
                <TextField
                  {...register(name + '.time.source.record.field', { required: true })}
                  className='eds-spacing--mb-16'
                />
                <Text font='subtitle-2'>
                  Record uses the value of a timestamp-containing field from the record.
                  <br />
                  This requires that the source stream has a schema.
                </Text>
              </Card>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export { Naming, getNaming }
