import { useCallback, useEffect, useState } from 'react'

import { getResourceRoles } from '../../api'

/**
 * Fetch roles for specified resource.
 * from API response, or an error string.
 *
 * @param {string} resourceType: one of 'sources', 'sinks', 'kstreams'
 * @param {string} name
 * @returns
 */
function useResourceRoles(resourceType, name, allowedActions) {
  const [roles, setRoles] = useState([])

  const getRoles = useCallback(async () => {
    const response = await getResourceRoles(resourceType, name)
    setRoles(response.roles)
  }, [resourceType, name])

  useEffect(() => {
    const type = resourceType === 'sources' ? 'source' : 'sink'

    if (name && allowedActions?.includes(`${type}:DescribeRoles`)) {
      getRoles()
    }

    return () => setRoles([])
  }, [allowedActions, getRoles, resourceType, name])

  return { getRoles, roles }
}

export default useResourceRoles
