import React from 'react'

import { StatusIndicator } from '@nike/eds'

import CopyableText from '../../components/CopyableText'

function Usage({ usage }) {
  const { routes, connections, connection } = usage
  const hasRoutes = routes && routes.length > 0
  const hasConnections = connections && connections.length > 0
  return (
    <div>
      {!hasRoutes && !hasConnections && !connection && 'No Data'}
      {hasRoutes &&
        routes.map((route, index) => (
          <div key={index}>
            <div>
              <span className='eds-type--subtitle-1'>Method:</span> {route.method}
            </div>
            <div className='word-break'>
              <span className='eds-type--subtitle-1'>Internal URL: </span>
              <CopyableText text={route.url.internal} showOnHover />
            </div>
            <div className='word-break'>
              <span className='eds-type--subtitle-1'>External URL: </span>
              <CopyableText text={route.url.external} showOnHover />
            </div>
            {route.id && (
              <div>
                <span className='eds-type--subtitle-1'>ID:</span>{' '}
                <CopyableText text={route.id} showOnHover />
              </div>
            )}
          </div>
        ))}
      {hasConnections &&
        connections.map((con, index) => (
          <div className='stream-connection eds-spacing--mb-16' key={index}>
            <div>
              <span className='eds-type--title-6'>Stream: {con.stream}</span>
            </div>
            <div>
              <span className='eds-type--subtitle-1'>Topic Name:</span>
              <CopyableText text={con.topic} showOnHover />
            </div>
            {con.storeName && (
              <div>
                <span className='eds-type--subtitle-1'>Store Name:</span>
                <CopyableText text={con.storeName} showOnHover />
              </div>
            )}
            <div>
              <span className='eds-type--subtitle-1'>Brokers: </span>
              <ul>
                {con.brokers.map((brokerUrl) => (
                  <li key={brokerUrl}>
                    <StatusIndicator label={brokerUrl} />
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <span className='eds-type--subtitle-1'>External Brokers: </span>
              <ul>
                {con.externalBrokers.map((externalBrokerUrl) => (
                  <li key={externalBrokerUrl}>
                    <StatusIndicator label={externalBrokerUrl} />
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <span className='eds-type--subtitle-1'>VPC Brokers: </span>
              <ul>
                {con.vpcBrokers.map((vpcBrokerUrl) => (
                  <li key={vpcBrokerUrl}>
                    <StatusIndicator label={vpcBrokerUrl} />
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <span className='eds-type--subtitle-1'>Token URL: </span>
              <CopyableText text={con.tokenURL} showOnHover />
            </div>
            {con.schemaRegistryURL.internal && (
              <div>
                <span className='eds-type--subtitle-1'>Internal Schema Registry URL: </span>
                <CopyableText text={con.schemaRegistryURL.internal} showOnHover />
              </div>
            )}
            {con.schemaRegistryURL.external && (
              <div>
                <span className='eds-type--subtitle-1'>External Schema Registry URL: </span>
                <CopyableText text={con.schemaRegistryURL.external} showOnHover />
              </div>
            )}
            {con.applicationID && (
              <div>
                <span className='eds-type--subtitle-1'>Application ID:</span>
                <CopyableText text={con.applicationID} showOnHover />
              </div>
            )}
          </div>
        ))}
      {connection && (
        <div>
          <div>
            <span className='eds-type--subtitle-1'>Topic Name:</span>
            <CopyableText text={connection.topic} showOnHover />
          </div>
          <div>
            <span className='eds-type--subtitle-1'>Brokers: </span>
            {connection.brokers.map((brokerUrl) => (
              <li key={brokerUrl}>
                <CopyableText text={brokerUrl} showOnHover />
              </li>
            ))}
          </div>
          <div>
            <span className='eds-type--subtitle-1'>External Brokers: </span>
            {connection.externalBrokers.map((brokerUrl) => (
              <li key={brokerUrl}>
                <CopyableText text={brokerUrl} showOnHover />
              </li>
            ))}
          </div>
          <div>
            <span className='eds-type--subtitle-1'>VPC Brokers: </span>
            {connection.vpcBrokers.map((brokerUrl) => (
              <li key={brokerUrl}>
                <CopyableText text={brokerUrl} showOnHover />
              </li>
            ))}
          </div>
          <div>
            <span className='eds-type--subtitle-1'>Token URL: </span>
            <CopyableText text={connection.tokenURL} showOnHover />
          </div>
          {connection.schemaRegistryURL.internal && (
            <div>
              <span className='eds-type--subtitle-1'>Internal Schema Registry URL:</span>
              <CopyableText text={connection.schemaRegistryURL.internal} showOnHover />
            </div>
          )}
          {connection.schemaRegistryURL.external && (
            <div>
              <span className='eds-type--subtitle-1'>External Schema Registry URL:</span>
              <CopyableText text={connection.schemaRegistryURL.external} showOnHover />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Usage
