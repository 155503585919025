import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Card, Radio, Text } from '@nike/eds'

import { Label } from '../../../../components/FormComponents'
import Jwt from './Jwt'
import Oauth from './Oauth'
import Oscar from './Oscar'
import Shoestring from './Shoestring'

function AuthForm() {
  const { watch, setValue } = useFormContext()
  const name = 'settings.http.auth.type'
  const value = watch(name, '')

  const components = {
    '': () => {
      return null
    },
    jwt: Jwt,
    oauth: Oauth,
    oscar: Oscar,
    shoestring: Shoestring,
  }
  const resetAuth = () => {
    setValue(name, '')
    setValue('settings.http.auth', {})
  }
  const SelectedAuth = components[value || '']
  return (
    <Card padding={24}>
      <Label label='Auth' />
      <div className={'eds-spacing--mb-16 eds-grid eds-grid--m-cols-6'}>
        <Radio
          name={name}
          id='auth_none'
          value=''
          onClick={() => resetAuth()}
          checked={!value}
          label={<Text font='body-2'>None</Text>}
        />
        <Radio
          name={name}
          id='auth_jwt'
          value='jwt'
          checked={value === 'jwt'}
          onClick={(e) => setValue(name, e.target.value)}
          label={<Text font='body-2'>jwt</Text>}
        />
        <Radio
          name={name}
          id='auth_oauth'
          value='oauth'
          checked={value === 'oauth'}
          onClick={(e) => setValue(name, e.target.value)}
          label={<Text font='body-2'>oauth</Text>}
        />
        <Radio
          name={name}
          id='auth_oscar'
          value='oscar'
          checked={value === 'oscar'}
          onClick={(e) => setValue(name, e.target.value)}
          label={<Text font='body-2'>oscar</Text>}
        />
        <Radio
          name={name}
          id='auth_shoestring'
          value='shoestring'
          checked={value === 'shoestring'}
          onClick={(e) => setValue(name, e.target.value)}
          label={<Text font='body-2'>shoestring</Text>}
        />
      </div>
      <SelectedAuth />
    </Card>
  )
}

export default AuthForm
