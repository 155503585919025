import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { Button, Icon, Text, TextField, Toggle } from '@nike/eds'

import { Label } from '../../components/FormComponents'
import Info from '../../components/Info'

const int = (value) => parseInt(value) || undefined

function esSettings(data) {
  const settings = {
    type: 'kafka.connect.elasticsearch',
    'kafka.connect.elasticsearch': {
      auth: data.settings.auth
        ? {
            type: 'basic',
            basic: { user: data.settings.auth.user, password: data.settings.auth.password },
          }
        : {},
      urls: data.settings.urls,
      type: data.settings.type,
      index: data.settings.index,
      id: {},
      batch: {
        count: int(data.settings.batch.count),
        frequency: int(data.settings.batch.frequency),
      },
      retry: {
        backoff: int(data.settings.retry.backoff),
        maxCount: int(data.settings.retry.maxCount),
      },
    },
  }

  if (data.settings.format === 'avro') settings.format = { avro: {}, type: 'avro' }
  if (data.settings.format === 'json')
    settings.format = {
      json: data.settings.compression ? { compression: data.settings.compression } : {},
      type: 'json',
    }
  if (data.settings.format === 'parquet') settings.format = { parquet: {}, type: 'parquet' }

  return settings
}

function ElasticSearch() {
  const { register, formState, watch, setValue } = useFormContext()
  const errors = formState.errors
  const [auth, setAuth] = useState(watch('settings.auth.basic'))
  const urls = watch('settings.urls', [''])

  useEffect(() => {
    !urls && setValue('settings.urls', [''])
  }, [setValue, urls])

  return (
    <div>
      <div className='eds-spacing--mb-24 eds-grid eds-grid--m-cols-4'>
        <div>
          <Label label='Auth' />
          <Toggle
            checked={auth}
            onChange={(e) => {
              setAuth(!auth)``
              setValue('settings.auth.basic', !auth)
            }}
          />
        </div>
        {auth && (
          <div>
            <TextField
              {...register('settings.auth.user', { required: true })}
              hasErrors={errors['settings.auth.user']}
              label={<Label label='User' required />}
            />
            <Text font='subtitle-2'>The user name to connect with</Text>
          </div>
        )}
        {auth && (
          <div>
            <TextField
              {...register('settings.auth.password', { required: true })}
              hasErrors={errors['settings.accountName']}
              label={<Label label='Password' required />}
            />
            <Text font='subtitle-2'>The password to connect with</Text>
          </div>
        )}
      </div>

      <Label label='URLs' required />
      <Text font='subtitle-2' className='eds-spacing--mb-16'>
        The list of Elasticsearch HTTP connection URLs to connect to. If any of the URLs specifies
        https protocol HTTPS will be used for all connections. A URL with no protocol will be
        treated as http.
      </Text>
      {urls &&
        urls.map((url, i) => (
          <div key={i} className='eds-spacing--mb-16 eds-grid eds-grid--m-cols-4'>
            <div>
              <TextField
                id='settingsURL'
                key={i}
                value={urls[i]}
                onChange={(e) => {
                  urls[i] = e.target.value
                  setValue('settings.urls', urls)
                }}
              />
            </div>
            <div>
              {i > 0 && (
                <Button
                  variant='secondary'
                  size='s'
                  onClick={() => {
                    urls.splice(i, 1)
                    setValue('settings.urls', urls)
                  }}
                  className='eds-spacing--mt-24 no-padding'
                >
                  <Icon name='Close' />
                </Button>
              )}
            </div>
          </div>
        ))}
      <Button
        variant='secondary'
        onClick={() => setValue('settings.urls', [...urls, ''])}
        className='eds-spacing--mb-16'
      >
        Add URL
      </Button>

      <Label label='Type' required />
      <TextField {...register('settings.type', { required: true })} />
      <Text font='subtitle-2' className='eds-spacing--mb-16'>
        The Elasticsearch type name to use when indexing.
      </Text>

      <Label label='Index' required />
      <TextField {...register('settings.index', { required: true })} />
      <Text font='subtitle-2' className='eds-spacing--mb-16'>
        The name of the Elasticsearch index to write to.
      </Text>

      <Label label='Batch' />
      <Text font='subtitle-2'>Settings for when data is written to Elasticsearch.</Text>
      <div className='eds-spacing--mb-24 eds-grid eds-grid--m-cols-6'>
        <div>
          <TextField
            {...register('settings.batch.count')}
            label={
              <Text font='subtitle-1' as='div'>
                Count{' '}
                <Info
                  tooltip={
                    <>
                      The number of records that should be buffered before writing to Elasticsearch.
                      <br />
                      If not specified then this value defaults to 100.
                    </>
                  }
                />
              </Text>
            }
          />
        </div>
        <div>
          <TextField
            {...register('settings.batch.frequency')}
            label={
              <Text font='subtitle-1' as='div'>
                Frequency
                <Info
                  tooltip={
                    <>
                      The frequency (in milliseconds) to write to Elasticsearch regardless of
                      whether the batch count has been reached.
                      <br />
                      If not set then records are only written when Batch Count has been reached.
                      <br />
                      It can be useful to configure this value when records are being written slowly
                      and may be needed before Batch Count is reached.
                    </>
                  }
                />
              </Text>
            }
          />
        </div>
      </div>

      <Label label='Retry' />
      <Text font='subtitle-2'>
        Settings to control retries when records fail to be written to Elasticsearch.
      </Text>
      <div className='eds-grid eds-grid--m-cols-6'>
        <div>
          <TextField
            {...register('settings.retry.backoff')}
            label={
              <Text font='subtitle-1' as='div'>
                Backoff
                <Info
                  tooltip={
                    <>
                      The number of milliseconds to wait before attempting the first retry of a
                      failed indexing request.
                      <br />
                      Subsequent failures may wait up to twice as long as the previous wait time.
                    </>
                  }
                />
              </Text>
            }
          />
        </div>
        <div>
          <TextField
            {...register('settings.retry.maxCount')}
            label={
              <Text font='subtitle-1' as='div'>
                Max Count
                <Info
                  tooltip={
                    <>
                      The maximum number of retries that are allowed for failed indexing requests.
                      <br />
                      If the retry attempts are exhausted the task will fail.
                    </>
                  }
                />
              </Text>
            }
          />
        </div>
      </div>
    </div>
  )
}

export { ElasticSearch, esSettings }
