import React from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'

import { Button, Card, Icon, Select, Text, TextField, TextGroup, Tooltip } from '@nike/eds'

import AlarmAuth from './AlarmAuth'

function AlarmForm() {
  const { setValue, register, getValues, control, watch } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'alarms', // unique name for your Field Array
  })

  watch()
  const options = [
    { label: 'Degraded', value: 'degraded' },
    { label: 'Error', value: 'error' },
    { label: 'Initializing', value: 'initializing' },
    { label: 'Missing Schema', value: 'missingSchema' },
    { label: 'Paused', value: 'paused' },
    { label: 'Revoked', value: 'revoked' },
    { label: 'Running', value: 'running' },
  ]

  const handleMultiChange = (selectedOptions, index) => {
    const events = getValues().alarms[index]?.events

    if (events)
      Object.keys(events).map((option) => setValue(`alarms[${index}].events.${option}`, false))

    if (selectedOptions)
      selectedOptions.map((option) => setValue(`alarms[${index}].events.${option.value}`, true))
  }

  const getMultiSelectValues = (index) => {
    const valueArray = []
    const events = getValues()?.alarms[index].events
    if (events)
      Object.entries(events).forEach(([event, value]) => {
        const labels = options.filter((option) => option.value === event && value)
        for (const index in labels) {
          valueArray.push(labels[index])
        }
      })
    return valueArray
  }
  return (
    <>
      {fields.map((field, index) => (
        <Card key={field.id} padding={16} className='eds-spacing--mb-24'>
          <TextGroup>
            <Text font='title-4'>{`Alarm ${index + 1}`}</Text>
            <TextField
              {...register(`alarms[${index}].name`)}
              placeholder='Name'
              label='Alarm Name'
            />
            <TextField
              {...register(`alarms[${index}].message`)}
              placeholder='Message'
              label='Message'
            />
            <AlarmAuth index={index} />
            <Text font='title-6' className='eds-spacing--mb-24'>
              Events
              <Tooltip
                bodySlot={
                  <Text font='subtitle-2'>Select which states should trigger the alarm</Text>
                }
                placement='right'
                isDark={true}
              >
                <Icon name='InfoCircle' className='infoCircle' />
              </Tooltip>
            </Text>
            <Select
              placeholder='Select which states should trigger an alarm...'
              isMulti
              options={options}
              isClearable
              value={getMultiSelectValues(index)}
              onChange={(selectedOptions) => handleMultiChange(selectedOptions, index)}
            />
            <Button
              variant='secondary'
              size='small'
              className='eds-spacing--mt-24'
              onClick={() => remove(index)}
            >
              Delete Alarm
            </Button>
          </TextGroup>
        </Card>
      ))}
      <Card padding={16} className='eds-spacing--mb-24'>
        <Button variant='secondary' size='small' onClick={() => append({})}>
          Add Alarm
        </Button>
      </Card>
    </>
  )
}

export default AlarmForm
