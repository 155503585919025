import React, { useState, useEffect } from 'react'

import { Button, Icon, Text, TextField } from '@nike/eds'

import styles from './Roles.module.styl'

function Roles({ title, role, putGroups }) {
  const [edit, setEdit] = useState(false)
  const [groups, setGroups] = useState(role.groups)
  const [newGroups, setNewGroups] = useState([])

  useEffect(() => {
    setGroups(role.groups)
  }, [role.groups])

  return (
    <>
      <Text font='title-6' className='eds-spacing--mb-8 eds-spacing--mr-24' as='span'>
        {title}
      </Text>
      {edit ? (
        <>
          <Button
            size='small'
            variant='secondary'
            className={styles.saveButton}
            onClick={() => {
              const filteredNewGroups = newGroups.filter((grp) => !!grp)
              putGroups(title, [...groups, ...filteredNewGroups])
              setEdit(false)
              setNewGroups([])
            }}
          >
            Save
          </Button>
          <Button
            size='small'
            variant='secondary'
            onClick={() => {
              setEdit(false)
              setNewGroups([])
            }}
            className='eds-spacing--ml-24'
          >
            Cancel
          </Button>
        </>
      ) : (
        <Button variant='ghost' className={styles.edit} onClick={() => setEdit(true)}>
          <Icon name='Edit' />
        </Button>
      )}
      <div className='eds-grid eds-grid--m-cols-2 eds-spacing--mt-24'>
        {groups.map((name, i) => (
          <React.Fragment key={i}>
            <div>{name}</div>
            <div>
              {edit && (
                <Button
                  size='small'
                  variant='ghost'
                  className={styles.ghostButton}
                  onClick={() => {
                    groups.splice(i, 1)
                    setGroups([...groups])
                  }}
                >
                  <Icon name='Close' className={styles.closeIcon} />
                </Button>
              )}
            </div>
          </React.Fragment>
        ))}
        {newGroups.map((name, i) => (
          <React.Fragment key={i}>
            <div>
              <TextField
                type='email'
                value={name}
                onChange={(e) => {
                  newGroups[i] = e.target.value
                  setNewGroups([...newGroups])
                }}
              />
            </div>
            <div className='eds-spacing--mt-24'>
              {edit && (
                <Button
                  size='small'
                  variant='ghost'
                  className={styles.ghostButton}
                  onClick={() => {
                    newGroups.splice(i, 1)
                    setNewGroups([...newGroups])
                  }}
                >
                  <Icon name='Close' className={styles.closeIcon} />
                </Button>
              )}
            </div>
          </React.Fragment>
        ))}

        {edit && (
          <div>
            <Text font='subtitle-2' as='div'>
              Email or AD Group (without @nike.com) or Okta Application Client ID
            </Text>
            <Button
              size='small'
              variant='secondary'
              className='eds-spacing--mt-24'
              onClick={() => setNewGroups([...newGroups, ''])}
            >
              Add another {title}
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

export default Roles
