import React from 'react'

import { Icon, Text, Tooltip } from '@nike/eds'

function Info({ tooltip }) {
  return (
    <Tooltip bodySlot={<Text font='subtitle-2'>{tooltip}</Text>} placement='right' isDark={true}>
      <Icon name='InfoCircle' size='s' className='infoCircle' />
    </Tooltip>
  )
}

export default Info
