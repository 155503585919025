import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { TextField, Card, Select } from '@nike/eds'

import { CardTitle, Label } from './FormComponents'

function StreamSettings({ sourceType }) {
  const { register, setValue, control } = useFormContext()
  const name = `settings.${sourceType}.defaultStreamSettings`
  const options = [
    { label: 1, value: 1 },
    { label: 10, value: 10 },
  ]
  return (
    <Card padding={24}>
      <CardTitle title='Default Stream Settings' />

      {sourceType === 'producer' ? (
        <>
          <Label
            label='Max Payload Size'
            tooltip='The maximum payload request size in MB to be expected for this stream. Note this setting is only available for Producer-type sources. User must also be authorized to apply a setting larger than 1 MB. Please submit a request to gain access to this setting at https://confluence.nike.com/x/xBWUEw'
          />
          <Controller
            control={control}
            defaultValue={options.find((option) => option.value === 1)?.value}
            name={`${name}.maxPayloadSize`}
            render={({ field }) => (
              <Select
                value={options.filter((option) => option.value === field.value)}
                onChange={(e) => setValue(`${name}.maxPayloadSize`, e.value)}
                options={options}
                className={'eds-spacing--mb-16'}
              />
            )}
          />
        </>
      ) : (
        <input
          type='hidden'
          {...register(`${name}.maxPayloadSize`, { required: true, valueAsNumber: true })}
          value={1}
        />
      )}
      <TextField
        {...register(`${name}.partitionCount`, { valueAsNumber: true })}
        className='eds-spacing--mb-16'
        placeholder='1'
        defaultValue={1}
        label={<Label label='Partition Count' />}
      />

      <CardTitle title='Retention' />
      <TextField
        {...register(`${name}.retention.size`, { valueAsNumber: true })}
        className='eds-spacing--mb-16'
        placeholder='20000'
        defaultValue={20000}
        label={<Label label='Size' />}
      />
      <TextField
        {...register(`${name}.retention.time`, { valueAsNumber: true })}
        className='eds-spacing--mb-16'
        placeholder='345600000'
        defaultValue={345600000}
        label={<Label label='Time' />}
      />
    </Card>
  )
}

export default StreamSettings
