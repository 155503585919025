import React, { useState, useEffect } from 'react'

import { Link, Snack, Text } from '@nike/eds'

import { getCloudRed } from '../../api'

const cloudRedGuidRegex = new RegExp(
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
)

function CloudRedMessage({ guid }) {
  const [cloudRed, setCloudRed] = useState()

  useEffect(() => {
    async function fetchCloudRed() {
      const cloudRedLookup = await getCloudRed(guid)
      setCloudRed(cloudRedLookup)
    }

    if (guid && cloudRedGuidRegex.test(guid)) {
      fetchCloudRed()
    }
  }, [guid])

  if (guid && cloudRed && cloudRed.key === guid) {
    return null // Looks good
  }

  const message = guid ? (
    <span>
      Invalid CloudRed GUID <code>{guid}</code>
    </span>
  ) : (
    'Missing CloudRed GUID'
  )

  return (
    message && (
      <Snack status='error' hideDismiss>
        <Text font='subtitle-1' style={{ display: 'inline-block' }}>
          &nbsp;{message}&nbsp; (
          <Link
            target='_blank'
            rel='noreferrer'
            href='https://docs.platforms.nike.com/nike-streaming/CloudRed'
          >
            Why we need this
          </Link>
          )
        </Text>
      </Snack>
    )
  )
}

export default CloudRedMessage
